import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UniversalCookie from 'universal-cookie';

import { Box, Drawer, List } from "@material-ui/core";

import NavItem from './components/nav-item';

import client from './components/client-nav';
import staff from './components/staff-nav';
import admin from './components/admin-nav';


const DashboardSidebar = ({ onMobileClose, openMobile }) => {
	const location = useLocation();
	const { t } = useTranslation();
	const cookie = new UniversalCookie();
	const [navItems, setNavItems] = React.useState<any>([]);


	if (cookie.get('userType') === 'staff' && navItems.length === 0)
		setNavItems(staff);

	if (cookie.get('userType') === 'client' && navItems.length === 0)
		setNavItems(client);

	if (cookie.get('userType') === 'admin' && navItems.length === 0)
		setNavItems(admin);

	React.useEffect(() => {
		if (openMobile && onMobileClose)
			onMobileClose();
	}, [location.pathname]);
	const content = (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%'
			}}
		>
			<Box sx={{ px: 2 }}>
				<List>
					{navItems.map((item: any, index: number) => (
						<NavItem
							href={item.href}
							key={index}
							title={t(item.title)}
							icon={item.icon}
							tooltip_title={t(item.tooltip_title)}
							badge={item.badge}
						/>
					))}
				</List>
			</Box>
			<Box sx={{ flexGrow: 1 }} />
		</Box>
	);

	return (
		<>
			<Box sx={{ display: { xs: 'block', lg: 'none' } }}>
				<Drawer
					anchor='left'
					onClose={onMobileClose}
					open={openMobile}
					variant='temporary'
					PaperProps={{
						sx: {
							width: 280
						}
					}}
				>
					{content}
				</Drawer>
			</Box>
			<Box sx={{ display: { xs: 'none', lg: 'block' } }}>
				<Drawer
					anchor='left'
					open
					variant='persistent'
					PaperProps={{
						sx: {
							width: 280,
							top: 64,
							height: 'calc(100% - 64px)'
						}
					}}
				>
					{content}
				</Drawer>
			</Box>
		</>
	);
};

export default DashboardSidebar;