import { connect } from 'react-redux';
import { IReduxState } from 'src/redux/store';
import { setInterval } from 'src/redux/staff-report/actions';
import withRouter from 'src/hoc/withRouter';
import { HeaderComponent, IReduxStateProps, IReduxDispatchProps } from './component';

function mapStateToProps(state: IReduxState): IReduxStateProps {
	return {
		reduxState: {
			staffReport: state.staffReport
		}
	}
};

const mapDispatchToProps: IReduxDispatchProps = {
    setInterval
};

const HeaderWithRouter = withRouter(HeaderComponent);
export const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderWithRouter);