import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import TuneIcon from '@material-ui/icons/Tune';
import Switch from '@material-ui/core/Switch';

import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';

import { stableSort, getComparator2 } from 'src/utilities/data-table';

function BpDataTable (props: any) {
	const { data = [], dbp_data = [], onOpenTuning, onDbpOpenTuning, handleChangeIsTuned, handleChangeDbpIsTuned } = props;
	const { t } = useTranslation();
	const location = useLocation();
	
	const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
	const [orderBy, setOrderBy] = React.useState('calories');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	
	const headCells = [
		{ id: 'name', align: 'left', disablePadding: false, label: t('table.name'), sortable: true},
		{ id: 'email', align: 'left', disablePadding: false, label: t('table.email'), sortable: true },
		{ id: 'max', align: 'left', disablePadding: false, label: t('report.bp_score_max'), sortable: true },
		{ id: 'min', align: 'left', disablePadding: false, label: t('report.bp_score_min'), sortable: true },
		{ id: 'mean', align: 'left', disablePadding: false, label: t('report.bp_score_mean'), sortable: true },
		{ id: 'bp_max', align: 'right', disablePadding: false, label: t('report.bp_score_max'), sortable: true },
		{ id: 'bp_min', align: 'right', disablePadding: false, label: t('report.bp_score_min'), sortable: true },
		{ id: 'bp_mean', align: 'right', disablePadding: false, label: t('report.bp_score_mean'), sortable: true },
		{ id: 'tuning', align: 'center', disablePadding: false, label: t('table.tuning'), sortable: false },
		{ id: 'actions', align: 'center', disablePadding: false, label: t('table.actions'), sortable: false },
	];

	function handleRequestSort(event: any, property: string) {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
		setPage(newPage);
	};

	function handleOpenTuning(event: React.MouseEvent<HTMLButtonElement> | null, cell: any) {
		onOpenTuning(cell);
	};

	function handleDbpOpenTuning(event: React.MouseEvent<HTMLButtonElement> | null, cell: any) {
		onDbpOpenTuning(cell);
	};

	function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};	

	const [state, setState] = React.useState({
		checkedA: true,
		checkedB: true,
	});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, cell: any) => {
		handleChangeIsTuned(cell, event.target.checked);
		handleChangeDbpIsTuned(cell, event.target.checked);
	};

	let all_data = [];
	data.forEach(function (item:any, index: number) {
			
		all_data.push({
			name:item.name,
			email:item.email,
			min:item.min,
			max:item.max,
			mean:item.mean,
			sbp: item,
			dbp: dbp_data[index],
			bp_max: dbp_data[index].max,
			bp_min: dbp_data[index].min,
			bp_mean: dbp_data[index].mean
			});
	});

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell align="center" colSpan={2}>
						</TableCell>
						<TableCell align="center" colSpan={3}>
							{t('report.sbp_score')}
						</TableCell>
						<TableCell align="center" colSpan={3}>
							{t('report.dbp_score')}
						</TableCell>
						<TableCell align="center" colSpan={2}>
						</TableCell>
					</TableRow>
					<TableRow>
						{headCells.map((headCell: any) => (
							<TableCell
								key={headCell.id}
								align={headCell.align}
								sortDirection={orderBy === headCell.id ? order : false}
							>
								{headCell.sortable
								? (<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={(event) => handleRequestSort(event, headCell.id)}
									>
										<Box pr={1.5}>{headCell.label}</Box>
									</TableSortLabel>)

								: (<Box pr={1.5}>{headCell.label}</Box>)
							}
								
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{stableSort(all_data && all_data, getComparator2(order, orderBy))
						.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						.map((item: any, index: number) => {
							return (
								<TableRow
									hover
									role='checkbox'
									tabIndex={-1}
									key={index}
								>
									<TableCell>{item.name}</TableCell>
									<TableCell>{item.email}</TableCell>
									<TableCell align='left' sx={{ width: 99 }}>
										<Chip label={item.max.value} color={item.max.color} />
									</TableCell> 
									<TableCell align='left' sx={{ width: 99 }}>                                       
										<Chip label={item.min.value} color={item.min.color} />
									</TableCell>
									<TableCell align='left' sx={{ width: 99 }}>
                                        <Chip label={item.mean.value} color={item.mean.color} />
									</TableCell>
									<TableCell align='right' sx={{ width: 99 }}>
										<Chip label={item.bp_max.value} color={item.bp_max.color} />
									</TableCell> 
									<TableCell align='right' sx={{ width: 99 }}>                                       
										<Chip label={item.bp_min.value} color={item.bp_min.color} />
									</TableCell>
									<TableCell align='right' sx={{ width: 99 }}>
                                        <Chip label={item.bp_mean.value} color={item.bp_mean.color} />
									</TableCell>
									
									<TableCell align='center'>
										<Switch
										checked={item.sbp.isTuned}
										onChange={(e) => handleChange(e, item.sbp)}
										color="info"
										inputProps={{ 'aria-label': 'primary checkbox' }} />
										<IconButton disabled={!item.sbp.isTuned} color="info" onClick={(e) =>{handleOpenTuning(e, item.sbp); handleDbpOpenTuning(e, item.dbp)}}>
											<TuneIcon/>
										</IconButton>
									</TableCell>
									<TableCell align='center'>
										<IconButton component={RouterLink} to={location.pathname + '/' + item.sbp.uid + '?name=' + item.sbp.name + '&email=' + item.sbp.email}>
											<VisibilitySharpIcon style={{fill: "#484848"}}/>
										</IconButton>
									</TableCell>
								
								</TableRow>
							);
						})}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							count={data.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
}

export default BpDataTable;
