import Box from '@material-ui/core/Box';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from "react-i18next";
import { formatTime } from 'src/utilities/date';

interface IBarChartProps {
	values: any,
    values_dbp: any,
	interval: string,
	min: number,
	max: number,
	reportId: string,
}

function BpChart(props: IBarChartProps): JSX.Element {
	const {
		values = [],
        values_dbp = [],
		interval = 'weekly',
		min = 0,
		max = 100,
		reportId = ""
	} = props
	const { t } = useTranslation();
	
	let options: any = {
		maintainAspectRatio : false,
		scales: {
			// responsive: true,
			y: {
				type: 'linear',
				min: (reportId=="00005093" ? 80 : min),
				max: max,
				...(reportId=="00005093" && {ticks: {
					stepSize: 1,
					autoSkip: false
				}})
				
			}
		},
		plugins: {
			legend: {
				display: false
			},
		}
	};

	function data(): any {
		let labels: any[] = [];
		let meanDatasets: any[] = [];
		let min_max_datasets: any[] = [];

        let meanDatasetsDbp: any[] = [];
        let min_max_datasets_dbp: any[] = [];

		values.map((item: any) => {
			labels.push(formatTime(interval, item[0]));
			meanDatasets.push(parseFloat(item[3]));
			min_max_datasets.push([parseFloat(item[2]), parseFloat(item[1])]);

			return item;
		})

        values_dbp.map((item: any) => {
            meanDatasetsDbp.push(parseFloat(item[3]));
            min_max_datasets_dbp.push([parseFloat(item[2]), parseFloat(item[1])]);
       
            return item;
        })

		return {
			labels: labels.reverse(),
			datasets: [
				{
					type: 'line',
					label: t("report.mean_value_sbp"),
					borderColor: 'rgba(0,0,0,1)',
					backgroundColor: '#ff1943',
					data: meanDatasets.reverse(),
				},
				{
                    type: "line",
                    label: t("report.mean_value_dbp"),
                    borderColor: "rgba(0,0,0,1)",
                    backgroundColor: "#260dc4",
                    data: meanDatasetsDbp.reverse(),
                  },
				{
					label: t("report.min_max_sbp"),
					borderColor: '#000000',
					backgroundColor: '#c40d23',
					data: min_max_datasets.reverse(),
				},
                  {
                    type:"bar",
                    label: t("report.min_max_dbp"),
                    borderColor: "#000000",
                    backgroundColor: "#260dc4",
                    data: min_max_datasets_dbp.reverse(),
                 }
			]
		};
	};

	return (
		<Box sx={{ height: '60vh' }}>
			<Bar data={data()} options={options} />
		</Box>
	);
}

export default BpChart;