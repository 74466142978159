import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import LoadingButton from '@material-ui/lab/LoadingButton';

import AddSharpIcon from '@material-ui/icons/AddSharp';

import api from 'src/utilities/axios';

interface IAddFormDialogProps {
	updateRender: any
}

function AddFormDialog(props: IAddFormDialogProps): JSX.Element {
	const { updateRender } = props;
	const { t } = useTranslation();
	
	const [open, setOpen] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);

	function handleAdd(event: React.SyntheticEvent): void {
		event.preventDefault();
		event.persist();

		setLoading(true);

		const target = event.target as typeof event.target & {
			entityId: { value: string },
		}

		api({
			pathname: '/user/entities/' + target.entityId.value,
			method: 'POST',
			handleResponse: (): void => {
				setLoading(false);
				updateRender();
				handleClose();
			},
			handleError: (): void => {
				setLoading(false);
			}
		})
	}

	
	function handleClickOpen(): void {
		setOpen(true);
	};

	function handleClose(): void {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Button variant='contained' color='primary' onClick={handleClickOpen}>
				<AddSharpIcon/> {t('form.add_entity')}
			</Button>
			<Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
				<form onSubmit={handleAdd}>
					<DialogTitle>{t('form.add_entity')}</DialogTitle>
					<DialogContent>
						<TextField
							label={t('form.entity_id')}
							id='entityId'
							name='entityId'
							variant='outlined'
							fullWidth
							required
						/>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={handleClose}
							color='primary'
						>
							{t('form.cancel')}
						</Button>
						<LoadingButton
							component='button'
							type='submit'
							variant='contained'
							color='primary'
							loading={loading}
						>
							{t('form.add')}
						</LoadingButton>
					</DialogActions>
				</form>
			</Dialog>
		</React.Fragment>
	)
}

export default AddFormDialog