import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import api from 'src/utilities/axios';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Stack from '@material-ui/core/Stack';
import Button from '@material-ui/core/Button';
import Loader from 'src/components/loader';
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';

import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import DeleteFormInstanceDialog from '../delete-form-dialog';
import withRouter from 'src/hoc/withRouter';

function FormDetail(props: any) {
	const { t } = useTranslation();
    const id = props.params.id;
    const [loading, setLoading] = React.useState<boolean>(false);
	const [render, setRender] = React.useState<boolean>(false);
  	const updateRender = (): void => setRender(!render);
  	const [data, setData] = React.useState<any[]>([]);
	const [deleteState, setDeleteState] = React.useState<boolean>(false);
	const [deleteId, setDeleteId] = React.useState<number>(0);
	const [deleteSentOrder, setDeleteSentOrder] = React.useState<number>(0);
	const deleteDialog = {
			state: deleteState,
			deleteId: deleteId,
			deleteSentOrder: deleteSentOrder,

			setState: setDeleteState,
			setDeleteId: setDeleteId,
			setDeleteSentOrder: setDeleteSentOrder,
		}

	function DeleteData(sent_order): void {
		setDeleteState(true);
		setDeleteId(id);
		setDeleteSentOrder(sent_order);			

	}

	function fetchData(): void {
		setLoading(true);

		api({
			pathname: '/user/forms/'+id+'/history',
			method: 'GET',
			handleResponse: (response: any) => {
				setData(response.data);
				setLoading(false);
			},
			handleError: (error: any) => {
				setLoading(false);
			}
		})
	}

	React.useEffect(fetchData, [render]);
  

	return (
		<Loader state={loading}>
      <Typography variant='h3' sx={{ mb: 1 }}>
	  			{t('navigation.form_detail')}
			</Typography>
			<Breadcrumbs aria-label='breadcrumb'>
				<Link component={RouterLink} color='inherit' to='/client'>
					{t('navigation.dashboard')}
				</Link>
				<Link component={RouterLink} color='inherit' to='/client/forms'>
					{t('navigation.forms')}
				</Link>
				<Typography color='text.primary'>
					{t('navigation.form_detail')}
				</Typography>
			</Breadcrumbs>

			<Divider sx={{ my: 3 }} />

			<Stack alignItems="center" justifyContent="center" spacing={2}>
			<Grid container justifyContent='space-between' component={Paper} sx={{ borderRadius: '16px', 
                                                    maxWidth:"150ch", 
                                                    flexWrap: 'wrap',
                                                    backgroundColor: '#FFFFFF',
                                                    border: 1,
                                                    borderColor: 'grey.300',
                                                    display: 'flex' }}>
				<Grid item sx={{ px: 2, py: 3 }}>
					<Typography variant='h6' display='inline'>
						{t('form.not_answered')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>{t('table.name')}</TableCell>
								<TableCell>{t('table.last_modified')}</TableCell>
								<TableCell align='right'><Box pr={1.5}>{t('table.actions')}</Box></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.filter(a => !a.is_answered).map((item: any, index: number) => {
								return (
									<TableRow key={index}>
										<TableCell component='th' scope='row'>{item.entity_name}</TableCell>
										<TableCell>{moment.utc(item.date).local().format("YYYY/MM/DD HH:mm")}</TableCell>
										<TableCell align='right'>
												<Button variant="contained" color="success" component={RouterLink} to={'/client/forms/'+id+'/detail/answer/'}  state={{ id: id, sent_order: item.sent_order }} >
													{t('form.answer')}
												</Button>
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		
			<Grid container justifyContent='space-between' component={Paper} sx={{ borderRadius: '16px', 
                                                    maxWidth:"150ch", 
                                                    flexWrap: 'wrap',
                                                    backgroundColor: '#FFFFFF',
                                                    border: 1,
                                                    borderColor: 'grey.300',
                                                    display: 'flex' }}>
				<Grid item sx={{ px: 2, py: 3 }}>
					<Typography variant='h6' display='inline'>
						{t('form.answer_history')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Table >
						<TableHead>
							<TableRow>
								<TableCell>{t('table.name')}</TableCell>
								<TableCell>{t('table.last_modified')}</TableCell>
								<TableCell align='right'><Box pr={1.5}>{t('table.actions')}</Box></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.filter(a => a.is_answered).map((item: any, index: number) => {
								return (
									<TableRow key={index}>
										<TableCell component='th' scope='row'>{item.entity_name}</TableCell>
										<TableCell>{moment.utc(item.date).local().format("YYYY/MM/DD HH:mm")}</TableCell>
										<TableCell align='right'>
												<IconButton component={RouterLink} to={'/client/forms/'+id+'/detail/show-answer/'}  state={{ id: id, sent_order: item.sent_order }} >
													<VisibilitySharpIcon />
												</IconButton>
												<IconButton onClick={(e)=>DeleteData(item.sent_order)}>
													<DeleteSharpIcon />
												</IconButton>
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</Stack>
		<DeleteFormInstanceDialog updateRender={updateRender} deleteDialog={deleteDialog} />

    </Loader>
	);
}

export default withRouter(FormDetail);
