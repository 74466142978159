import React from 'react';
import { useTranslation } from 'react-i18next'

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import LoadingButton from '@material-ui/lab/LoadingButton';

import AddSharpIcon from '@material-ui/icons/AddSharp';

import api from 'src/utilities/axios';

interface IAddFormDialogProps {
	updateRender: any,
	types: any
}

export default function AddFormDialog(props: IAddFormDialogProps): JSX.Element {
	const { updateRender, types } = props

	const [open, setOpen] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<JSX.Element>(null);
	const [select, setSelect] = React.useState<any>(null);

	const { t } = useTranslation();

	function handleAdd(event: React.SyntheticEvent): void {
		event.preventDefault();
		event.persist();

		setLoading(true);
		setMessage(null);

		const target = event.target as typeof event.target & {
			questionText: { value: string },
			questionType: { value: number },
		}

		api({
			pathname: '/staff/questions',
			method: 'POST',
			data: {
				text: target.questionText.value,
				question_type: target.questionType.value,
			},
			handleResponse: (): void => {
				setLoading(false);
				updateRender();
				handleClose();
			},
			handleError: (): void => {
				setLoading(false);
			}
		})
	}

	
	function handleClickOpen(): void {
		setOpen(true);
	};

	function handleClose(): void {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Button variant='contained' color='primary' onClick={handleClickOpen}>
				<AddSharpIcon /> {t('form.add_question')}
			</Button>
			<Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
				<form onSubmit={handleAdd}>
					<DialogTitle>{t('form.add_question')}</DialogTitle>
					{message}
					<DialogContent>
						<TextField
							label='Text'
							id='questionText'
							name='questionText'
							variant='outlined'
							fullWidth
							required
							sx={{ mb: 2 }}
						/>
						<FormControl variant='outlined' fullWidth>
							<InputLabel id='questionTypeLabel'>Type</InputLabel>
							<Select
								name='questionType'
								id='questionType'
								labelId='questionTypeLabel'
								label='Type'
								value={select}
								onChange={(event: any): void => setSelect(event.target.value)}
								required
							>
								<MenuItem value={null}>
									<em>None</em>
								</MenuItem>
								{types.map((item: any, index: number) => {
									return (
										<MenuItem
											key={index}
											value={item.id}
										>
											{item.name}
										</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={handleClose}
							color='primary'
						>
							{t('form.cancel')}
						</Button>
						<LoadingButton
							component='button'
							type='submit'
							variant='contained'
							color='primary'
							loading={loading}
						>
							{t('form.add')}
						</LoadingButton>
					</DialogActions>
				</form>
			</Dialog>
		</React.Fragment>
	)
}