import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UniversalCookie from 'universal-cookie';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import Loader from 'src/components/loader';
import api from 'src/utilities/axios';

import DataTable from './components/data-table';

export default function Modules(): JSX.Element {
	const { t } = useTranslation();
	const cookie = new UniversalCookie();
	
	const [data, setData] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);

	function fetchData(): void {
		setLoading(true);

		api({
			pathname: '/staff/' + cookie.get('workspace') + '/modules',
			method: 'GET',
			handleResponse: (response: any): void => {
				setLoading(false);
				setData(response.data);
			},
			handleError: (error: any): void => {
				console.log(error);
				setLoading(false);
			}
		})
	}

	React.useEffect(fetchData, []);
	
	return (
		<Loader state={loading}>
			<Grid container justifyContent='space-between' spacing={3}>
				<Grid item>
					<Typography variant='h3' sx={{ mb: 1 }}>
						{t('navigation.modules')}
					</Typography>
					<Breadcrumbs aria-label='breadcrumb'>
						<Link component={RouterLink} color='inherit' to='/staff'>
							{t('navigation.dashboard')}
						</Link>
						<Typography color='text.primary'>
							{t('navigation.modules')}
						</Typography>
					</Breadcrumbs>
				</Grid>
			</Grid>

			<Divider sx={{ my: 3 }} />

			<DataTable data={data} />
		</Loader>
	)
}