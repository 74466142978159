import { Navigate } from "react-router-dom";
import AuthLayout from "src/layouts/Auth";

const commonRoutes = {
	path: '',
	element: <AuthLayout />,
	children: [
        { path: '', element: <Navigate to='/auth/client/sign-in' /> },	
    ]
}

export default commonRoutes;
