import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import withRouter from 'src/hoc/withRouter';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Loader from 'src/components/loader';
import api from 'src/utilities/axios';

import AddFormDialog from './components/add-form-dialog';
import Manage from './components/manage';
import DataTable from './components/data-table';
import AnswerTable from './components/answer-table';
import ImageTable from './components/image-table';

function DataParams(props: any): any {
	const { params } = props;
	const { t } = useTranslation();
	
	const [data, setData] = React.useState<any>({});
	const [loading, setLoading] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<any>(null);
	const [render, setRender] = React.useState<boolean>(false);
	
	const updateRender = (): void => setRender(!render);

	function fetchData(): void {
		setLoading(true);
		setMessage(null);

		api({
			pathname: '/user/data/' + params.id + '/detail',
			method: 'GET',
			handleResponse: (response: any) => {
				setData(response.data);
				setLoading(false);
			},
			handleError: (error: any) => {
				setLoading(false);
			}
		})
	}

	React.useEffect(fetchData, [render]);

	return (
		<Loader state={loading}>
			<Grid container justifyContent='space-between'>
				<Grid item>
					<Typography variant='h3' sx={{ mb: 1 }}>
						{t('data.'+data.id+'_title', data.name)}
					</Typography>
					<Breadcrumbs aria-label='breadcrumb'>
						<Link component={RouterLink} color='inherit' to='/client'>
							{t('navigation.dashboard')}
						</Link>
						<Link component={RouterLink} color='inherit' to='/client/data'>
							{t('navigation.data')}
						</Link>
						<Typography color='text.primary'>
							{t('data.'+data.id+'_title', data.name)}
						</Typography>
					</Breadcrumbs>
				</Grid>
				<Grid item>
					<AddFormDialog data={data} updateRender={updateRender} />
				</Grid>
			</Grid>

			<Divider sx={{ my: 3 }} />

			<Grid container justifyContent='space-between' spacing={3}>
				<Grid item xs={12} lg={3}>
					<Manage data={data} />
					{data.data_type === 'form_data'
						? <AnswerTable data={data} updateRender={updateRender} />
						: null
					}
					{data.data_type === 'image'
						? <ImageTable data={data} updateRender={updateRender} />
						: null
					}
				</Grid>
				<Grid item xs={12} lg={9}>
					<DataTable data={data} updateRender={updateRender} />
				</Grid>
			</Grid>
		</Loader>
	)
};

export default withRouter(DataParams);