import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

import {
	ArrowBackIosSharp,
	ArrowForwardIosSharp
} from '@material-ui/icons';

import { formatWithZero } from 'src/utilities/date';

interface IInfoPanelProps {
	data: any,
	dbp_data: any,
	startTime: any,
	endTime: any,
	interval: string,
	onIndexChange: Function,
}

function InfoPanel(props: IInfoPanelProps): JSX.Element {
	const { data, dbp_data=[], startTime, endTime, interval, onIndexChange } = props;

	let startTimeDate = new Date(startTime);
	let endTimeDate = new Date(endTime);

	let maximum: any = Number.NEGATIVE_INFINITY;
	let minimum: any = Number.POSITIVE_INFINITY;
	let maximum_dbp: any = Number.NEGATIVE_INFINITY;
	let minimum_dbp: any = Number.POSITIVE_INFINITY;

	data.values && data.values.map((item: any) => {
		if (item[1] === null)
			return item;
		if (parseInt(item[1]) > maximum)
			maximum = parseInt(item[1]);

		if (data.id === "00005097") {
			if (parseInt(item[1]) < minimum)
				minimum = parseInt(item[1]);
		}
		else {
			if (item[2] === null)
				return item;
			if (parseInt(item[2]) < minimum)
				minimum = parseInt(item[2]);
		}
	})
	if (data.id==="00005095" ){
		dbp_data.values && dbp_data.values.map((item: any) => {
			if (item[1] === null)
				return item;
			if (parseInt(item[1]) > maximum_dbp)
				maximum_dbp = parseInt(item[1]);
				
			if (item[2] === null)
				return item;
			if (parseInt(item[2]) < minimum_dbp)
				minimum_dbp = parseInt(item[2]);
			
	
		})
		if (maximum_dbp === Number.NEGATIVE_INFINITY)
			maximum_dbp = '';
		if (minimum_dbp === Number.POSITIVE_INFINITY)
			minimum_dbp = '';
	}
	
	let startDate: any = '';
	let endDate: any = '';
	
	switch(interval) {
		case 'daily':
			break;
		case 'weekly':
			endTimeDate.setDate(endTimeDate.getDate() - 1);
			break;
		case 'monthly':
			endTimeDate.setDate(endTimeDate.getDate() - 1);
			break;
		case 'yearly':
			break;
	}
	
	startDate = startTimeDate.getFullYear() + '/' + formatWithZero(startTimeDate.getMonth() + 1) + '/' + formatWithZero(startTimeDate.getDate());
	endDate = endTimeDate.getFullYear() + '/' + formatWithZero(endTimeDate.getMonth() + 1) + '/' + formatWithZero(endTimeDate.getDate());
	
	if (maximum === Number.NEGATIVE_INFINITY)
		maximum = '';
	if (minimum === Number.POSITIVE_INFINITY)
		minimum = ''

	return (
		<Box>
			<IconButton onClick={() => onIndexChange(1)}>
				<ArrowBackIosSharp fontSize='small' />
			</IconButton>
			{data.id==="00005095"?(
			<Typography variant='caption' display='inline-block' sx={{ verticalAlign: 'middle', textAlign: 'center' }}>
				<b>{minimum}-{maximum} {data.unit && data.unit}</b>
				<br/>
				<b>{minimum_dbp}-{maximum_dbp} {data.unit && data.unit}</b>
				<br/>
				<i>({startDate}-{endDate})</i>
			</Typography>):(
			<Typography variant='caption' display='inline-block' sx={{ verticalAlign: 'middle', textAlign: 'center' }}>
				<b>{minimum}-{maximum} {data.unit && data.unit}</b>
				<br/>
				<i>({startDate}-{endDate})</i>
			</Typography>)}
			<IconButton onClick={() => onIndexChange(-1)}>
				<ArrowForwardIosSharp fontSize='small' />
			</IconButton>
		</Box>
	)
}

export default InfoPanel;