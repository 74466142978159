import React from 'react';
import { useTranslation } from 'react-i18next';
import UniversalCookie from 'universal-cookie';

import {
	Tooltip, 
	Dialog, 
	DialogActions, 
	DialogContent, 
	DialogTitle, 
	TextField, 
	Button 
} from '@material-ui/core';

import LoadingButton from '@material-ui/lab/LoadingButton';

import AddSharpIcon from '@material-ui/icons/AddSharp';

import api from 'src/utilities/axios';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

interface IAddFormDialogProps {
	updateRender: any
}

export default function AddFormDialog(props: IAddFormDialogProps): JSX.Element {
	const { updateRender } = props;
	const { t } = useTranslation();
	const cookie = new UniversalCookie();

	const [open, setOpen] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);

	function handleAdd(event: React.SyntheticEvent): void {
		event.preventDefault();
		event.persist();

		setLoading(true);

		const target = event.target as typeof event.target & {
			email: { value: string },
		}

		api({
			pathname: '/staff/' + cookie.get('workspace') + '/register',
			method: 'POST',
			data: {
				email: target.email.value,
			},
			handleResponse: (): void => {
				setLoading(false);
				updateRender();
				handleClose();
			},
			handleError: (): void => {
				setLoading(false);
			}
		})
	}

	
	function handleClickOpen(): void {
		setOpen(true);
	};

	function handleClose(): void {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Button variant='contained' color='primary' onClick={handleClickOpen}>
				<AddSharpIcon /> {t('form.add_staff')}
			</Button>
			<Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
				<form onSubmit={handleAdd}>
					<DialogTitle>{t('form.add_staff')}</DialogTitle>
					<DialogContent>
						<TextField
							label={t('form.email')}
							id='email'
							name='email'
							variant='outlined'
							fullWidth
							required
						/>
					</DialogContent>
					<DialogActions>
						<Tooltip title={t('tooltip.staff.staffs_add_modal')} arrow placement='left'>
							<Button sx={{color: 'text.secondary'}}>
								<HelpOutlineIcon></HelpOutlineIcon>
							</Button>
						</Tooltip>
						<Button
							onClick={handleClose}
							color='primary'
						>
							{t('form.cancel')}
						</Button>
						<LoadingButton
							component='button'
							type='submit'
							variant='contained'
							color='primary'
							loading={loading}
						>
							{t('form.add')}
						</LoadingButton>
					</DialogActions>
				</form>
			</Dialog>
		</React.Fragment>
	)
}