import React from 'react';
import UniversalCookie from 'universal-cookie'

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import ImageFormDialog from '../image_form_dialog';

import withRouter from 'src/hoc/withRouter';
import Loader from 'src/components/loader';
import api from 'src/utilities/axios';

function Covid19PCRTest(props: any) {
	const { id } = props.params;
	const cookie = new UniversalCookie();

	const [loading, setLoading] = React.useState<boolean>(true);
	const [data, setData] = React.useState<any>([]);
	const [render, setRender] = React.useState<boolean>(false);
	
	const updateRender = (): void => setRender(!render);
	
	function fetchData(): void {
		setLoading(true);

		api({
			method: 'GET',
			pathname: '/staff/' + cookie.get('workspace') + '/data/covid19_pcr_test/user/' + id + '/images',
			handleResponse: (response: any): void => {
				setData(response.data);
				setLoading(false);
			},
			handleError: (error: any): void => {
				console.log(error);
				setLoading(false);
			}
		})
	}

	React.useEffect(fetchData, [render, id]);

	return (
		<Loader state={loading}>
			<Card sx={{ boxShadow: 6 }}>
				<Grid container justifyContent='space-between'>
					<Grid item sx={{ px: 2, py: 3 }}>
						<Typography variant='h6' display='inline'>
							Covid 19 PCR Test
						</Typography>
					</Grid>
					<Grid item sx={{ px: 2, py: 3 }}>
						<ImageFormDialog dataId='covid19_pcr_test' userId={id} updateRender={updateRender} />
					</Grid>
					<Grid item xs={12}>
						<Table>
							<TableBody>
								{data && data.map((item: any, index: number) => {
									return (
										<TableRow key={index}>
											<TableCell>Image File {index + 1}</TableCell>
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
					</Grid>
				</Grid>
			</Card>
		</Loader>
	)
}

export default withRouter(Covid19PCRTest);