import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import UniversalCookie from "universal-cookie";
import {
  Breadcrumbs,
  Card,
  Divider,
  Grid,
  Link,
  Typography
} from "@material-ui/core";
import Loader from "src/components/loader";
import api from "src/utilities/axios";
import { Header } from "./components/header";
import Slider from "./components/slider";
import DataTable from "./components/data-table";
import BpDataTable from './components/bp-data-table';
import TuningDialog from "../components/tuning_dialog";
import BpTuningDialog from '../components/bp_tuning_dialog';
import { StaffReportStateType } from "src/redux/staff-report/reducers";

export interface IPublicProps {
  params: any;
}

export interface IReduxStateProps {
  reduxState: {
    staffReport: StaffReportStateType;
  };
}

export interface IReduxDispatchProps {
  setStartDate: (_: any) => void;
  setInterval: (_: any) => void;
  setComparingDataSet: (_: any) => void;
}

export type Props = IPublicProps & IReduxStateProps & IReduxDispatchProps;

const COOKIE = new UniversalCookie();
const TODAY = new Date();
const WEEK_AGO = moment(TODAY).subtract(6, 'days').toDate();

export function ReportsParamsComponent(props: Props): JSX.Element {
  const { id } = props.params;
  const { interval } = props.reduxState.staffReport;
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>({});
  const [dbp_data, setDbpData] = React.useState<any>({});
  const [sliderData, setSliderData] = React.useState<any>({});
  const [sliderDbpData, setSliderDbpData] = React.useState<any>({});
  const [dataFetched, setDataFetched] = React.useState<boolean>(false);
  const [dbpDataFetched, setDbpDataFetched] = React.useState<boolean>(false);
  const [selectedForTuning, setSelectedForTuning] = React.useState<any>(null);
  const [dbpSelectedForTuning, setDbpSelectedForTuning] = React.useState<any>(null);

  function getIntervalValue(): number {
    let intervalValue: number;

    switch (interval) {
      case "daily":
        intervalValue = 1;
        break;
      case "weekly":
        intervalValue = 7;
        break;
      case "monthly":
        intervalValue = 30;
        break;
      case "yearly":
        intervalValue = 365;
        break;
      default:
        intervalValue = 7;
    }

    return intervalValue;
  }

  function fetchData(): void {
    setLoading(true);

    api({
      pathname: "/staff/" + COOKIE.get("workspace") + "/reports_v2/" + id,
      params: {
        interval: getIntervalValue(),
      },
      method: "GET",
      handleResponse: (response: any): void => {
        setDataFetched(true);
        setData(response.data);
        if (id !== "00005095"){
          setLoading(false);
        }
      },
      handleError: (error: any): void => {
        console.log(error);
        setLoading(false);
      },
    });

    if (id === "00005095"){
      fetchDbpData();
    }
  }

  function fetchDbpData(): void {
    setLoading(true);

    api({
      pathname: "/staff/" + COOKIE.get("workspace") + "/reports_v2/00005096",
      params: {
        interval: getIntervalValue(),
      },
      method: "GET",
      handleResponse: (response: any): void => {
        setDbpDataFetched(true);
        setDbpData(response.data);
        setLoading(false);
      },
      handleError: (error: any): void => {
        console.log(error);
        setLoading(false);
      },
    });
  }

  function resetReduxData(): void {
    props.setComparingDataSet([]);
    props.setStartDate(WEEK_AGO);
    props.setInterval("weekly");
  }

  function updateData(): void {
    setLoading(true);

    if (dataFetched === false) return;

    let sliderDataQuery = sliderData;

    if (
      Object.keys(sliderDataQuery).length === 0 &&
      sliderDataQuery.constructor === Object
    ) {
      sliderDataQuery = {
        maxValue: [0, 0, 0, 0],
        minValue: [0, 0, 0, 0],
        meanValue: [0, 0, 0, 0],
      };
    }

    api({
      pathname: "/staff/" + COOKIE.get("workspace") + "/reports_v2/" + id,
      method: "POST",
      data: {
        interval: getIntervalValue(),
        ...sliderDataQuery,
      },
      handleResponse: (response: any): void => {
        setData(response.data);
        setLoading(false);
      },
      handleError: (error: any): void => {
        console.log(error);
        setLoading(false);
      },
    });
  }

  function updateDbpData(): void {
    setLoading(true);

    if (dbpDataFetched === false) return;

    let sliderDataQuery = sliderDbpData;

    if (
      Object.keys(sliderDataQuery).length === 0 &&
      sliderDataQuery.constructor === Object
    ) {
      sliderDataQuery = {
        maxValue: [0, 0, 0, 0],
        minValue: [0, 0, 0, 0],
        meanValue: [0, 0, 0, 0],
      };
    }

    api({
      pathname: "/staff/" + COOKIE.get("workspace") + "/reports_v2/00005096",
      method: "POST",
      data: {
        interval: getIntervalValue(),
        ...sliderDataQuery,
      },
      handleResponse: (response: any): void => {
        setDbpData(response.data);
        setLoading(false);
      },
      handleError: (error: any): void => {
        console.log(error);
        setLoading(false);
      },
    });
  }

  function normalizeData(raw_data: any): any {
    let new_data = [];
    if (raw_data.data) {
      raw_data.data.forEach(function (item: any) {
        new_data.push({
          isTuned: item.isTuned,
          customRanges: item.customRanges,
          ...item.user,
          ...item.report,
        });
      });
    }

    return new_data;
  }

  function handleOpenTuning(cell: any): any {
    setSelectedForTuning(cell);
  }

  function handleDbpOpenTuning(cell: any): any {		
		setDbpSelectedForTuning(cell);
	}

  function handleSubmitTuning(tunningData: any): any {
    const index = data.data.findIndex(
      (e: any) => e.user.uid === selectedForTuning.uid,
    );
    api({
      pathname:
        "/staff/" +
        COOKIE.get("workspace") +
        "/reports_v2/" +
        id +
        "/tuning/" +
        selectedForTuning.uid,
      method: "POST",
      params: {
        interval: getIntervalValue(),
      },
      data: tunningData,
      handleResponse: (response: any): void => {
        // data.data[index].customRanges = response.data.customRanges;
        data.data[index].report = response.data.report;
        data.data[index].user = {
          ...data.data[index].user,
          ...response.data,
          customRanges: tunningData,
        };
        setData({ ...data });
        setSelectedForTuning(null);
        // setLoading(false);
      },
      handleError: (error: any): void => {
        console.log(error);
      },
    });
  }

  function handleSubmitDbpTuning(tunningData: any): any {
    const index = dbp_data.data.findIndex(
      (e: any) => e.user.uid === dbpSelectedForTuning.uid,
    );
    api({
      pathname:
        "/staff/" +
        COOKIE.get("workspace") +
        "/reports_v2/00005096/tuning/" +
        selectedForTuning.uid,
      method: "POST",
      params: {
        interval: getIntervalValue(),
      },
      data: tunningData,
      handleResponse: (response: any): void => {
        // data.data[index].customRanges = response.data.customRanges;
        dbp_data.data[index].report = response.data.report;
        dbp_data.data[index].user = {
          ...data.data[index].user,
          ...response.data,
          customRanges: tunningData,
        };
        setDbpData({ ...dbp_data });
        setDbpSelectedForTuning(null);
        // setLoading(false);
      },
      handleError: (error: any): void => {
        console.log(error);
      },
    });
  }

  function handleChangeIsTuned(cell: any, isTuned: any): any {
    const index = data.data.findIndex((e: any) => e.user.uid === cell.uid);
    api({
      pathname:
        "/staff/" +
        COOKIE.get("workspace") +
        "/reports_v2/" +
        id +
        "/tuning/" +
        cell.uid,
      method: "PUT",
      params: {
        interval: getIntervalValue(),
      },
      data: { isTuned },
      handleResponse: (response: any): void => {
        data.data[index].user.isTuned = isTuned;
        data.data[index].report = response.data.report;
        data.data[index].user.report = response.data.report;
        setData({ ...data });
      },
      handleError: (error: any): void => {
        console.log(error);
      },
    });
  }

  function handleDbpChangeIsTuned(cell: any, isTuned: any): any {
    const index = dbp_data.data.findIndex((e: any) => e.user.uid === cell.uid);
    api({
      pathname:
        "/staff/" +
        COOKIE.get("workspace") +
        "/reports_v2/00005096/tuning/" +
        cell.uid,
      method: "PUT",
      params: {
        interval: getIntervalValue(),
      },
      data: { isTuned },
      handleResponse: (response: any): void => {
        dbp_data.data[index].user.isTuned = isTuned;
        dbp_data.data[index].report = response.data.report;
        dbp_data.data[index].user.report = response.data.report;
        setDbpData({ ...dbp_data });
      },
      handleError: (error: any): void => {
        console.log(error);
      },
    });
  }

  React.useEffect(fetchData, [id, interval]);
  React.useEffect(updateData, [sliderData]);
  React.useEffect(resetReduxData, []);
  React.useEffect(updateDbpData, [sliderDbpData]);
    
  return (
    <div>
    {id==="00005095"?(
    <Loader state={loading}>
    {selectedForTuning !== null && dbpSelectedForTuning !==null && <BpTuningDialog
      handleClose={() => {handleOpenTuning(null); handleDbpOpenTuning(null)}}
      data={selectedForTuning}
      dbp_data={dbpSelectedForTuning}
      sliderMin={dbp_data.min_value} sliderMax={dbp_data.max_value}
      generalSlider={JSON.parse(data.report_info.replaceAll(/'/g, '"'))}
      generalDbpSlider={JSON.parse(dbp_data.report_info.replaceAll(/'/g, '"'))}
      handleSubmit={handleSubmitTuning}
      handleDbpSubmit={handleSubmitDbpTuning}
      />}
      <Grid justifyContent='space-between' alignItems='flex-end' container spacing={3}>
      <Grid item>
        <Typography variant='h3' sx={{ mb: 1 }}>
          {t('report.'+id, data.name)}
        </Typography>

        <Breadcrumbs aria-label='breadcrumb'>
          <Link component={RouterLink} color='inherit' to='/staff'>
            {t('navigation.dashboard')}
          </Link>
          <Link component={RouterLink} color='inherit' to='/staff/reports'>
            {t('navigation.reports')}
          </Link>
          <Typography color='text.primary'>
            {t('report.'+id, data.name)}
          </Typography>
        </Breadcrumbs>
      </Grid>
    </Grid>

    <Divider sx={{ my: 3 }} />
    <Card sx={{ boxShadow: 6 }}>
      <Grid container>
        <Grid item xs={12}>
          <Header data={data} setInterval={setInterval} interval={interval} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant='h6' align='left'  sx={{ ml: 2 }}>
            {t('report.sbp')}
          </Typography>
          <Slider data={data} setSliderData={setSliderData} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant='h6' align='left' sx={{ ml: 2 }}>
            {t('report.dbp')}
          </Typography>
          <Slider data={dbp_data} setSliderData={setSliderDbpData} />
        </Grid>
        <Grid item xs={12}>
          <BpDataTable data={normalizeData(data)} dbp_data={normalizeData(dbp_data)} onOpenTuning={handleOpenTuning} onDbpOpenTuning={handleDbpOpenTuning}
          handleChangeIsTuned={handleChangeIsTuned} handleChangeDbpIsTuned={handleDbpChangeIsTuned}/>
        </Grid>
      </Grid>
    </Card>
  </Loader>):(
  <Loader state={loading}>
    {selectedForTuning !== null && <TuningDialog
    handleClose={() => handleOpenTuning(null)}
    data={selectedForTuning}
    sliderMin={data.min_value} sliderMax={data.max_value}
    generalSlider={JSON.parse(data.report_info.replaceAll(/'/g, '"'))}
    handleSubmit={handleSubmitTuning}
    />}
    <Grid justifyContent='space-between' alignItems='flex-end' container spacing={3}>
      <Grid item>
        <Typography variant='h3' sx={{ mb: 1 }}>
          {t('report.'+id, data.name)}
        </Typography>

        <Breadcrumbs aria-label='breadcrumb'>
          <Link component={RouterLink} color='inherit' to='/staff'>
            {t('navigation.dashboard')}
          </Link>
          <Link component={RouterLink} color='inherit' to='/staff/reports'>
            {t('navigation.reports')}
          </Link>
          <Typography color='text.primary'>
            {t('report.'+id, data.name)}
          </Typography>
        </Breadcrumbs>
      </Grid>
    </Grid>

    <Divider sx={{ my: 3 }} />

    <Card sx={{ boxShadow: 6 }}>
      <Grid container>
        <Grid item xs={12}>
          <Header data={data} setInterval={setInterval} interval={interval} />
        </Grid>
        <Grid item xs={12}>
          <Slider data={data} setSliderData={setSliderData} />
        </Grid>
        <Grid item xs={12}>
          <DataTable data={normalizeData(data)} onOpenTuning={handleOpenTuning} 
          handleChangeIsTuned={handleChangeIsTuned}/>
        </Grid>
      </Grid>
    </Card>
  </Loader>)}
  </div>
  )
}
