import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { FilterListSharp } from "@material-ui/icons";
import { StaffReportStateType } from "src/redux/staff-report/reducers";

export interface IReduxStateProps {
  reduxState: {
    staffReport: StaffReportStateType;
  };
}

export interface IReduxDispatchProps {
  setInterval: (_: any) => void;
  setStartDate: (_: any) => void;
}

export type Props = IReduxDispatchProps & IReduxStateProps;

const TODAY = new Date();

export function FilterDialogComponent(props: any): JSX.Element {
  const { interval, startDate } = props.reduxState.staffReport;

  const [open, setOpen] = React.useState(false);
  const [stateInterval, setStateInterval] = React.useState<string>(interval);
  const [stateStartDate, setStateStartDate] = React.useState<Date>(startDate);
  const { t } = useTranslation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  /**
   * This is onChange event function that will set the filter interval
   * 
   * @param event 
   */
  function onChangeInterval(event: any): void {
    const {
      target: { value },
    } = event;

    if (value === "weekly" || value === "monthly"){
      let copyDate = moment(TODAY);
      if (value === "weekly") {
        copyDate.subtract(6, "days");
      }
      if (value === "monthly") {
        copyDate.subtract(1, "months").add(1, "days");
      }

      copyDate.hour(0).minute(0).second(0);

      let startMaxDate = new Date(copyDate.toDate());
 
      if (startMaxDate < stateStartDate){
        setStateStartDate(startMaxDate);
      }
      else {
        let momentStateStartDate = moment(stateStartDate);
        momentStateStartDate.hour(0).minute(0).second(0);
        setStateStartDate(momentStateStartDate.toDate());
      }
    }

    if (value === "yearly") {
      let startOfYear = moment(stateStartDate).startOf("year");
      setStateStartDate(startOfYear.toDate());
    }

    setStateInterval(value);
  }

  /**
   * This is onChange event function that will set filter start DATE!
   * 
   * @param event
   */
  function onChangeStartDate(event: any): void {
    const {
      target: { value },
    } = event;
    let year = value.slice(0, 4);
    let month = value.slice(5, 7);
    let date = value.slice(8, 10);
    let updatedMomentDate = moment(stateStartDate)
      .year(year)
      .month(month - 1)
      .date(date);
    if (stateInterval !== "hourly" && stateInterval !== "daily")
      updatedMomentDate.hour(0).minute(0).second(0);
    setStateStartDate(updatedMomentDate.toDate());
  }

  /**
   * This is onChange event function that will set filter start TIME!
   * 
   * @param event
   */
  function onChangeStartTime(event: any): void {
    const {
      target: { value },
    } = event;
    let hours = value.slice(0, 2);
    let minutes = value.slice(3, 5);
    let updatedMomentDate = moment(stateStartDate)
      .hours(hours)
      .minutes(minutes);
    setStateStartDate(updatedMomentDate.toDate());
  }

  /**
   * This function will submit the filter results.
   * Responses will go to the redux state
   * 
   * @returns {void}
   */
  function handleSave(): void {
    props.setInterval(stateInterval);
    props.setStartDate(stateStartDate);
    handleClose();
  }

  return (
    <React.Fragment>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        <FilterListSharp />
        {t("form.filter")}
      </Button>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <DialogTitle>{t("form.filter")}</DialogTitle>
        <DialogContent>
          <Box>
            <FormControl sx={{ textAlign: "left", my: 1 }} fullWidth>
              <TextField
                id="date"
                label={t("report.starting_date")}
                type="date"
                value={moment(stateStartDate).format("YYYY-MM-DD")}
                onChange={onChangeStartDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
            <FormControl sx={{ textAlign: "left", my: 1 }} fullWidth>
              <TextField
                id="time"
                label={t("report.start_hour")}
                type="time"
                value={moment(stateStartDate).format("HH:mm")}
                onChange={onChangeStartTime}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 600,
                }}
                disabled={
                  stateInterval !== "hourly" && stateInterval !== "daily"
                }
              />
            </FormControl>
            <FormControl sx={{ textAlign: "left", mt: 1 }} fullWidth>
              <InputLabel id="interval">{t("date.interval")}</InputLabel>
              <Select
                labelId="interval"
                id="interval"
                value={stateInterval}
                label={t("date.interval")}
                onChange={onChangeInterval}
              >
                <MenuItem value="hourly">{t("date.hourly")}</MenuItem>
                <MenuItem value="daily">{t("date.daily")}</MenuItem>
                <MenuItem value="weekly">{t("date.weekly")}</MenuItem>
                <MenuItem value="monthly">{t("date.monthly")}</MenuItem>
                <MenuItem value="yearly">{t("date.yearly")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("form.cancel")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            {t("form.search")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
