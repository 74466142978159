import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UniversalCookie from 'universal-cookie';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import Loader from 'src/components/loader';
import api from 'src/utilities/axios';

import AddFormDialog from './components/add-form-dialog';
import DeleteFormDialog from './components/delete-form-dialog';
import DataTable from './components/data-table';
import {Tooltip, Button } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export default function Staffs(): JSX.Element {
	const { t } = useTranslation();
	const cookie = new UniversalCookie();
	
	const [data, setData] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [render, setRender] = React.useState<boolean>(false);

	const [deleteState, setDeleteState] = React.useState<boolean>(false);
	const [deleteData, setDeleteData] = React.useState<any[]>([]);

	const updateRender = (): void => setRender(!render);

	const deleteDialog = {
		state: deleteState,
		data: deleteData,

		setState: setDeleteState,
		setData: setDeleteData
	}

	function fetchData(): void {
		setLoading(true);

		api({
			pathname: '/staff/' + cookie.get('workspace') + '/staffs',
			method: 'GET',
			handleResponse: (response: any): void => {
				setLoading(false);
				setData(response.data);
			},
			handleError: (error: any): void => {
				console.log(error);
				setLoading(false);
			}
		})
	}

	React.useEffect(fetchData, [render]);
	
	return (
		<Loader state={loading}>
			<Grid container justifyContent='space-between' spacing={3}>
				<Grid item>
					<Typography variant='h3' sx={{ mb: 1 }}>
						{t('navigation.staffs')}
					</Typography>
					<Breadcrumbs aria-label='breadcrumb'>
						<Link component={RouterLink} color='inherit' to='/staff'>
							{t('navigation.dashboard')}
						</Link>
						<Typography color='text.primary'>
							{t('navigation.staffs')}
						</Typography>
					</Breadcrumbs>
				</Grid>
				<Grid item>
					<Tooltip title={t('tooltip.staff.staffs_add')} arrow placement='left'>
						<Button sx={{color: 'text.secondary'}}>
							<HelpOutlineIcon></HelpOutlineIcon>
						</Button>
					</Tooltip>
					<AddFormDialog updateRender={updateRender} />
				</Grid>
			</Grid>

			<Divider sx={{ my: 3 }} />

			<DeleteFormDialog updateRender={updateRender} deleteDialog={deleteDialog} />
			<DataTable data={data} deleteDialog={deleteDialog} />
		</Loader>
	)
}