import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';

function DataTable(props: any): any {
	const { data } = props;
	const { t } = useTranslation();

	return (
		<Card sx={{ boxShadow: 6 }}>
			<Grid container justifyContent='space-between'>
				<Grid item sx={{ px: 2, py: 3 }}>
					<Typography variant='h6' display='inline'>
						{t('navigation.data')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>{t('table.name')}</TableCell>
								<TableCell>{t('table.description')}</TableCell>
								<TableCell>{t('table.last_modified')}</TableCell>
								<TableCell align='right'><Box pr={1.5}>{t('table.actions')}</Box></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((item: any, index: number) => {
								return (
									<TableRow key={index}>
										<TableCell component='th' scope='row'>{t('data.'+item.id+'_title', item.name)}</TableCell>
										<TableCell>{t('data.'+item.id+'_description', item.description)}</TableCell>
										<TableCell>{item.last_updated === null ? '' : moment(item.last_updated).fromNow()}</TableCell>
										<TableCell align='right'>
											<Tooltip title={t('table.detailed')}>
												<IconButton component={RouterLink} to={'/client/data/' + item.id}>
													<VisibilitySharpIcon />
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</Card>
	)
}

export default DataTable;