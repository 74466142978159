import React, { useState, useEffect } from 'react'
import { Link as RouterLink } from "react-router-dom"
import UniversalCookie from 'universal-cookie';
import { useTranslation } from 'react-i18next'

import Loader from 'src/components/loader';
import api from 'src/utilities/axios';

import { Grid, Typography, Divider, Breadcrumbs, Link, Button } from '@material-ui/core';

import Settings from "./components/settings";
import LockCard from "./components/lock-card";
import LockValidationModal from "./components/lock-validation-modal";

export default function NotificationSettings(): JSX.Element {
    const cookie = new UniversalCookie();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [isLocked, setIsLocked] = useState<boolean>(true);
    const [isAllowed, setIsAllowed] = useState(!isLocked);

    const fetchData = () => {
        api({
            pathname: '/staff/' + cookie.get('workspace') + '/entity',
            method: 'GET',
            handleResponse: (response: any): void => {
                switch (response.data.notification_setting_status) {
                    case "unlocked":
                        setIsLocked(false);
                        setIsAllowed(true);
                        break;
                    case "locked":
                        setIsLocked(true);
                        setIsAllowed(false);
                        break;
                    default:
                        break;
                }

                setIsLoading(false);
            },
            handleError: (error: any): void => {
                console.log(error);
            }
        })
    }

    useEffect(fetchData, []);

    const handleModalClose = () => {
        setModalIsOpen(false);
    }

    const handleModalOpen = () => {
        if (isLocked) return;
        setModalIsOpen(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isLocked || isAllowed) return;

        setIsLoading(true);

        api({
            pathname: '/staff/' + cookie.get('workspace') + '/lock_notification',
            method: 'POST',
            handleResponse: (response: any): void => {
                setIsAllowed(false);
                setIsLocked(true);
                setIsLoading(false);
                handleModalClose();
            },
            handleError: (error: any): void => {
                console.log(error);
                setIsLoading(false);
                handleModalClose();
            }
        })
    }

    return (
        <Loader state={isLoading}>
            <Grid container justifyContent='space-between' spacing={3}>
                <Grid item>
                    <Typography variant='h3' sx={{ mb: 1 }}>
                        {t('navigation.notification_settings')}
                    </Typography>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link component={RouterLink} color='inherit' to='/staff'>
                            {t('navigation.dashboard')}
                        </Link>
                        <Typography color='text.primary'>
                            {t('navigation.notification_settings')}
                        </Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            <Grid container spacing={3}>
                <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                    <Settings path='measurement-value' />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                    <Settings path='synchronization' />
                </Grid>

                <Grid item xs={12} sm={4} md={4} lg={6} xl={8} />

                <Grid item xs={12} sm={8} md={8} lg={6} xl={4}>
                    <LockCard isLocked={isLocked} isAllowed={isAllowed} setIsAllowed={setIsAllowed} openModal={handleModalOpen} />
                    <LockValidationModal open={modalIsOpen} handleClose={handleModalClose} handleSubmit={handleSubmit} />
                </Grid>
            </Grid>
        </Loader>
    )
}