import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UniversalCookie from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

import Alert from '@material-ui/core/Alert';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import LoadingButton from '@material-ui/lab/LoadingButton';

import LanguageMenu from 'src/components/language-menu';
import firebase from 'src/utilities/firebase';
import { useLocation } from 'react-router';

function SignIn(): JSX.Element {
	const { t } = useTranslation();
	const cookie = new UniversalCookie();
	const navigate = useNavigate();
	const [loading, setLoading] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<JSX.Element>(null);
	const location = useLocation();

	function handleSignIn(event: React.SyntheticEvent) {
		event.preventDefault();
		event.persist();

		setLoading(true);
		setMessage(null);

		const target = event.target as typeof event.target & {
			email: { value: string };
			password: { value: string };
		}

		firebase
			.auth()
			.signInWithEmailAndPassword(target.email.value, target.password.value)
			.then((response: any): void => {
				cookie.set('userEmail', response.user.email, { path: '/' });
				localStorage.setItem("isAuthenticated", "true");
				let from_path = '/staff'
				if (location.state?.from){from_path = location.state.from}
				navigate('/auth/staff/entity-select',{state:{from:from_path}});
			})
			.catch((error: any): void => {
				setLoading(false);
				if (error.code === "auth/user-not-found"){
					setMessage(<Alert variant='filled' severity='error'>{t("form.no_account_error")}</Alert>)
				}
				else if (error.code === "auth/wrong-password"){
					setMessage(<Alert variant='filled' severity='error'>{t("form.wrong_password_error")}</Alert>)
				}
				else if (error.code === "auth/too-many-requests"){
					setMessage(<Alert variant='filled' severity='error'>{t("form.too_many_request_error")}</Alert>)
				}
				else {
					setMessage(<Alert variant='filled' severity='error'>{t("form.unknown_error")}</Alert>)
				}
			})
	}

	return (
		<React.Fragment>
			<div style={{ textAlign: 'center' }}>
				<img src='/static/img/brands/ResQ.jpg' width='40%' alt='ResQ' />
			</div>
			<br/>
			{message}
			<br/>
			<form onSubmit={handleSignIn}>
				<TextField
					label={t('form.email')}
					variant='outlined'
					name='email'
					type='email'
					autoComplete='email'
					autoFocus
					required
					fullWidth
					sx={{ mb: 2 }}
				/>
				<TextField
					label={t('form.password')}
					variant='outlined'
					name='password'
					type='password'
					autoComplete='password'
					autoFocus
					required
					fullWidth
					sx={{ mb: 2 }}
				/>
				<LoadingButton
					component='button'
					type='submit'
					variant='contained'
					color='primary'
					loading={loading}
					fullWidth
					sx={{ mb: 2 }}
				>
					{t('form.sign_in')}
				</LoadingButton>
				<Button
					component={RouterLink}
					to='/auth/forgot-password'
					fullWidth
					sx={{ mb: 1 }}
				>
					{t('form.forgot_password')}
				</Button>
			</form>
			<LanguageMenu/>
		</React.Fragment>
	);
}

export default SignIn;