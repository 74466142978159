import React from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@material-ui/core/Alert';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';

import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';

import api from 'src/utilities/axios';

interface IDataTableProps {
	data: any,
	updateRender: any
}

function DataTable(props: IDataTableProps): JSX.Element {
	const { data, updateRender } = props;
	const { t } = useTranslation();

	const [message, setMessage] = React.useState<any>(null);
	const [loading, setLoading] = React.useState<Array<boolean>>(new Array(data.shared_entities ? data.shared_entities.length : 0));

	function mappedData(): JSX.Element[] {

		function handleRemove(workspaceName: any, changeLoading: Function) {
			changeLoading(true);
			setMessage(null);
	
			api({
				pathname: '/user/data/' + data.id + '/entities/' + workspaceName,
				method: 'DELETE',
				handleResponse: (): void => {
					changeLoading(false);
					setMessage(<Alert severity='success'>Request Successful</Alert>);
					updateRender();
				},
				handleError: (): void => {
					changeLoading(false);
				}
			})
		}

		return data.shared_entities && data.shared_entities.map((item: any, index: number) => {
			function changeLoading(value: boolean): void {
				let arrayLoading = JSON.parse(JSON.stringify(loading));
				arrayLoading[index] = value;
				setLoading(arrayLoading);
			}

			return (
				<TableRow key={index}>
					<TableCell component='th' scope='row'>{item.name}</TableCell>
					<TableCell>{item.description}</TableCell>
					<TableCell align='right'>
						<Tooltip title={t('table.delete')}>
							<IconButton onClick={() => handleRemove(item.workspace_name, changeLoading)}>
								<DeleteSharpIcon />
							</IconButton>
						</Tooltip>
					</TableCell>
				</TableRow>
			)
		})
	}

	return (
		<Card sx={{ boxShadow: 6 }}>
			<Grid container justifyContent='space-between'>
				<Grid item sx={{ px: 2, py: 3 }}>
					<Typography variant='h6' display='inline'>
						{t('navigation.entities')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					{message}
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>{t('table.name')}</TableCell>
								<TableCell>{t('table.description')}</TableCell>
								<TableCell align='right'><Box pr={1.5}>{t('table.actions')}</Box></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{mappedData()}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</Card>
	)
}

export default DataTable;