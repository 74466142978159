import React from 'react';
import { useTranslation } from 'react-i18next';
import UniversalCookie from 'universal-cookie';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import api from 'src/utilities/axios';
import Button from '@material-ui/core/Button';
import Stack from '@material-ui/core/Stack';
import {Divider} from '@material-ui/core';

import { stableSort, getComparator } from 'src/utilities/data-table';

export default function ApprovalTable(props: any) {
	const { data, updateRender, deleteDialog } = props;
	const { t } = useTranslation();
	const cookie = new UniversalCookie();

	const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
	const [orderBy, setOrderBy] = React.useState('calories');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(3);

	const headCells: any = [
		{ id: 'name', align: 'left', disablePadding: false, label: t('table.name') },
		{ id: 'email', align: 'left', disablePadding: false, label: t('table.email') },
		{ id: 'actions', align: 'right', disablePadding: false, label: t('table.actions') },
	];

	function handleRequestSort(event: any, property: string) {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
		setPage(newPage);
	};

	function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	function handleApproval(user_id: any): void {
		api({
			pathname: '/staff/' + cookie.get('workspace') + '/users/' + user_id + '/approval',
			method: 'POST',
			handleResponse: (): void => {
				updateRender();
			},
			handleError: (): void => {
			}
		})
	}

	function handleRejection(user_id: any): void {
		api({
			pathname: '/staff/' + cookie.get('workspace') + '/users/' + user_id + '/approval',
			method: 'PUT',
			handleResponse: (): void => {
				updateRender();
			},
			handleError: (): void => {
			}
		})
	}
	if(!data.length)
          return null;
	return (
		<Card sx={{ boxShadow: 6 }} >
			<Grid container justifyContent='space-between'>
				<Grid item sx={{ px: 2, py: 3 }}>
					<Typography variant='h6' display='inline'>
						{t('table.user_requests')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									{headCells.map((headCell: any) => (
										<TableCell
											key={headCell.id}
											align={headCell.align}
											sortDirection={orderBy === headCell.id ? order : false}
										>
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={orderBy === headCell.id ? order : 'asc'}
												onClick={(event) => handleRequestSort(event, headCell.id)}
											>
												<Box pr={1.5}>{headCell.label}</Box>
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{stableSort(data, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => (
										<TableRow hover role='checkbox' tabIndex={-1} key={index + 1}>
											<TableCell align='left'>{row.name}</TableCell>
											<TableCell align='left'>{row.email}</TableCell>
											<TableCell align='right' style={{ paddingInline : 20 }} >
                                                <Stack spacing={2} direction="row"  alignItems="right" justifyContent="right">
                                                    <Button variant="contained" color="success" onClick={() => handleApproval(row.id)}>{t('table.accept')}</Button>
                                                    <Button variant="outlined" onClick={() => handleRejection(row.id)}>{t('table.reject')}</Button>
                                                </Stack>
											</TableCell>
										</TableRow>
									)
								)}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={[3, 6, 9]}
										count={data.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</TableRow>
							</TableFooter>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</Card>
	)
}