import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import Loader from 'src/components/loader';
import api from 'src/utilities/axios';

import PasswordForm from './components/password-form';
import ProfileForm from './components/profile-form';

function Profile(): JSX.Element {
	const { t } = useTranslation();
	
	const [data, setData] = React.useState<any>({});
	const [loading, setLoading] = React.useState<boolean>(true);

	function fetchData(): void {
		setLoading(true);

		api({
			pathname: '/user/info',
			method: 'GET',
			handleResponse: (response: any): void => {
				setData(response.data);
				setLoading(false);
			},
			handleError: (error: any): void => {
				console.log(error);
				setLoading(false);
			}
		})
	}

	React.useEffect(fetchData, []);
	
	return (
		<Loader state={loading}>
			<Typography variant='h3' sx={{ mb: 1 }}>
				{t('navigation.profile')}
			</Typography>
			<Breadcrumbs aria-label='breadcrumb'>
				<Link component={RouterLink} color='inherit' to='/client'>
					{t('navigation.dashboard')}
				</Link>
				<Typography color='text.primary'>
					{t('navigation.profile')}
				</Typography>
			</Breadcrumbs>

			<Divider sx={{ my: 3 }} />
 
			<Grid container spacing={3}>
				<Grid item md={6}>
					<ProfileForm data={data} />
				</Grid>
				<Grid item md={6}>
					<PasswordForm />
				</Grid>
			</Grid>
		</Loader>
	)
}

export default Profile;