import moment from "moment";

export function calculateStartTimeUnix(interval, date) {
  let copyDate = moment(date);

  switch (interval) {
    case "hourly":
      copyDate.subtract(1, "minutes");
      break;
    case "daily":
      copyDate.subtract(1, "hours");
      break;
    case "weekly":
      copyDate.subtract(1, "days");
      break;
    case "monthly":
      copyDate.subtract(1, "days");
      break;
    case "yearly":
      copyDate.startOf("year");
      break;
    default:
      break;
  }

  return copyDate.unix();
}

export function calculateEndTimeUnix(interval, date) {
  let copyDate = moment(date);

  switch (interval) {
    case "hourly":
      copyDate.add(1, "hours");
      break;
    case "daily":
      copyDate.add(1, "days");
      break;
    case "weekly":
      copyDate.add(1, "weeks");
      break;
    case "monthly":
      copyDate.add(1, "months");
      break;
    case "yearly":
      copyDate.endOf("year");
      break;
    default:
      break;
  }

  return copyDate.unix();
}