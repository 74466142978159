import React from 'react';
import { useTranslation } from 'react-i18next';
import UniversalCookie from 'universal-cookie';

import {
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	DialogContentText,
} from '@material-ui/core';

import LoadingButton from '@material-ui/lab/LoadingButton';

import api from 'src/utilities/axios';

interface IDeleteFormDialogProps {
	updateRender: any,
	deleteDialog: any,
}

export default function DeleteFormDialog(props: IDeleteFormDialogProps): JSX.Element {
	const { updateRender, deleteDialog } = props;
	const { t } = useTranslation();
	const cookie = new UniversalCookie();

	const [loading, setLoading] = React.useState<boolean>(false);

	function handleDelete(event: React.SyntheticEvent): void {
		event.preventDefault();
		event.persist();

		setLoading(true);

		api({
			pathname: '/staff/' + cookie.get('workspace') + '/users/' + deleteDialog.data,
			method: 'DELETE',
			handleResponse: (): void => {
				setLoading(false);
				updateRender();
				handleClose();
			},
			handleError: (): void => {
				setLoading(false)
			}
		})
	}

	function handleClose(): void {
		deleteDialog.setState(false);
	};

	return (
		<Dialog open={deleteDialog.state} onClose={handleClose} maxWidth='sm' fullWidth>
			<form onSubmit={handleDelete}>
				<DialogTitle>{t('form.delete_dialog_title')}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{t('form.delete_dialog_content_user')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color='primary'
					>
						{t('form.cancel')}
					</Button>
					<LoadingButton
						component='button'
						type='submit'
						variant='contained'
						color='primary'
						loading={loading}
					>
						{t('form.delete')}
					</LoadingButton>
				</DialogActions>
			</form>
		</Dialog>
	)
}