import { connect } from "react-redux";
import { IReduxState } from "src/redux/store";
import {
  setStartDate,
  setInterval
} from "src/redux/user-report/actions";
import {
  ReportsComponent,
  IReduxStateProps,
  IReduxDispatchProps,
} from "./component";

function mapStateToProps(state: IReduxState): IReduxStateProps {
  return {
    reduxState: {
      userReport: state.userReport,
    },
  };
}

const mapDispatchToProps: IReduxDispatchProps = {
  setStartDate,
  setInterval
};

export const Reports = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsComponent);
