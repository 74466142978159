import { connect } from "react-redux";
import { IReduxState } from "src/redux/store";
import {
  setStartDate,
  setComparingDataSet,
} from "src/redux/staff-report/actions";
import withRouter from "src/hoc/withRouter";
import {
  ReportsDetailsComponent,
  IReduxStateProps,
  IReduxDispatchProps,
} from "./component";

function mapStateToProps(state: IReduxState): IReduxStateProps {
  return {
    reduxState: {
      staffReport: state.staffReport,
    },
  };
}

const mapDispatchToProps: IReduxDispatchProps = {
  setStartDate,
  setComparingDataSet,
};

const ReportDetailsWithRouter = withRouter(ReportsDetailsComponent);
export const ReportsDetails = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportDetailsWithRouter);
