import React from 'react'
import { useTranslation } from 'react-i18next'

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Stack, DialogProps } from "@material-ui/core";

export default function LockValidationModal({ handleClose, handleSubmit, ...props }: LockValidationModalProps) {
    const { t } = useTranslation();

    return (
        <Dialog {...props} onClose={handleClose} sx={{
            textAlign: "center",
        }}>
            <DialogContent >
                <Typography sx={{ fontSize: "16px" }}>
                    {t("notification_settings.lock_validation_modal.content.line_1")}
                </Typography>

                <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: "primary.main" }}>
                    {t("notification_settings.lock_validation_modal.content.line_2")}
                </Typography>
            </DialogContent>

            <DialogTitle
                sx={{
                    fontSize: "16px"
                }}
            >
                {t("notification_settings.lock_validation_modal.title")}
            </DialogTitle>

            <DialogActions >
                <Stack alignItems="center" justifyContent="center" direction={{
                    xs: "column",
                    sm: "row"
                }} sx={{ width: "100%" }} spacing={2}>
                    <Button variant="contained" type="button" onClick={handleSubmit}>{t("form.yes")}</Button>
                    <Button autoFocus variant="outlined" type="button" onClick={handleClose}>{t("form.no")}</Button>
                </Stack>
            </DialogActions>

        </Dialog>
    )
}

interface LockValidationModalProps extends DialogProps {
    handleClose: () => void;
    handleSubmit: (e) => void;
}