import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress';

interface ILoaderProps {
	children: any
	state: boolean
	height?: number
}

function Loader(props: ILoaderProps) {
	const { children, state, height = 200 } = props;
	
	if (!state)
		return children;
	else
		return (
			<Box sx={{
				justifyContent: 'center',
				alignItems: 'center',
				display: 'flex',
				minHeight: '300px',
				width: '100%',
				textAlign: 'center'
			}}>
				<CircularProgress color='secondary' />
			</Box>
		)
}

export default Loader;
