import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import api from 'src/utilities/axios';
import Loader from 'src/components/loader';

import AddFormDialog from './components/add-form-dialog';
import EditFormDialog from './components/edit-form-dialog';
import DeleteFormDialog from './components/delete-form-dialog';
import DataTable from './components/data-table';

export default function Questions(): JSX.Element {
	const { t } = useTranslation();

	const [data, setData] = React.useState<any[]>([]);
	const [types, setTypes] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [render, setRender] = React.useState<boolean>(false);

	const [editState, setEditState] = React.useState<boolean>(false);
	const [editData, setEditData] = React.useState<any[]>([]);

	const [deleteState, setDeleteState] = React.useState<boolean>(false);
	const [deleteData, setDeleteData] = React.useState<any[]>([]);

	const updateRender = (): void => setRender(!render);

	const editDialog = {
		state: editState,
		data: editData,

		setState: setEditState,
		setData: setEditData,
	}

	const deleteDialog = {
		state: deleteState,
		data: deleteData,

		setState: setDeleteState,
		setData: setDeleteData
	}


	function fetchData(): void {
		setLoading(true);

		api({
			pathname: '/staff/questions',
			method: 'GET',
			handleResponse: (response: any): void => {
				setLoading(false);
				setData(response.data);
			},
			handleError: (): void => {
				setLoading(false)
			}
		})

		setLoading(true);

		api({
			pathname: '/staff/question_types',
			method: 'GET',
			handleResponse: (response: any): void => {
				setLoading(false);
				setTypes(response.data);
			},
			handleError: (): void => {
				setLoading(false)
			}
		})
	}

	React.useEffect(fetchData, [render]);

	return (
		<Loader state={loading}>
			<Grid container justifyContent='space-between' spacing={3}>
				<Grid item>
				<Typography variant='h3' sx={{ mb: 1 }}>
						{t('navigation.questions')}
					</Typography>
					<Breadcrumbs aria-label='breadcrumb'>
						<Link component={RouterLink} color='inherit' to='/staff'>
							{t('navigation.dashboard')}
						</Link>
						<Typography color='text.primary'>
							{t('navigation.questions')}
						</Typography>
					</Breadcrumbs>
				</Grid>
				<Grid item>
					<AddFormDialog updateRender={updateRender} types={types} />
				</Grid>
			</Grid>

			<Divider sx={{ my: 3 }} />

			<EditFormDialog updateRender={updateRender} editDialog={editDialog} />
			<DeleteFormDialog updateRender={updateRender} deleteDialog={deleteDialog} />
			<DataTable types={types} editDialog={editDialog} deleteDialog={deleteDialog} data={data && data} />
		</Loader>
	)
}