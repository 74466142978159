import MobileLayout from "src/layouts/Mobile";
import ReportsParams from "src/views/mobile/reports/params";

const mobileRoutes = {
	path: 'mobile',
	element: <MobileLayout />,
	children: [
		{ path: 'reports/resq_band', element: <ReportsParams /> },
	]
}

export default mobileRoutes;
