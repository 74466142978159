import React, { useState, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom';
import api from 'src/utilities/axios';
import UniversalCookie from "universal-cookie";
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Stack from '@material-ui/core/Stack';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import AddIcon from '@material-ui/icons/Add';
import EventIcon from '@material-ui/icons/Event';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DeleteIcon from '@material-ui/icons/Delete';
import Radio from '@material-ui/core/Radio';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import DeleteFormInstanceDialog from '../delete-instance-dialog';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LoadingButton from '@material-ui/lab/LoadingButton';
import CloseIcon from '@material-ui/icons/Close';
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import DownloadSharpIcon from '@material-ui/icons/DownloadSharp';
import ScheduleSendIcon from '@material-ui/icons/ScheduleSend';
import CheckIcon from '@material-ui/icons/Check';
import ResendFormDialog from '../../components/resend-form-dialog';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import Tab from '@material-ui/core/Tab';
import Skeleton from '@material-ui/core/Skeleton';
import TabPanel from '@material-ui/lab/TabPanel';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/core/Alert';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import withRouter from 'src/hoc/withRouter';


import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { CSVLink } from 'react-csv';


const COOKIE = new UniversalCookie();

function createData(
  user_name: string,
  user_id: number,
  user_email: string,
  history:any
) {
  return {
    user_name,
    user_id,
    user_email,
    history
  };
}



function Row(props: { row: ReturnType<typeof createData>, id, DeleteData }) {
  const { row, id, DeleteData } = props;
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.user_name}
        </TableCell>
        <TableCell align="right">{row.user_email}</TableCell>
        <TableCell align="right">{moment.utc(row.history[row.history.length-1].answered_date).local().format("YYYY/MM/DD HH:mm")}</TableCell>
        <TableCell align="right">{row.history.filter(obj => obj.is_answered === true).length+"/"+row.history.length}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("table.date")}</TableCell>
                    <TableCell>{t("table.status")}</TableCell>
                    <TableCell align="right">{t("table.actions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                     <TableRow key={historyRow.answered_date}>
                     <TableCell component="th" scope="row">
                       {moment.utc(historyRow.answered_date).local().format("YYYY/MM/DD HH:mm")}
                     </TableCell>
                     <TableCell>{historyRow.is_answered?(<CheckIcon />):(<ScheduleSendIcon/> )}</TableCell>
                     <TableCell align="right">
                     {historyRow.is_answered?(<div>
                       <IconButton component={RouterLink} to={'/staff/form/'+id+'/history/answer'} state={{ id: id, sent_order: historyRow.sent_order, user_id : row.user_id, user_name:row.user_name, user_email: row.user_email, date: historyRow.answered_date }}>
                         <VisibilitySharpIcon />
                       </IconButton>
                       <IconButton onClick={()=> DeleteData(id, historyRow.sent_order, row.user_id)}>
                         <DeleteSharpIcon />
                       </IconButton></div>):(<div>
                           <IconButton disabled>
                             <VisibilitySharpIcon />
                           </IconButton>
                           <IconButton onClick={()=> DeleteData(id, historyRow.sent_order, row.user_id)}>
                             <DeleteSharpIcon />
                           </IconButton>
                         </div>
                       )}

                     </TableCell>
                     
                   </TableRow>
                  ))}
                     
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function FormHistory(props: any) {

	const { t } = useTranslation();
  const id  = props.params.id;
  const ariaLabel = { 'aria-label': 'description' };
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [render, setRender] = React.useState<boolean>(false);
  const updateRender = (): void => setRender(!render);
  const [questions, setQuestions] = React.useState<any>([]);
  const [addedQuestions, setAddedQuestions] = React.useState<any>([]);
  const [addedQuestionOptions, setAddedQuestionOptions] = React.useState<any>([]);
  const [tableRows, setTableRows] = React.useState<any>([]);
  const [csvData, setCsvData] = React.useState<any>([]);
	const [value, setValue] = React.useState('1');
  const [loading, setLoading] = React.useState<boolean>(true);
  const [editLoading, setEditLoading] = React.useState<boolean>(false);
  const [csvLoading, setCsvLoading] = React.useState<boolean>(false);
  const [ApplyDisabled, setApplyDisabled] = React.useState<boolean>(true);
  const [types, setTypes] = React.useState<any[]>([]);
  const [deletedQuestions, setDeletedQuestions] = React.useState<any[]>([]);
  const [deletedQuestionOptions, setDeletedQuestionOptions] = React.useState<any[]>([]);
  const [name, setName] = React.useState<any>('');
  const [description, setDescription] = React.useState<any>('');
  const [formId, setFormId] = React.useState<number>(0);
  const [deleteState, setDeleteState] = React.useState<boolean>(false);
	const [deleteId, setDeleteId] = React.useState<number>(0);
	const [deleteSentOrder, setDeleteSentOrder] = React.useState<number>(0);
	const [deleteUserId, setDeleteUserId] = React.useState<number>(0);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [successOpen, setSuccessOpen] = React.useState(false);
  const formRefEdit = React.useRef();
  const csvLink: any = React.useRef();


  const deleteDialog = {
		state: deleteState,
		deleteId: deleteId,
		deleteSentOrder: deleteSentOrder,
		deleteUserId: deleteUserId,

		setState: setDeleteState,
		setDeleteId: setDeleteId,
		setDeleteSentOrder: setDeleteSentOrder,
		setDeleteUserId: setDeleteUserId,
	}



	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
      };
	
	const fetchTypesData = () => {
    api({
        pathname: '/staff/question_types',
        method: 'GET',
        handleResponse: (response: any): void => {
            setTypes(response.data);
        },
        handleError: (): void => {
        }
    })
}

  useEffect(fetchTypesData, []);

  function DeleteData(id, sent_order, user_id): void {
    setDeleteState(true);
    setDeleteId(id);
    setDeleteSentOrder(sent_order);
    setDeleteUserId(user_id);
  }

	const handleNameChange = e =>{
    setName(e.target.value);
  };

	const handleDescriptionChange = e =>{
    setDescription(e.target.value);
  };

	const addQuestion = () => {
    if (!addedQuestions.length){
      if (questions.length){setAddedQuestions(currentRows=> [...currentRows,{order: Math.max(...questions.map(o => o.order))+1,question_type:1,text:'', is_required:false, question_options:[{'order':0,text:''}]}]);}
      else{setAddedQuestions(currentRows=> [...currentRows,{order: 0,question_type:1,text:'', is_required:false, question_options:[{'order':0,text:''}]}]);}
      
    }
    else{
      setAddedQuestions(currentRows=> [...currentRows,{order: Math.max(...addedQuestions.map(o => o.order))+1,question_type:1,text:'', is_required:false, question_options:[{'order':0,text:''}]}]);
    }
}


const deleteOldQuestionOption = (o_index, index, question_id, deleted_order) => (event) => {
  const deleteQuestionsWithOptions = [...questions]
  deleteQuestionsWithOptions[index].question_options = deleteQuestionsWithOptions[index].question_options.filter((_, index) => index !== o_index)
  setQuestions(deleteQuestionsWithOptions);

  if (deletedQuestionOptions.find(o=>o.question_id===question_id) !== undefined) {
    deletedQuestionOptions.find(o=>o.question_id===question_id).options_to_delete.push(deleted_order);
  }
  else{
      setDeletedQuestionOptions(currentRows=>[...currentRows, {'question_id':question_id, 'options_to_delete':[deleted_order]}]);
  }
  setDeletedQuestionOptions(currentRows=> [...currentRows]);  
}

const addAddedQuestionOption = (index:any) => (event) => {
  event.stopPropagation();
  const newAddedQuestionOptions = addedQuestions
  if (newAddedQuestionOptions[index].question_options.length){
    newAddedQuestionOptions[index].question_options.push({order:Math.max(...newAddedQuestionOptions[index].question_options.map(o => o.order))+1, text:''});
  }
  else{
    newAddedQuestionOptions[index].question_options.push({order:0, value:''});
  }
  setAddedQuestions(newAddedQuestionOptions);
  setAddedQuestions(currentRows=> [...currentRows]);
  
}

	const handleInputChange = e => {
    const newQuestions = questions
    newQuestions[e.target.id].text = e.target.value 
    setQuestions(newQuestions);
  };

  const handleAddedInputChange = e => {
    const newAddedQuestions = addedQuestions
    newAddedQuestions[e.target.id].text = e.target.value 
    setAddedQuestions(newAddedQuestions);
  };

  const handleSelectChange = e => {        
    const newQuestionsSelect = addedQuestions
    newQuestionsSelect[e.target.id].question_type = Number(e.target.value)
    setAddedQuestions(newQuestionsSelect);
    setAddedQuestions(currentRows=> [...currentRows]);
  };

  const deleteAddedQuestionOption = (o_index, index) => (event) => {
    const deleteAddedQuestionsWithOptions = [...addedQuestions]
    deleteAddedQuestionsWithOptions[index].question_options = deleteAddedQuestionsWithOptions[index].question_options.filter((_, index) => index !== o_index)
    setAddedQuestions(deleteAddedQuestionsWithOptions);    
}
  const deleteQuestionOptionAdded = (o_index, index) => (event) => {
    const deleteAddedQuestionOptions = [...addedQuestionOptions]    
    setAddedQuestionOptions(deleteAddedQuestionOptions.filter((_, index) => index !== o_index));    
}

const addQuestionOption = (index:any, question_id) => (event) => {
  event.stopPropagation();
  const tmp_questions= questions;
  const order_tmp = Math.max(...tmp_questions[index].question_options.map(o => o.order))+1;
  if(!addedQuestionOptions.length){
    if(tmp_questions[index].question_options.length){setAddedQuestionOptions(currentRows=> [...currentRows, {'question_id':question_id, 'text':'', 'order':order_tmp}]);}
    else{setAddedQuestionOptions(currentRows=> [...currentRows, {'question_id':question_id, 'text':'', 'order':0}]);}
    
  }else{
    setAddedQuestionOptions(currentRows=> [...currentRows, {'question_id':question_id, 'text':'', 'order': Math.max(...addedQuestionOptions.map(o => o.order))+1}]);
  }  
}

const deleteAddedQuestion = (i:number) => (event) => {
  event.stopPropagation();
  setAddedQuestions(addedQuestions.filter((_, index) => index !== i));
}

const handleSwitch = (index) => {
  const questionsSwitch = questions
  if(questionsSwitch[index.toString()].is_required){
      questionsSwitch[index.toString()].is_required = false
      setQuestions(questionsSwitch);
      setQuestions(currentRows=> [...currentRows])
  }
  else{
      questionsSwitch[index.toString()].is_required = true
      setQuestions(questionsSwitch);
      setQuestions(currentRows=> [...currentRows])
  }
};


const handleAddedSwitch = (index) => {
  const addedQuestionsSwitch = addedQuestions
  if(addedQuestionsSwitch[index.toString()].is_required){
      addedQuestionsSwitch[index.toString()].is_required = false
      setAddedQuestions(addedQuestionsSwitch);
      setAddedQuestions(currentRows=> [...currentRows])
  }
  else{
      addedQuestionsSwitch[index.toString()].is_required = true
      setAddedQuestions(addedQuestionsSwitch);
      setAddedQuestions(currentRows=> [...currentRows])
  }
};

const handleOptionsInputChange = (e, index, o_index) => {
    const newQuestionsWithOptions = questions
    newQuestionsWithOptions[index.toString()].question_options[o_index.toString()].text = e.target.value
    setQuestions(newQuestionsWithOptions);
  };

const handleAddedOptionsInputChange = (e, index, o_index) => {
  const newAddedQuestionOptions = addedQuestionOptions
  newAddedQuestionOptions[o_index.toString()].text = e.target.value
  setAddedQuestionOptions(newAddedQuestionOptions);
};

const handleOptionsAddedInputChange = (e, index, o_index) => {
  const newAddedQuestionsWithOptions = addedQuestions
  newAddedQuestionsWithOptions[index.toString()].question_options[o_index.toString()].text = e.target.value
  setAddedQuestions(newAddedQuestionsWithOptions);
};

const deleteOldQuestion = (i:number, order) => (event) => {
    event.stopPropagation();
    setQuestions(questions.filter((_, index) => index !== i));
    
    console.log(deletedQuestions, order);
    setDeletedQuestions(currentRows=>[...currentRows, order]);
    
}

const handleDefaultSwitch = (event: React.SyntheticEvent)=> {
  if(isDefault){
      setIsDefault(false);
  }
  else{
      setIsDefault(true);
  }
};

  function fetchData(): void {

		api({
			pathname: '/staff/'+ COOKIE.get("workspace") + '/forms/' + id,
			method: 'GET',
			handleResponse: (response: any): void => {
				setLoading(true);
        setName(response.data.name);
        setDescription(response.data.description);
        setIsDefault(response.data.is_default);
        setFormId(response.data.id);
        setTableRows(response.data.sent_users);
				setQuestions(response.data.questions);

        setLoading(false);
			},
			handleError: (): void => {
				setLoading(false);
			}
		})
	}

	React.useEffect(fetchData, [render]);


  function handleEdit(event: React.SyntheticEvent, formRef:any): void {
		event.preventDefault();
		event.persist();
    

		setEditLoading(true);

    if (formRef?.current){
      if (formRef.current.reportValidity()){
        api({
          pathname: '/staff/' + COOKIE.get('workspace') + '/forms/' + id,
          method: 'PUT',
          
          data: {
            name: name,
            description: description,
            questions: questions,
            added_questions:addedQuestions,
            added_question_options:addedQuestionOptions,
            added_users:[],
            deleted_questions:deletedQuestions,
            deleted_question_options:deletedQuestionOptions,
            deleted_users:[],
            is_editable:true,
            is_default:isDefault


          },
          handleResponse: (): void => {
            updateRender();
            setAddedQuestions([]);
            setAddedQuestionOptions([]);
            setDeletedQuestionOptions([]);
            setDeletedQuestions([]);
            setSuccessOpen(true);
            setEditLoading(false);

          },
          handleError: (): void => {
            setAddedQuestions([]);
            setAddedQuestionOptions([]);
            setDeletedQuestionOptions([]);
            setDeletedQuestions([]);
            setErrorOpen(true);
            setEditLoading(false);
          }
        })}  else{setEditLoading(false);}
      }

      else{
        // setEditLoading(false);
        api({
          pathname: '/staff/' + COOKIE.get('workspace') + '/forms/' + id,
          method: 'PUT',
          
          data: {
            name: name,
            description: description,
            questions: questions,
            added_questions:addedQuestions,
            added_question_options:addedQuestionOptions,
            added_users:[],
            deleted_questions:deletedQuestions,
            deleted_question_options:deletedQuestionOptions,
            deleted_users:[],
            is_editable:true,
            is_default:isDefault
  
  
          },
          handleResponse: (): void => {
            updateRender();
            setAddedQuestions([]);
            setAddedQuestionOptions([]);
            setDeletedQuestionOptions([]);
            setDeletedQuestions([]);
            setSuccessOpen(true);
            setEditLoading(false);
  
          },
          handleError: (): void => {
            setAddedQuestions([]);
            setAddedQuestionOptions([]);
            setDeletedQuestionOptions([]);
            setDeletedQuestions([]);
            setErrorOpen(true);
            setEditLoading(false);
          }
        })
      }
	}

  function handleCsvDownload(event: React.SyntheticEvent): void {
		event.preventDefault();
		event.persist();
    
		setCsvLoading(true);
    api({
      pathname: '/staff/' + COOKIE.get('workspace') + '/forms/' + id +'/download',
      method: 'GET',

      handleResponse: (response: any): void => {
        response.data.forEach(function(item, i) { if (i !== 0) item[2] = moment.utc(item[2]).local().format("YYYY/MM/DD HH:mm") });
        response.data.forEach(function(item, i) { if (i !== 0) item[3] = moment.utc(item[3]).local().format("YYYY/MM/DD HH:mm") });
        setCsvData(response.data);
        csvLink.current.link.click()
        setCsvLoading(false);

      },
      handleError: (): void => {
        setCsvLoading(false);
      }
    })
	}

  
	return (
    <div>
      <Grid container justifyContent='space-between' spacing={3}>
                  <Grid item>
                      <Typography variant='h3' sx={{ mb: 1 }}>
                          {t('navigation.form_detail')}
                      </Typography>
                      <Breadcrumbs aria-label='breadcrumb'>
                          <Link component={RouterLink} color='inherit' to='/staff'>
                              {t('navigation.dashboard')}
                          </Link>
                          <Link component={RouterLink} color='inherit' to='/staff/form'>
                              {t('navigation.forms')}
                          </Link>
                          <Typography color='text.primary'>
                            {t('navigation.form_detail')}
                          </Typography>
                      </Breadcrumbs>
                      
                  </Grid>
                  <Grid item>
                  <Snackbar anchorOrigin={ {vertical: 'bottom', horizontal: 'right'} } open={errorOpen} autoHideDuration={6000} onClose={()=>{setErrorOpen(false);}}>
                    <Alert severity="error" variant="filled"
                      action={
                        <IconButton
                        aria-label={t('form.close')}
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setErrorOpen(false);
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2 }}
                      >
                      {t('form.failed_saving')}
                    </Alert>
                  </Snackbar>
                  <Snackbar anchorOrigin={ {vertical: 'bottom', horizontal: 'right'} } open={successOpen} autoHideDuration={6000} onClose={()=>{setSuccessOpen(false);}}>
                    <Alert severity="success" variant="filled"
                      action={
                        <IconButton
                        aria-label={t('form.close')}
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setSuccessOpen(false);
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2 }}
                      >
                      {t('form.saved')}
                    </Alert>
                  </Snackbar>
                  <Stack spacing={2} direction="row">

                    <LoadingButton variant='contained' loading={editLoading} color='primary' onClick={(e)=> handleEdit(e, formRefEdit)}>
                    {t('form.save')}
                    </LoadingButton>
                    <ResendFormDialog updateRender={updateRender} id={formId}/>
                    </Stack>
                    </Grid>
              </Grid>
              <TabContext value={value}>
        
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} centered aria-label="lab API tabs example">
                  <Tab disableRipple label={t('navigation.questions')} value="1" />
                  <Tab disableRipple label={t('navigation.answers')} value="2" />
                  <Tab disableRipple label={t('navigation.settings')} value="3" />
              </TabList>
              </Box>
        <TabPanel value="1">
        <div>
            
        {loading?(<div>
          <Stack alignItems="center" justifyContent="center" spacing={3}>
          <Skeleton variant="rectangular" sx={{
                    '& > :not(style)': { m: 5, width: '93ch' },
                    maxWidth:"100ch",
                    flexWrap: 'wrap',
                    border: 1,
                    borderColor: 'grey.300', 
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '16px',
                }} animation="wave" width={'93ch'} height={'27ch'} />
          <Skeleton variant="rectangular" sx={{
                    '& > :not(style)': { m: 5, width: '93ch' },
                    maxWidth:"100ch",
                    flexWrap: 'wrap',
                    border: 1,
                    borderColor: 'grey.300', 
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '16px',
                }} animation="wave" width={'93ch'} height={'30ch'} />
          <Skeleton variant="rectangular" sx={{
                    '& > :not(style)': { m: 5, width: '93ch' },
                    maxWidth:"100ch",
                    flexWrap: 'wrap',
                    border: 1,
                    borderColor: 'grey.300', 
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '16px',
                }} animation="wave" width={'93ch'} height={'30ch'} />
          </Stack>
          </div>):(
        <form ref={formRefEdit}>
          <Stack alignItems="center" justifyContent="center" spacing={3}>
                <Box
                sx={{
                    '& > :not(style)': { m: 5, width: '93ch' },
                    maxWidth:"100ch",
                    flexWrap: 'wrap',
                    backgroundColor: '#FFFFFF',
                    border: 1,
                    borderColor: 'grey.300', 
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '16px',
                }}
                >
                    <Stack  spacing={3}>
                    
                        <TextField fullWidth required id="standard-basic" placeholder="Form Name" InputProps={{ style: { fontSize: 27 } }} onChange={e => handleNameChange(e)} value={name} variant="standard" />
                        <TextField multiline required rows={4} id="standard-basic" placeholder="Form Description" onChange={e => handleDescriptionChange(e)} value={description} variant="standard" />
                    </Stack>
                </Box>
                
                <Stack  spacing={3}>
                {questions.map((item: any, index: number) => (
                        <Box
                        id={item.id.toString()}
                        key={'box-'+item.id.toString()}
                        sx={{
                            '& > :not(style)': { mt: 5, mx: 5, mb: 2, width: '93ch' },
                            maxWidth:"100ch",
                            flexWrap: 'wrap',
                            backgroundColor: '#FFFFFF',
                            border: 1,
                            borderColor: 'grey.300',
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: '16px',
                        }}
                        >
                            <Grid container spacing={2} justifyContent="flex-end">
                                <Grid item xs={8} >
                                    <Stack spacing={2}>
                                    
                                    <TextField fullWidth required id={index.toString()} placeholder={t("form.question_text")} defaultValue={item.text} onChange={(e)=> {setApplyDisabled(false); handleInputChange(e);}} variant="standard" />
                                    <Grid item xs={12}></Grid>
                                    {item.question_type_id == 1?(<Input fullWidth disabled value="Text Answer Field" inputProps={ariaLabel} />
                                    ):(item.question_type_id == 4?(
                                    <Stack spacing={1}>
                                        {item.question_options.map((it: any, o_index: number) => (
                                        
                                            <Grid item key={'q-option4-'+o_index.toString()} xs={12}><Checkbox disabled /><TextField required id={o_index.toString()} placeholder={"Answer Option"} key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={it.text} onChange={e => {setApplyDisabled(false); handleOptionsInputChange(e, index, o_index);}}/><IconButton color="primary" onClick={deleteOldQuestionOption(o_index, index, item.id, it.order)}><CloseIcon fontSize="small" /></IconButton></Grid>
                                        
                                        ))}
                                        {addedQuestionOptions.map((i: any, o_index: number) => (
                                            <div key={'addedq-option4-'+o_index.toString()}>
                                            { i.question_id===item.id?(<Grid item xs={12}><Checkbox disabled /><TextField required id={o_index.toString()} placeholder={"Answer Option"} key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={i.text} onChange={e => {setApplyDisabled(false); handleAddedOptionsInputChange(e, index, o_index);}}/><IconButton color="primary" onClick={deleteQuestionOptionAdded(o_index, index)}><CloseIcon fontSize="small" /></IconButton></Grid>):(<div></div>)}
                                            </div>
                                        ))}
                                        
                                        <Grid item xs={12}>
                                            <IconButton color="primary" onClick={addQuestionOption(index, item.id )}>
                                                <AddIcon fontSize="small" />
                                            </IconButton>
                                        </Grid>
                                    </Stack>
                                        
                                    ):(item.question_type_id == 3?(<Stack spacing={1}>
                                        {item.question_options.map((it: any, o_index: number) => (
                                        
                                            <Grid key={'q-option3-'+o_index.toString()} item xs={12}> <Radio disabled/><TextField required id={o_index.toString()} placeholder={"Answer Option"} key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={it.text} onChange={e => {setApplyDisabled(false); handleOptionsInputChange(e, index, o_index);}}/><IconButton color="primary" onClick={deleteOldQuestionOption(o_index, index, item.id, it.order)}><CloseIcon fontSize="small" /></IconButton></Grid>
                                        
                                        ))}

                                        {addedQuestionOptions.map((i: any, o_index: number) => (
                                            <div key={'addedq-option3-'+o_index.toString()}>
                                            { i.question_id===item.id?(<Grid item xs={12}><Radio disabled/><TextField required id={o_index.toString()} placeholder={"Answer Option"} key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={i.text} onChange={e => {setApplyDisabled(false); handleAddedOptionsInputChange(e, index, o_index);}}/><IconButton color="primary" onClick={deleteQuestionOptionAdded(o_index, index)}><CloseIcon fontSize="small" /></IconButton></Grid>):(<div></div>)}
                                            </div>
                                        ))}
                                        <Grid item xs={12}>
                                            <IconButton color="primary" onClick={addQuestionOption(index, item.id)}>
                                                <AddIcon fontSize="small" />
                                            </IconButton>
                                        </Grid>
                                    </Stack>):(item.question_type_id == 2?(<Input fullWidth disabled value="Number Answer Field"  />
                                    ):(item.question_type_id == 5?(<Input disabled value="Day/Month/Year" endAdornment={
                                        <InputAdornment position="start">
                                          <EventIcon />
                                        </InputAdornment>
                                      } inputProps={ariaLabel} />):(item.question_type_id == 6?(<Input disabled value="Time" endAdornment={
                                        <InputAdornment position="start">
                                          <AccessTimeIcon  />
                                        </InputAdornment>
                                      } inputProps={ariaLabel} />):(<Input disabled value="Day/Month/Year Hour:Minutes" endAdornment={
                                        <InputAdornment position="start">
                                          <EventIcon />
                                        </InputAdornment>
                                      } inputProps={ariaLabel} />))))))}
                                    </Stack>
                                </Grid>

                                <Grid item xs={4}>
                                <FormControl fullWidth disabled>
                                  <NativeSelect
                                      defaultValue={item.question_options_name}
                                      >
                                        <option value={item.question_options_name}>{t("form.input_type."+item.question_options_name)}</option>
                                  </NativeSelect>
                                </FormControl>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}>
                                    <Divider variant="middle" />
                                </Grid>
                                <Grid item xs={3} >
                                    <IconButton aria-label="delete" onClick={deleteOldQuestion(index, item.order)} size="large">
                                        <DeleteIcon />
                                    </IconButton>
                                    <FormControlLabel control={<Switch checked={item.is_required} onClick={()=>handleSwitch(index)}/>} label={t("form.required")} />
                                </Grid>
                            </Grid>
                        </Box>


                        
                    ))}

              {addedQuestions.map((item: any, index: number) => (
                        <Box
                        id={item.order.toString()}
                        key={'added-box-'+item.order.toString()}
                        sx={{
                            '& > :not(style)': { mt: 5, mx: 5, mb: 2, width: '93ch' },
                            maxWidth:"100ch",
                            flexWrap: 'wrap',
                            backgroundColor: '#FFFFFF',
                            border: 1,
                            borderColor: 'grey.300',
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: '16px',
                        }}
                        >
                            <Grid container spacing={2} justifyContent="flex-end">
                                <Grid item xs={8} >
                                    <Stack spacing={2}>
                                    
                                    <TextField fullWidth required id={index.toString()} placeholder={t("form.question_text")} defaultValue={item.text} onChange={(e)=> {setApplyDisabled(false); handleAddedInputChange(e);}} variant="standard" />
                                    <Grid item xs={12}></Grid>
                                    {item.question_type == 1?(<Input fullWidth disabled value="Text Answer Field" inputProps={ariaLabel} />
                                    ):(item.question_type == 4?(
                                    <Stack spacing={1}>
                                        {item.question_options.map((it: any, o_index: number) => (
                                        
                                            <Grid key={'4key-'+o_index.toString()} item xs={12}><Checkbox disabled /><TextField required id={o_index.toString()} placeholder={"Answer Option"} key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={it.text} onChange={e => {setApplyDisabled(false); handleOptionsAddedInputChange(e, index, o_index);}}/><IconButton color="primary" onClick={deleteAddedQuestionOption(o_index, index)}><CloseIcon fontSize="small" /></IconButton></Grid>
                                        
                                        ))}
                                        <Grid item xs={12}>
                                            <IconButton color="primary" onClick={addAddedQuestionOption(index)}>
                                                <AddIcon fontSize="small" />
                                            </IconButton>
                                        </Grid>
                                    </Stack>
                                        
                                    ):(item.question_type == 3?(<Stack spacing={1}>
                                        {item.question_options.map((it: any, o_index: number) => (
                                        
                                            <Grid key={'3key-'+o_index.toString()} item xs={12}> <Radio disabled/><TextField id={o_index.toString()} placeholder={"Answer Option"} key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={it.text} onChange={e => {setApplyDisabled(false); handleOptionsAddedInputChange(e, index, o_index);}}/><IconButton color="primary" onClick={deleteAddedQuestionOption(o_index, index)}><CloseIcon fontSize="small" /></IconButton></Grid>
                                        
                                        ))}
                                        <Grid item xs={12}>
                                            <IconButton color="primary" onClick={addAddedQuestionOption(index)}>
                                                <AddIcon fontSize="small" />
                                            </IconButton>
                                        </Grid>
                                    </Stack>):(item.question_type == 2?(<Input fullWidth disabled value="Number Answer Field"  />
                                    ):(item.question_type == 5?(<Input disabled value="Day/Month/Year" endAdornment={
                                        <InputAdornment position="start">
                                          <EventIcon />
                                        </InputAdornment>
                                      } inputProps={ariaLabel} />):(item.question_type == 6?(<Input disabled value="Time" endAdornment={
                                        <InputAdornment position="start">
                                          <AccessTimeIcon  />
                                        </InputAdornment>
                                      } inputProps={ariaLabel} />):(<Input disabled value="Day/Month/Year Hour:Minutes" endAdornment={
                                        <InputAdornment position="start">
                                          <EventIcon />
                                        </InputAdornment>
                                      } inputProps={ariaLabel} />))))))}
                                    </Stack>
                                </Grid>

                                <Grid item xs={4}>
                                <FormControl fullWidth>
 
                                    <NativeSelect
                                        defaultValue={item.question_type}
                                        id= {index.toString()}
                                        inputProps={{
                                            name: 'age',
                                            id: index.toString(),
                                        }}
                                        onChange={e => handleSelectChange(e)}
                                        >
                                        {types.map((item: any, i: number) => {
                                            return (
                                                <option key={'option-'+i.toString()} value={item.id}>{t("form.input_type."+item.name)}</option>
                                            )
                                        })}
                                        </NativeSelect>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}>
                                    <Divider variant="middle" />
                                </Grid>
                                <Grid item xs={3} >
                                    <IconButton aria-label="delete" onClick={deleteAddedQuestion(index)} size="large">
                                        <DeleteIcon />
                                    </IconButton>
                                    <FormControlLabel control={<Switch checked={item.is_required} onClick={()=> handleAddedSwitch(index)}/>} label={t("form.required")} />
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
                    </Stack>
                
                <Button variant="outlined" color='primary' startIcon={<AddIcon />} onClick={addQuestion}>
                    {t("form.add_question")}
                </Button>  
                </Stack>

                  </form>)}  
                </div>            

        </TabPanel>
        <TabPanel value="2">       
        <Stack alignItems="center" justifyContent="space-between" spacing={2}>
            <TableContainer component={Paper} sx={{ borderRadius: '16px', 
                                                    maxWidth:"150ch", 
                                                    flexWrap: 'wrap',
                                                    backgroundColor: '#FFFFFF',
                                                    border: 1,
                                                    borderColor: 'grey.300',
                                                    display: 'flex' }}>
                      

                      <Toolbar
                        sx={{
                          pl: { sm: 3 },
                          pr: { xs: 2, sm: 2 },
                          flexGrow: 1,
                        }}
                      >
                    
                      {/* <Tooltip title="Download As CSV"> */}
                      <LoadingButton loading={csvLoading} variant='contained' color={'primary'} onClick={handleCsvDownload}>
                        {t("report.csv_download_btn")} <DownloadSharpIcon />
                      </LoadingButton>
                      {/* </Tooltip> */}
                      </Toolbar>
                      <CSVLink
                          filename={name}
                          data={csvData}
                          // enclosingCharacter={``}
                          ref={csvLink}
                        >
                      </CSVLink>
                      
                      <Table aria-label="collapsible table">
                        <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell>{t("table.name")}</TableCell>
                          <TableCell align="right">{t("table.email")}</TableCell>
                          <TableCell align="right">{t("form.last_requested_date")}</TableCell>
                          <TableCell align="right">{t("form.form_answer")}</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {tableRows.map((row) => (

                          <Row key={row.user_id} row={row} id={id} DeleteData={DeleteData}/>
                        ))}
                        </TableBody>
                      </Table>
                      </TableContainer>
                      
                </Stack>
                <DeleteFormInstanceDialog updateRender={updateRender} deleteDialog={deleteDialog} /> 
                </TabPanel>
                <TabPanel value="3">
                  <Stack alignItems="center" justifyContent="center" spacing={2}>
                  <Box
                  sx={{
                      '& > :not(style)': { m: 5, width: '93ch' },
                      maxWidth:"100ch",
                      flexWrap: 'wrap',
                      backgroundColor: '#FFFFFF',
                      border: 1,
                      borderColor: 'grey.300', 
                      display: 'flex',
                      justifyContent: 'center',
                      borderRadius: '16px',
                  }}
                  >
                      <Grid container spacing={1}>
                      <Grid item xs={12}>
                      <Typography variant="h3"> {t("form.form_settings")} </Typography>
                      </Grid>
                      <Grid item xs={12}>
                      <FormControlLabel control={<Switch checked={isDefault} onClick={handleDefaultSwitch}/>} label={t("form.make_to_default_form")} />
                      <FormHelperText>{t("form.make_to_default_form_desc")}</FormHelperText>
                      </Grid>
                      </Grid>
                  </Box>    
                  </Stack>

      </TabPanel>
      </TabContext>
      </div>
	);
}

export default withRouter(FormHistory);
