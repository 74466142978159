import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Card,
	CardContent,
	Typography,
	Tooltip, 
	Button,
	Badge } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export default function Stats(props: any) {
	const { id, name, type } = props
	const { t } = useTranslation();

	return (
		<Box component={RouterLink} to={'/staff/reports/' + id}>
			<Badge 
				invisible={id === "00005095" ? false : true} 
				badgeContent={
					<Tooltip title={t('tooltip.staff.report.'+id)} 
						arrow placement='left'>
						<Button sx={{color: 'text.secondary'}} style={{ backgroundColor: 'transparent'}} >
							<HelpOutlineIcon></HelpOutlineIcon>
						</Button>
					</Tooltip>}>
				<Card sx={{ boxShadow: 6, pt: 6, pb: 2 }}>
					<CardContent sx={{ textAlign: 'center', py: 0}}>
						<img
							src={'/static/img/dashboard/' + id + '.svg'}
							alt={name}
							width='50%'
						/>
						<Typography variant='h6'>
							{t('report.'+id, name)}
						</Typography>
					</CardContent>
				</Card>
			</Badge>
		</Box>
	);
}