import { TimeSeries } from './time-series';
import ImageFile from './image-file';

import withRouter from "src/hoc/withRouter";

function ClientReportsResQParams(props: any) {
  const { params } = props;

  if (params.type === "time_series") return <TimeSeries id={params.id} />;
  if (params.type === "image_file") return <ImageFile id={params.id} />;
}

export default withRouter(ClientReportsResQParams);
