import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import api from 'src/utilities/axios';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Stack from '@material-ui/core/Stack';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import EventIcon from '@material-ui/icons/Event';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Radio from '@material-ui/core/Radio';
import InputAdornment from '@material-ui/core/InputAdornment';
import Skeleton from '@material-ui/core/Skeleton';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useLocation } from "react-router-dom";
import withRouter from 'src/hoc/withRouter';
import moment from 'moment';

function FormShowAnswer(props: any) {
	const { t } = useTranslation();
  const location = useLocation();
  const id = props.params.id;
  const sent_order = location.state.sent_order;
  const user_id = location.state.user_id;
  const user_name = location.state.user_name;
  const user_email = location.state.user_email;
  const date = location.state.date;
  const ariaLabel = { 'aria-label': 'description' };
  const [all, setAll] = React.useState<any>({});
  const [questions, setQuestions] = React.useState<any>([]);
	const [answers, setAnswers] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);



  function fetchData(): void {
    setLoading(true);
		api({
			pathname: '/staff/forms/' + id + '/' + sent_order + '/answers/' + user_id,
			method: 'GET',
			handleResponse: (response: any): void => {
				setAll(response.data);
        setAnswers(response.data.answers);
				setQuestions(response.data.questions);
        setLoading(false);
			},
			handleError: (): void => {
				setLoading(false);
			}
		})

	}

	React.useEffect(fetchData, []);

  

	return (
<div>
      <Grid container justifyContent='space-between' spacing={3}>
                <Grid item>
                      <Typography variant='h3' sx={{ mb: 1 }}>
                          {t('form.form_answer')}
                      </Typography>
                      <Breadcrumbs aria-label='breadcrumb'>
                          <Link component={RouterLink} color='inherit' to='/staff'>
                              {t('navigation.dashboard')}
                          </Link>
                          <Link component={RouterLink} color='inherit' to='/staff/form'>
                              {t('navigation.forms')}
                          </Link>
                          <Link component={RouterLink} color='inherit' to={'/staff/form/'+id+'/history'}>
                              {t('navigation.form_detail')}
                          </Link>
                          <Typography color='text.primary'>
                              {all.name}
                          </Typography>
                      </Breadcrumbs>
                      
                  </Grid>
              </Grid>
        
              <Divider sx={{ my: 3 }} />
        
            <Stack alignItems="center" justifyContent="center" spacing={1}>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 5, width: '93ch' },
                    maxWidth:"100ch",
                    flexWrap: 'wrap',
                    backgroundColor: '#FFFFF0',
                    border: 1,
                    borderColor: 'grey.300', 
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '16px',
                }}
                noValidate
                autoComplete="off"
                >
                    <Stack  spacing={3}>
                    
                        <Typography variant="h3" gutterBottom>{user_name+ ':  '+ user_email}</Typography>
                        <Typography variant="subtitle2" gutterBottom>{moment.utc(date).local().format("YYYY/MM/DD HH:mm")}</Typography>
                    </Stack>
                </Box>

                
                <div>
            
        {loading?(<div>
          
          <Stack alignItems="center" justifyContent="center" spacing={3}>
          <Skeleton variant="rectangular" sx={{
                    '& > :not(style)': { m: 5, width: '100ch' },
                    maxWidth:"100ch",
                    flexWrap: 'wrap',
                    border: 1,
                    borderColor: 'grey.300', 
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '16px',
                }} animation="wave" width={'100ch'} height={'27ch'} />
          <Skeleton variant="rectangular" sx={{
                    '& > :not(style)': { m: 5, width: '100ch' },
                    maxWidth:"100ch",
                    flexWrap: 'wrap',
                    border: 1,
                    borderColor: 'grey.300', 
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '16px',
                }} animation="wave" width={'100ch'} height={'30ch'} />
          <Skeleton variant="rectangular" sx={{
                    '& > :not(style)': { m: 5, width: '100ch' },
                    maxWidth:"100ch",
                    flexWrap: 'wrap',
                    border: 1,
                    borderColor: 'grey.300', 
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '16px',
                }} animation="wave" width={'100ch'} height={'30ch'} />
          </Stack>
          </div>):(
        <div>
          
                <Stack  spacing={1}>
                <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 5, width: '93ch' },
                    maxWidth:"100ch",
                    flexWrap: 'wrap',
                    backgroundColor: '#FFFFFF',
                    border: 1,
                    borderColor: 'grey.300', 
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '16px',
                }}
                noValidate
                autoComplete="off"
                >
                    <Stack  spacing={3}>
                    
                        <Typography variant="h3" gutterBottom>{all.name}</Typography>
                        <Typography variant="subtitle2" sx={{whiteSpace: "pre-line"}} gutterBottom>
                          {all.description}
                    </Typography>
                    </Stack>
                </Box>
                {questions.map((item: any, index: number) => (
                        <Box
                        id={index.toString()}
                        key={'box-'+index.toString()}
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 5, width: '93ch' },
                            maxWidth:"100ch",
                            flexWrap: 'wrap',
                            backgroundColor: '#FFFFFF',
                            border: 1,
                            borderColor: 'grey.300',
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: '16px',
                        }}
                        noValidate
                        autoComplete="off"
                        >
                            <Grid container spacing={2} justifyContent="flex-end">
                                <Grid item xs={12} >
                                    <Stack spacing={2}>
                                    
                                    <Typography variant="h5" gutterBottom>{item.text} </Typography>
                                    <Grid item xs={12}></Grid>
                                    {item.question_type_id == 1?(<Input fullWidth disabled sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "black",
                                        },
                                      }} value={answers.find(o=>o.question_id=== item.id)?.value} inputProps={ariaLabel} />
                                    ):(item.question_type_id == 4?(
                                    <Stack spacing={1}>
                                        {item.question_options.map((it: any, o_index: number) => (
                                        
                                            <Grid key={'qoptions4-'+o_index.toString()} item xs={12}>{answers.find(o => o.question_id === item.id)?.answer_option_order?.match(/\d+/g)?.map(Number).includes(o_index)?(<Checkbox checked={true} disabled/>):(<Checkbox disabled/>)}
                                            <Input sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                  WebkitTextFillColor: "black",
                                                },
                                              }} disabled value={it.text}  /></Grid>
                                        
                                        ))}
                                        
                                    </Stack>
                                        
                                    ):(item.question_type_id == 3?(<Stack spacing={1}>
                                        {item.question_options.map((it: any, o_index: number) => (
                                        
                                            <Grid key={'qoptions3-'+o_index.toString()} item xs={12}> {answers.find(o => o.question_id === item.id)?.answer_option_order?.match(/\d+/g)?.map(Number).includes(o_index)?(<Radio checked={true} disabled/>):(<Radio disabled/>)}
                                            
                                            <Input sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                  WebkitTextFillColor: "black",
                                                },
                                              }} disabled value={it.text}  /></Grid>
                                        
                                        ))}
                                    </Stack>):(item.question_type_id == 2?(<Input fullWidth sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "black",
                                        },
                                      }} disabled value={answers.find(o=>o.question_id=== item.id)?.value}  />
                                    ):(item.question_type_id == 5?(<Input disabled  sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "black",
                                        },
                                      }} value={answers.find(o=>o.question_id=== item.id)?.value} endAdornment={
                                        <InputAdornment position="start">
                                          <EventIcon />
                                        </InputAdornment>
                                      } inputProps={ariaLabel} />):(item.question_type_id == 6?(<Input disabled sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "black",
                                        },
                                      }} value={answers.find(o=>o.question_id=== item.id)?.value} endAdornment={
                                        <InputAdornment position="start">
                                          <AccessTimeIcon  />
                                        </InputAdornment>
                                      }/>):(<Input disabled sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "black",
                                        },
                                      }} value={answers.find(o=>o.question_id=== item.id)?.value} endAdornment={
                                        <InputAdornment position="start">
                                          <EventIcon />
                                        </InputAdornment>
                                      } inputProps={ariaLabel} />))))))}
                                    </Stack>
                                </Grid>

                                <Grid item xs={4}>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                
                            </Grid>
                        </Box>


                        
                    ))}
                    </Stack> 
                    </div>)}  
                </div>          

                               
                </Stack>
                
                </div>
	);
}

export default withRouter(FormShowAnswer);
