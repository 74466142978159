import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import AnswerFormDialog from '../answer-form-dialog';

interface IAnswerTableProps {
	data: any,
	updateRender: any
}

function AnswerTable(props: IAnswerTableProps): JSX.Element {
	const { data, updateRender } = props;
	const { t } = useTranslation();

	return (
		<Card sx={{ boxShadow: 6 }}>
			<Grid container justifyContent='space-between'>
				<Grid item sx={{ px: 2, py: 3 }}>
					<Typography variant='h6' display='inline'>
						{t('navigation.answers')}
					</Typography>
				</Grid>
				<Grid item sx={{ px: 2, py: 3 }}>
					<AnswerFormDialog data={data} updateRender={updateRender} />
				</Grid>
				<Grid item xs={12}>
					<Table>
						<TableBody>
							{data.answers && data.answers.map((item: any, index: number) => (
								<TableRow key={index}>
									<TableCell>{item.value}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</Card>
	)
}

export default AnswerTable;