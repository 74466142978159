import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Alert from '@material-ui/core/Alert';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import Loader from 'src/components/loader';
import api from 'src/utilities/axios';

import DataTable from './components/data-table'; 

function Data(): JSX.Element {
	const { t } = useTranslation();
	
	const [data, setData] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<JSX.Element>(null);

	function fetchData(): void {
		setLoading(true);

		api({
			pathname: '/user/data',
			method: 'GET',
			handleResponse: (response: any): void => {
				setData(response.data);
				setLoading(false);
			},
			handleError: (error: any): void => {
				setLoading(false);
				setMessage(<Alert severity='error' sx={{ mb: 3 }}>{error.response.data.detail}</Alert>);
			}
		})
	}

	React.useEffect(fetchData, []);

	return (
		<Loader state={loading}>
			<Typography variant='h3' sx={{ mb: 1 }}>
				{t('navigation.data')}
			</Typography>
			<Breadcrumbs aria-label='breadcrumb'>
				<Link component={RouterLink} color='inherit' to='/client'>
					{t('navigation.dashboard')}
				</Link>
				<Typography color='text.primary'>
					{t('navigation.data')}
				</Typography>
			</Breadcrumbs>

			<Divider sx={{ my: 3 }} />

			{message}

			<DataTable data={data} />
		</Loader>
	);
}

export default Data;