import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import Typography from '@material-ui/core/Typography';
import UniversalCookie from "universal-cookie";
import api from 'src/utilities/axios';
import Loader from 'src/components/loader';
import DeleteFormDialog from './components/delete-form-dialog';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import TextField from '@material-ui/core/TextField';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';

const COOKIE = new UniversalCookie();
interface IStatsProps {
	id: number
	name: string
	answer_progress: string
	date: string
	is_editable: boolean
	is_default: boolean
    user: object
    entity_name: string
    updateRender:any
	is_duplicate:boolean
}


export default function Forms(): JSX.Element {
	const [duplicateData, setDuplicateData] = React.useState<any[]>([]);

	function Stats(props: IStatsProps) {
		const { id, name, answer_progress, date, is_editable, is_default, user, entity_name, updateRender, is_duplicate } = props;
		const { t } = useTranslation();
		const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
		const open = Boolean(anchorEl);
		const handleClick = (event: React.MouseEvent<HTMLElement>) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		const [duplicatedName, setDuplicatedName] = React.useState<any>(name);

		const handleDuplicatedNameChange = e =>{
			setDuplicatedName(e.target.value);
		  };

		const today = new Date();
		const yyyy = today.getFullYear();
		const mm = today.getMonth() + 1; 
		const dd = today.getDate();
		var day = dd.toString();
		var month = mm.toString();

		if (dd < 10) {var day = '0' + dd.toString()}
		;
		if (mm < 10) {var month = '0' + mm.toString()}
		;

		const formattedToday = yyyy + '/' + month + '/' + day;


		const cookie = new UniversalCookie();

		function handleDelete(event: React.SyntheticEvent): void {
			event.preventDefault();
			event.persist();

			setDeleteState(true);
			setDeleteData(id);
			
		}

		function handleDuplicate(event: React.SyntheticEvent): void {
			event.preventDefault();
			event.persist();


			api({
				pathname: '/staff/' + cookie.get('workspace') + '/forms',
				method: 'PUT',
				data: {
					id: duplicateData[0].id,
					name: duplicatedName
	
				},
				
				handleResponse: (): void => {
					setDuplicateData([]);
					setDuplicateData(currentRows=>[...currentRows]);
					handleClose();
					updateRender();
				},
				handleError: (): void => {
				}
			})
		}

		function handleClickDuplicate(event: React.SyntheticEvent): void {
			event.preventDefault();
			event.persist();
			
			setDuplicateData(currentRows=>[...currentRows,{'name':name, 'entity_name':entity_name, 'id':id}]);
			
		}


		

		return (
			
				<Card variant="outlined" sx={{ boxShadow: 6, pb: 2, maxHeight: 369, minHeight: 369}}>
					{!is_duplicate?(<div>
					<CardHeader
						action={
						<IconButton aria-label="more"
						id="long-button"
						aria-controls={open ? 'long-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						aria-haspopup="true"
						onClick={handleClick}>
							<MoreVertIcon />
						</IconButton>
						}
					/>
					<Menu
						id="long-menu"
						MenuListProps={{
						'aria-labelledby': 'long-button',
						}}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						
					>
						<MenuItem onClick={handleClickDuplicate} disableRipple>
							<FileCopyIcon />
							{t('form.form_duplicate')}
						</MenuItem>
						<MenuItem onClick={handleDelete} disableRipple>
							<DeleteIcon />
							{t('form.form_delete')}
						</MenuItem>
					</Menu>
					<Box component={RouterLink} to={'/staff/form/'+id+'/history'}>
					<CardContent sx={{ textAlign: 'center', py: 0}}>
						<Typography variant='h1' fontWeight='fontWeightLight' sx={{ pt: 0, pb: 1 }}>
							<Box>
								{name}
							</Box>
						</Typography>
						<DescriptionSharpIcon sx={{ fontSize: 99, color:'gray' }}></DescriptionSharpIcon>
						<Typography variant='h3' sx={{ pt: 1, pb: 3 }}>
							<Box fontWeight='fontWeightLight' color={'gray'}>
								{moment.utc(date).local().format("YYYY/MM/DD")}
							</Box>
						</Typography>
					</CardContent>
					
					<CardContent sx={{ textAlign: 'right', pt: 2}}>
						<Typography sx={{ mb: 0 }} color="text.secondary">
							{entity_name}
						</Typography>
					</CardContent>
					</Box>
					</div>):(<div><CardHeader action={
						<IconButton aria-label="more"
						id="long-button"
						disabled={true}
						aria-controls={open ? 'long-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						aria-haspopup="true">
							<MoreVertIcon />
						</IconButton>
						}/>
					<Box>
					<CardContent sx={{ textAlign: 'center', py: 0}}>
					<Box
							component="form"
							sx={{
								'& > :not(style)': { m: 1, width: '25ch' },
							}}
							noValidate
							autoComplete="off"
							>
							<TextField id="inPut" InputProps={{ style: { fontSize: 21 } }} autoFocus onChange={e => handleDuplicatedNameChange(e)} defaultValue={duplicatedName} onBlur={handleDuplicate} label="Name" variant="standard" onKeyPress={(ev) => {
								
								if (ev.key === 'Enter') {
									handleDuplicate(ev);
								}
							}}/>
							
					</Box>
						<DescriptionSharpIcon sx={{ fontSize: 99, color:'gray' }}></DescriptionSharpIcon>
						<Typography variant='h3' sx={{ pt: 1, pb: 3 }}>
							<Box fontWeight='fontWeightLight' color={'gray'}>
								{formattedToday}
							</Box>
						</Typography>
					</CardContent>
					
					<CardContent sx={{ textAlign: 'right', pt: 2}}>
						<Typography sx={{ mb: 0 }} color="text.secondary">
							{entity_name}
						</Typography>
					</CardContent>
					</Box></div>)}
				
				</Card>
			// </Box>
		);
	}
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [data, setData] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [render, setRender] = React.useState<boolean>(false);
	const [deleteState, setDeleteState] = React.useState<boolean>(false);
	const [deleteData, setDeleteData] = React.useState<number>(0);

	const updateRender = (): void => setRender(!render);

	const deleteDialog = {
		state: deleteState,
		data: deleteData,

		setState: setDeleteState,
		setData: setDeleteData
	}


	function fetchData(): void {
		setLoading(true);

		api({
			pathname: '/staff/'+ COOKIE.get("workspace") + '/forms',
			method: 'GET',
			handleResponse: (response: any): void => {
				setLoading(false);
				setData(response.data);
			},
			handleError: (): void => {
				setLoading(false)
			}
		})

		setLoading(true);
	}

	React.useEffect(fetchData, [render]);

	return (
		<Loader state={loading}>
			<Grid container justifyContent='space-between' spacing={3}>
				<Grid item>
				<Typography variant='h3' sx={{ mb: 1 }}>
						{t('navigation.forms')}
					</Typography>
					<Breadcrumbs aria-label='breadcrumb'>
						<Link component={RouterLink} color='inherit' to='/staff'>
							{t('navigation.dashboard')}
						</Link>
						<Typography color='text.primary'>
							{t('navigation.forms')}
						</Typography>
					</Breadcrumbs>
				</Grid>
				<Grid item>
					<Button variant='contained' color='primary' onClick={()=> navigate('/staff/form/create')}>
						<AddSharpIcon /> {t('form.create_form')}
					</Button>
				</Grid>
			</Grid>

			<Divider sx={{ my: 3 }} />
			
			<Grid container spacing={3}>
				{data.map((item: any, iteration: number) => (
					<Grid key={iteration} item xs={12} sm={4} md={4} lg={3} xl={2}>
						<Stats {...item} updateRender={updateRender} is_duplicate={false}/>
					</Grid>
				))}

				{duplicateData.map((item: any, iteration: number) => (
					<Grid key={iteration} item xs={12} sm={4} md={4} lg={3} xl={2}>
						<Stats {...item} updateRender={updateRender} is_duplicate={true}/>
					</Grid>
				))}
				
			</Grid>
			<DeleteFormDialog updateRender={updateRender} deleteDialog={deleteDialog} />

		</Loader>
	)
}