import DashboardLayout from "src/layouts/Dashboard";
import Home from "src/views/common/home";
import { Reports } from "src/views/client/reports";
import ReportsResQParams from "src/views/client/reports/resq-band/params";
import Data from "src/views/client/data";
import DataParams from "src/views/client/data/params";
import Entities from "src/views/client/entities";
import EntitiesParams from "src/views/client/entities/params";
import {Forms} from "src/views/client/forms";
import FormDetail from "src/views/client/forms/form-detail";
import FormClientShowAnswer from "src/views/client/forms/show-answer";
import FormDetailAnswer from "src/views/client/forms/form-answer";


import Profile from "src/views/common/profile";

const clientRoutes = {
	path: 'client',
	element: <DashboardLayout usertype={'client'}/>,
	children: [
		{ path: '', element: <Home /> },
		{ path: 'reports', element: <Reports /> },
		{ path: 'reports/resq-band/:type/:id', element: <ReportsResQParams /> },
		{ path: 'data', element: <Data /> },
		{ path: 'data/:id', element: <DataParams /> },
		{ path: 'entities', element: <Entities /> },
		{ path: 'forms', element: <Forms /> },
		{ path: 'forms/:id/detail', element: <FormDetail /> },
		{ path: 'forms/:id/detail/show-answer', element: <FormClientShowAnswer /> },
		{ path: 'forms/:id/detail/answer', element: <FormDetailAnswer /> },
		{ path: 'entities/:workspace_name', element: <EntitiesParams /> },
		{ path: 'profile', element: <Profile />}
	]
}

export default clientRoutes;
