import React from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@material-ui/core/Alert';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
 
import api from 'src/utilities/axios';
 
import UniversalCookie from 'universal-cookie';

interface IProfileFormProps {
	data: any
}

function ProfileForm(props: IProfileFormProps) {
	const { data } = props;
	const cookie = new UniversalCookie();
	const { t } = useTranslation();
 
	const [loading, setLoading] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<JSX.Element>(null);
 
	function handleSubmit(event: React.SyntheticEvent): void {
		event.preventDefault();
		event.persist();
 
		setLoading(false);
		setMessage(null);
 
		const target = event.target as typeof event.target & {
			displayname: { value: string },
			firstname: { value: string },
			lastname: { value: string },
			firstnameKana: { value: string },
			lastnameKana: { value: string },
			phoneNumber1: { value: string },
			phoneNumber2: { value: string },
			address: { value: string },
		}
 
		api({
			pathname: '/user/info',
			method: 'PUT',
			data: {
				display_name: target.displayname.value,
				first_name: target.firstname.value,
				last_name: target.lastname.value,
				first_name_kana: target.firstnameKana.value,
				last_name_kana: target.lastnameKana.value,
				phone_number1: target.phoneNumber1.value,
				phone_number2: target.phoneNumber1.value,
				address: target.address.value,
				
			},
			handleResponse: (): void => {
				window.scrollTo(10,0);
				setMessage(<Alert severity='success' sx={{ mb: 3 }}>Request Successful</Alert>);
				setLoading(false);
			},
			handleError: (): void => {
				setLoading(false);
			}
		})
 
	}
 
	return (
		<Card sx={{ boxShadow: 6, px: 2, py: 3}}>
			{message}
			<form onSubmit={handleSubmit}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant='h6'>
							{t('profile.profile_info_title')}
						</Typography>
					</Grid>
					<Grid item md={12} xs={12}>
						<TextField
							id='displayname'
							label={t('profile.display_name')}
							variant='outlined'
							defaultValue={data.display_name && data.display_name}
							fullWidth
							required
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<TextField
							id='firstname'
							label={t('profile.first_name')}
							variant='outlined'
							defaultValue={data.first_name && data.first_name}
							fullWidth
							required
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<TextField
							id='lastname'
							label={t('profile.last_name')}
							variant='outlined'
							defaultValue={data.last_name && data.last_name}
							fullWidth
							required
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<TextField
							id='firstnameKana'
							label={t('profile.first_name_kana')}
							variant='outlined'
							defaultValue={data.first_name_kana && data.first_name_kana}
							fullWidth
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<TextField
							id='lastnameKana'
							label={t('profile.last_name_kana')}
							variant='outlined'
							defaultValue={data.last_name_kana && data.last_name_kana}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id='email'
							label={t('profile.email')}
							variant='outlined'
							type='email'
							defaultValue={cookie.get('userEmail')}
							fullWidth
							disabled
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id='phoneNumber1'
							label={t('profile.phone1')}
							variant='outlined'
							type='string'
							defaultValue={data.phone_number1 && data.phone_number1}
							inputProps={{
								'pattern': '([0-9]{0,})'
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id='phoneNumber2'
							label={t('profile.phone2')}
							variant='outlined'
							type='string'
							defaultValue={data.phone_number2 && data.phone_number2}
							inputProps={{
								'pattern': '([0-9]{0,})'
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id='address'
							label={t('profile.address')}
							variant='outlined'
							defaultValue={data.address && data.address}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} style={{'textAlign': 'right'}}>
						<Button type='submit' variant='contained' color='primary' disabled={loading}>
							{t('profile.save_btn')}
						</Button>
					</Grid>
				</Grid>
			</form>
		</Card>
	);
}

export default ProfileForm;