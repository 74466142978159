import React, { ReactNode } from 'react'

import { Stack, Typography, StackProps } from "@material-ui/core"

interface ReferenceboxProps extends StackProps {
    data: ReactNode[];
}

export default function ReferenceBox({ data, ...props }: ReferenceboxProps) {
    return (
        <Stack sx={{ backgroundColor: "background.default", p: 2, maxWidth: "480px", borderRadius: 1 }} spacing={2} {...props}>
            {data.map((section, index) => (
                <Stack key={index} direction="row" spacing={2}>
                    <Typography>※</Typography>
                    <Stack spacing={2}>
                        {section}
                    </Stack>
                </Stack>
            ))}
        </Stack>
    )
}
