import React from 'react';
import { useTranslation } from 'react-i18next';
import withRouter from 'src/hoc/withRouter';

import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import LoadingButton from '@material-ui/lab/LoadingButton';

import AddSharpIcon from '@material-ui/icons/AddSharp';

import Loader from 'src/components/loader';
import api from 'src/utilities/axios';

function AddFormDialog(props: any) {
	const { params, updateRender } = props;
	const { t } = useTranslation();

	const [open, setOpen] = React.useState<boolean>(false);
	const [handleLoading, setHandleLoading] = React.useState<boolean>(false);
	const [fetchLoading, setFetchLoading] = React.useState<boolean>(false);
	const [entityOptions, setEntityOptions] = React.useState<any[]>([]);
	const [selectedEntity, setSelectedEntity] = React.useState<any>(null);

	function handleAdd(event: React.SyntheticEvent): void {
		event.preventDefault();
		event.persist();

		setHandleLoading(true);

		const target = event.target as typeof event.target & {
			entity: { value: string };
		}

		api({
			pathname: '/user/data/' + params.id + '/entities/' + target.entity.value,
			method: 'POST',
			handleResponse: (): void => {
				setHandleLoading(false);
				handleClose();
				updateRender();
			},
			handleError: (): void => {
				setHandleLoading(false);
			}
		})
	}

	function fetchData(): void {
		setFetchLoading(true);

		api({
			pathname: '/user/data/' + params.id + '/entities',
			method: 'GET',
			handleResponse: (response: any): void => {
				setEntityOptions(response.data);
				setFetchLoading(false);
			},
			handleError: (): void => {
				setFetchLoading(false);
			}
		})
	}

	function handleClickOpen(): void {
		setOpen(true);
	};

	function handleClose(): void {
		setOpen(false);
	};

	React.useEffect(fetchData, []);

	return (
		<React.Fragment>
			<Button variant='contained' color='primary' onClick={handleClickOpen}>
				<AddSharpIcon /> {t('form.add_entity')}
			</Button>
			<Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
				<Loader state={fetchLoading}>
					<form onSubmit={handleAdd}>
						<DialogTitle>{t('form.add_entity')}</DialogTitle>
						<DialogContent>
							<FormControl fullWidth>
								<InputLabel id='entityLabel'>{t('form.entity')}</InputLabel>
								<Select
									name='entity'
									id='entity'
									labelId='entityLabel'
									label={t('form.entity')}
									value={selectedEntity}
									onChange={(event: any): void => setSelectedEntity(event.target.value)}
									required
								>
									<MenuItem value={null}>
										<em>None</em>
									</MenuItem>
									{entityOptions.map((item: any, iteration: number) => (
										<MenuItem
											key={iteration}
											value={item.workspace_name}
										>
											{item.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={handleClose}
								color='primary'
							>
								{t('form.cancel')}
							</Button>
							<LoadingButton
								component='button'
								type='submit'
								variant='contained'
								color='primary'
								loading={handleLoading}
							>
								{t('form.add')}
							</LoadingButton>
						</DialogActions>
					</form>
				</Loader>
			</Dialog>
		</React.Fragment>
	);
}

export default withRouter(AddFormDialog);