import React from "react";
import { useTranslation } from "react-i18next";
import UniversalCookie from "universal-cookie";
import moment from "moment";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { 
  FilterListSharp, 
  HelpOutline as HelpOutlineIcon } from "@material-ui/icons";
import { calculateEndTimeUnix } from "src/utilities/date/index.new";
import { apiAsync } from "src/utilities/axios";
import { StaffReportStateType } from "src/redux/staff-report/reducers";
import { removeMilliseconds } from "src/utilities/date";

export interface IPublicProps {
  params: any;
  setLoading: (_: boolean) => void;
}

export interface IReduxStateProps {
  reduxState: {
    staffReport: StaffReportStateType;
  };
}

export interface IReduxDispatchProps {
  setInterval: (_: any) => void;
  setComparingDataSet: (_: any) => void;
  setStartDate: (_: any) => void;
}

export type Props = IPublicProps & IReduxDispatchProps & IReduxStateProps;

const COOKIE = new UniversalCookie();
const TODAY = new Date();

export function FilterDialogComponent(props: any): JSX.Element {
  const { params, setGraphLoading, setDbpData } = props;
  const { interval, startDate } = props.reduxState.staffReport;

  const [open, setOpen] = React.useState(false);
  const [selectSet, setSelectSet] = React.useState<any>([]);
  const [stateInterval, setStateInterval] = React.useState<string>(interval);
  const [stateStartDate, setStateStartDate] = React.useState<Date>(startDate);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  /**
   * This function will fetch every selected report data.
   * 
   * @returns {any[]}
   */
  function fetchDataSet(): any[] {
    return selectSet.map((id: string): any => {
      let pathname =
        "/staff/" +
        COOKIE.get("workspace") +
        "/reports/" +
        id +
        "/" +
        params.uid;
      pathname += "?start_time=" + removeMilliseconds(startDate);
      pathname += "&end_time=" + calculateEndTimeUnix(interval, startDate);
      pathname += "&interval=" + interval;

      if (id === "00005095"){
        let dbp_pathname =
          "/staff/" +
          COOKIE.get("workspace") +
          "/reports/00005096/" +
          params.uid;
        dbp_pathname += "?start_time=" + removeMilliseconds(startDate);
        dbp_pathname += "&end_time=" + calculateEndTimeUnix(interval, startDate);
        dbp_pathname += "&interval=" + interval;

        Promise.resolve(apiAsync({ pathname: dbp_pathname, method: "GET" }))
          .then((response: any) => {
            setDbpData(response.data.report);
          })
          .catch((error: any) => {
            console.log(error);
        });
      }
      
      
      return apiAsync({ pathname: pathname, method: "GET" })
        .then((response: any) => {
          return response.data.report;
        })
        .catch((error: any) => {
          console.log(error);
          return undefined;
        });
    });
    
  }

  /**
   * This is onChange event function that will set the filter interval
   * 
   * @param event 
   */
  function onChangeInterval(event: any): void {
    const {
      target: { value },
    } = event;
    
    if (value === "weekly" || value === "monthly"){
      let copyDate = moment(TODAY);
      if (value === "weekly") {
        copyDate.subtract(6, "days");
      }
      if (value === "monthly") {
        copyDate.subtract(1, "months").add(1, "days");
      }

      copyDate.hour(0).minute(0).second(0);

      let startMaxDate = new Date(copyDate.toDate());
 
      if (startMaxDate < stateStartDate){
        setStateStartDate(startMaxDate);
      }
      else {
        let momentStateStartDate = moment(stateStartDate);
        momentStateStartDate.hour(0).minute(0).second(0);
        setStateStartDate(momentStateStartDate.toDate());
      }
    }
    
 
    if (value === "yearly") {
      let startOfYear = moment(stateStartDate).startOf("year");
      setStateStartDate(startOfYear.toDate());
    }

    setStateInterval(value);
  }

  /**
   * This is onChange event function that will set filter start DATE!
   * 
   * @param event
   */
  function onChangeStartDate(event: any): void {
    const {
      target: { value },
    } = event;
    let year = value.slice(0, 4);
    let month = value.slice(5, 7);
    let date = value.slice(8, 10);
    let updatedMomentDate = moment(stateStartDate)
      .year(year)
      .month(month - 1)
      .date(date);
    if (stateInterval !== "hourly" && stateInterval !== "daily")
      updatedMomentDate.hour(0).minute(0).second(0);
    setStateStartDate(updatedMomentDate.toDate());
  }

  /**
   * This is onChange event function that will set filter start TIME!
   * 
   * @param event
   */
  function onChangeStartTime(event: any): void {
    const {
      target: { value },
    } = event;
    let hours = value.slice(0, 2);
    let minutes = value.slice(3, 5);
    let updatedMomentDate = moment(stateStartDate)
      .hours(hours)
      .minutes(minutes);
    setStateStartDate(updatedMomentDate.toDate());
  }

  /**
   * This is onChange event function that will put reports selected by checkbox into setSelect state
   * 
   * @param {any} event
   * @returns {void}
   */
  function onChangeCompareCheckbox(event: any): void {
    const {
      target: { value },
    } = event;
    if (value.indexOf(params.id) > -1) return;
    setSelectSet(value);
  }

  /**
   * This function will submit the filter results.
   * Responses will go to the redux state
   * 
   * @returns {void}
   */
  function handleSave(): void {
    setGraphLoading(true);
    setLoading(true);
    props.setInterval(stateInterval);
    props.setStartDate(stateStartDate);

    Promise.all(fetchDataSet())
      .then((response: any) => {
        props.setComparingDataSet(response);
        handleClose();
        setGraphLoading(false);
        setLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        handleClose();
        setGraphLoading(false);
        setLoading(false);
      });
  }

  /**
   * This function will update comparingDataSet to redux state
   * triggered by startDate and interval value of redux state
   * I honestly don't know why this function is important but it helps don't touch it :)
   * 
   * @returns {void}
   */
  function updateAllDataByDateIndex(): void {
    setGraphLoading(true);
    Promise.all(fetchDataSet())
      .then((response: any) => {
        props.setComparingDataSet(response);
        setGraphLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setGraphLoading(false);
      });
    setStateStartDate(startDate);
  }

  React.useEffect(updateAllDataByDateIndex, [startDate, interval]);

  return (
    <React.Fragment>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        <FilterListSharp />
        {t("form.filter")}
      </Button>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <DialogTitle>{t("form.filter")}</DialogTitle>
        <DialogContent>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
              <FormControl sx={{ textAlign: "left", my: 1 }} fullWidth>
                <InputLabel id="compare-label">
                  {t("form.graph_comparison")}
                </InputLabel>
                <Select
                  id="compare-checkbox"
                  labelId="compare-label"
                  label={t("form.graph_comparison")}
                  multiple
                  value={selectSet}
                  renderValue={(selected) =>
                    selected.map((item: string) => t("report." + item) + ", ")
                  }
                  onChange={onChangeCompareCheckbox}
                >
                  <MenuItem value="00005091">
                    <Checkbox
                      size="small"
                      disabled={params.id === "00005091"}
                      checked={
                        params.id === "00005091" ||
                        selectSet.indexOf("00005091") > "-1"
                      }
                    />
                    <ListItemText primary={t("report.00005091")} />
                  </MenuItem>
                  <MenuItem value="00005092">
                    <Checkbox
                      size="small"
                      disabled={params.id === "00005092"}
                      checked={
                        params.id === "00005092" ||
                        selectSet.indexOf("00005092") > "-1"
                      }
                    />
                    <ListItemText primary={t("report.00005092")} />
                  </MenuItem>
                  <MenuItem value="00005093">
                    <Checkbox
                      size="small"
                      disabled={params.id === "00005093"}
                      checked={
                        params.id === "00005093" ||
                        selectSet.indexOf("00005093") > "-1"
                      }
                    />
                    <ListItemText primary={t("report.00005093")} />
                  </MenuItem>
                  <MenuItem value="00005094">
                    <Checkbox
                      size="small"
                      disabled={params.id === "00005094"}
                      checked={
                        params.id === "00005094" ||
                        selectSet.indexOf("00005094") > "-1"
                      }
                    />
                    <ListItemText primary={t("report.00005094")} />
                  </MenuItem>
                  <MenuItem value="00005095">
                    <Checkbox
                      size="small"
                      disabled={params.id === "00005095"}
                      checked={
                        params.id === "00005095" ||
                        selectSet.indexOf("00005095") > "-1"
                      }
                    />
                    <ListItemText primary={t("report.00005095")} />
                  </MenuItem>
                  <MenuItem value="00005097">
                    <Checkbox
                      size="small"
                      disabled={params.id === "00005097"}
                      checked={
                        params.id === "00005097" ||
                        selectSet.indexOf("00005097") > "-1"
                      }
                    />
                    <ListItemText primary={t("report.00005097")} />
                  </MenuItem>
                  <MenuItem value="00005098">
                    <Checkbox
                      size="small"
                      disabled={params.id === "00005098"}
                      checked={
                        params.id === "00005098" ||
                        selectSet.indexOf("00005098") > "-1"
                      }
                    />
                    <ListItemText primary={t("report.00005098")} />
                  </MenuItem>
                  <MenuItem value="00005099">
                    <Checkbox
                      size="small"
                      disabled={params.id === "00005099"}
                      checked={
                        params.id === "00005099" ||
                        selectSet.indexOf("00005099") > "-1"
                      }
                    />
                    <ListItemText primary={t("report.00005099")} />
                  </MenuItem>
                </Select>
              </FormControl>
              <Tooltip title={t("tooltip.staff.report.filter_compare")}>
                <Button sx={{color: 'text.secondary'}} style={{ backgroundColor: 'transparent'}} >
                  <HelpOutlineIcon></HelpOutlineIcon>
                </Button>
              </Tooltip>
            </Box>
            
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
              <FormControl sx={{ textAlign: "left", my: 1 }} fullWidth>
                <TextField
                  id="date"
                  label={t("report.starting_date")}
                  type="date"
                  value={moment(stateStartDate).format("YYYY-MM-DD")}
                  onChange={onChangeStartDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <Tooltip title={t("tooltip.staff.report.filter_date")}>
                <Button sx={{color: 'text.secondary'}} style={{ backgroundColor: 'transparent'}} >
                  <HelpOutlineIcon></HelpOutlineIcon>
                </Button>
              </Tooltip>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
              <FormControl sx={{ textAlign: "left", my: 1 }} fullWidth >
                <TextField
                  id="time"
                  label={t("report.start_hour")}
                  type="time"
                  value={moment(stateStartDate).format("HH:mm")}
                  onChange={onChangeStartTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 600,
                  }}
                  disabled={
                    stateInterval !== "hourly" && stateInterval !== "daily"
                  }
                />
              </FormControl>
              <Tooltip title={t("tooltip.staff.report.filter_time")}>
                  <Button sx={{color: 'text.secondary'}} style={{ backgroundColor: 'transparent'}} >
                    <HelpOutlineIcon></HelpOutlineIcon>
                  </Button>
                </Tooltip>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
              <FormControl sx={{ textAlign: "left", mt: 1 }} fullWidth>
                <InputLabel id="interval">{t("date.interval")}</InputLabel>
                <Select
                  labelId="interval"
                  id="interval"
                  value={stateInterval}
                  label={t("date.interval")}
                  onChange={onChangeInterval}
                >
                  <MenuItem value="hourly">{t("date.hourly")}</MenuItem>
                  <MenuItem value="daily">{t("date.daily")}</MenuItem>
                  <MenuItem value="weekly">{t("date.weekly")}</MenuItem>
                  <MenuItem value="monthly">{t("date.monthly")}</MenuItem>
                  <MenuItem value="yearly">{t("date.yearly")}</MenuItem>
                </Select>
              </FormControl>
              <Tooltip title={t("tooltip.staff.report.filter_interval")}>
                <Button sx={{color: 'text.secondary'}} style={{ backgroundColor: 'transparent'}} >
                  <HelpOutlineIcon></HelpOutlineIcon>
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("form.cancel")}
          </Button>
          <LoadingButton
            component="button"
            type="submit"
            variant="contained"
            color="primary"
            loading={loading}
            onClick={handleSave}
          >
            {t("form.search")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
