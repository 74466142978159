import {
	NavLink as RouterLink,
	matchPath,
	useLocation
} from 'react-router-dom';
import { Button, ListItem, Tooltip } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const NavItem = (props: any) => {
	const { href, icon: Icon, title, tooltip_title, badge: Badge, ...rest } = props
	const location = useLocation();

	const active = href ? !!matchPath({
		path: href,
		end: false
	}, location.pathname) : false;

	return (
		<ListItem
			disableGutters
			sx={{
				display: 'flex',
				py: 0
			}}
			{...rest}
		>
			<Button
				component={RouterLink}
				sx={{
					color: 'text.secondary',
					fontWeight: 'medium',
					justifyContent: 'flex-start',
					letterSpacing: 0,
					py: 1.25,
					textTransform: 'none',
					width: '100%',
					...(active && {
						color: 'primary.main'
					}),
					'& svg': {
						mr: 1
					}
				}}
				to={href}
			>
				{Icon && (
					<Badge>
						<Icon size="20" />
					</Badge>
				)}
				<span>
					{title}
				</span>
			</Button>
			{tooltip_title && (
				<Tooltip title={tooltip_title} arrow placement='right'>
					<Button sx={{ color: 'text.secondary' }}>
						<HelpOutlineIcon></HelpOutlineIcon>
					</Button>
				</Tooltip>
			)}

		</ListItem>
	);
};


export default NavItem;