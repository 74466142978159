import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UniversalCookie from 'universal-cookie';

import {
	Breadcrumbs,
	Divider,
	Grid,
	Link,
	Typography,
} from '@material-ui/core';

import Loader from 'src/components/loader';
import api from 'src/utilities/axios';

import DeleteFormDialog from './components/delete-form-dialog';
import AddFormDialog from './components/add-form-dialog';
import DataTable from './components/data-table';
import ApprovalTable from './components/request-table';

export default function Users(): JSX.Element {
	const { t } = useTranslation();
	const cookie = new UniversalCookie();
	
	const [data, setData] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [render, setRender] = React.useState<boolean>(false);

	const [deleteState, setDeleteState] = React.useState<boolean>(false);
	const [deleteData, setDeleteData] = React.useState<any[]>([]);
	
	const [requestsData, setRequestsData] = React.useState<any[]>([]);

	const updateRender = (): void => setRender(!render);

	const deleteDialog = {
		state: deleteState,
		data: deleteData,

		setState: setDeleteState,
		setData: setDeleteData
	}


	function fetchData(): void {
		setLoading(true);
		api({
			pathname: '/staff/' + cookie.get('workspace') + '/users',
			method: 'GET',
			handleResponse: (response: any): void => {
				setLoading(false);
				setData(response.data.filter(e=> e.is_pending === false));
				setRequestsData(response.data.filter(e=> e.is_pending === true));
			},
			handleError: (error: any): void => {
				setLoading(false);
			}
		})
	}

	React.useEffect(fetchData, [render]);
	
	return (
		<Loader state={loading}>
			<Grid container justifyContent='space-between' spacing={3}>
				<Grid item>
					<Typography variant='h3' sx={{ mb: 1 }}>
						{t('navigation.users')}
					</Typography>
					<Breadcrumbs aria-label='breadcrumb'>
						<Link component={RouterLink} color='inherit' to='/staff'>
							{t('navigation.dashboard')}
						</Link>
						<Typography color='text.primary'>
							{t('navigation.users')}
						</Typography>
					</Breadcrumbs>
				</Grid>
				<Grid item>
					<AddFormDialog updateRender={updateRender} />
				</Grid>
				
			</Grid>

			<Divider sx={{ my: 3 }} />

			<DeleteFormDialog updateRender={updateRender} deleteDialog={deleteDialog} />
			<ApprovalTable data={requestsData} updateRender={updateRender} deleteDialog={deleteDialog} />
			<div>
				{requestsData.length ? (
					<Divider  sx={{ my: 3 }}/>
				) : (
					<Divider  sx={{ my: 0 }}/>
				)}
			</div>
			<DataTable data={data} updateRender={updateRender} deleteDialog={deleteDialog} />
		</Loader>
	)
}