import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Stats from './components/stats';

function ResQBand(props: any) {
	const { data } = props;

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Typography variant='h6' display='inline'>
					ResQ Band
				</Typography>
			</Grid>
			{data.map((item: any, iteration: number) => (
				<Grid key={iteration} item xs={12} sm={4} md={4} lg={3} xl={2}>
					<Stats {...item} />
				</Grid>
			))}
		</Grid>
	)
}

export default ResQBand;