import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import Tooltip from '@material-ui/core/Tooltip';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Bookmark from '@material-ui/icons/Bookmark';
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import { stableSort, getComparator } from 'src/utilities/data-table';


function DataTable(props: any): JSX.Element{
	const { data = [] } = props;
	const { t } = useTranslation();
	const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
	const [orderBy, setOrderBy] = React.useState('calories')
	
	  
	const headCells: any = [
		{ id: 'name', align: 'left', label: t('table.name') },
		{ id: 'workspace_name', align: 'left', label: t('form.entity_id')+" " },
		{ id: 'is_public', align: 'center', label: t('table.status') },
		{ id: 'details', align: 'right' , label: t('table.actions')}
	];	  


	
	function handleRequestSort(event: any, property: string) {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	
	function renderArrayData(): JSX.Element[] {
		
		return stableSort(data, getComparator(order, orderBy)).map((item: any, index: number): JSX.Element => {

				return (
						
					<TableRow key={index}>
						<TableCell 
						component='th' 
						scope='row'  
						>{item.name}</TableCell>							
						<TableCell align='left'>{item.workspace_name}</TableCell>						
						<TableCell align='center'>{item.is_public ?  <Chip label={t('table.public')} color="info" /> :  <Chip label={t('table.private')} />}</TableCell>
						<TableCell align='right'>
							<Tooltip title={""}>
								<IconButton
									
									>
										<Bookmark />
									
								</IconButton>
							</Tooltip>
							<Tooltip title={t('table.detailed')}>
								<IconButton
									component={RouterLink}
									to={ item.workspace_name}
									
									>
									<VisibilitySharpIcon />
								</IconButton>
							</Tooltip>
							
									
						</TableCell>
					</TableRow>
				)
			})
		
	}
	
	return (
		<Card sx={{ boxShadow: 6 }}>
			<Grid container justifyContent='space-between'>
				<Grid item sx={{ px: 2, py: 3 }}>
					<Typography variant='h6' display='inline'>
						{t('navigation.entities')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Table>
						<TableHead>
							<TableRow>
								<>
							{headCells.map((headCell: any) => (
										<TableCell
											key={headCell.id}
											align={headCell.align}
											sortDirection={orderBy === headCell.id ? order : false}
										>
											{headCell.id==='actions' ? 
											<Box component="span" sx={{ p: 1}}>{headCell.label}</Box> :
											 <TableSortLabel
												active={orderBy === headCell.id}
												direction={orderBy === headCell.id ? order : 'asc'}
												onClick={(event) => handleRequestSort(event, headCell.id)}
											>
												<Box pr={1.5}>{headCell.label}</Box>
												{headCell.tooltip_help && (
													<Tooltip title={headCell.tooltip_help} arrow placement='top'>
														<Button sx={{color: 'text.secondary'}}>
														</Button>
													</Tooltip>
												)}</TableSortLabel>}
												</TableCell>
											))}</>
							
							</TableRow>
						</TableHead>
						<TableBody>
							
							{renderArrayData()}						
									
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</Card>
	)
}

export default DataTable;