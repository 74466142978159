import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/ja';

import { ThemeProvider } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/styles';

import theme from './theme';
import Routes from './routes';
import i18n from './i18n';

import GlobalStyles from 'src/components/global-styles';
import { store } from 'src/redux/store';

export default function App() {
	moment.locale('ja');

	return (
		<BrowserRouter>
			<Helmet
				titleTemplate='%s | ResQ'
				defaultTitle='ResQ Platform'
			/>
			<StylesProvider injectFirst>
				<GlobalStyles />
				<Provider store={store}>
					<ThemeProvider theme={theme()}>
						<I18nextProvider i18n={i18n}>
							<Routes/>
						</I18nextProvider>
					</ThemeProvider>
				</Provider>
			</StylesProvider>
		</BrowserRouter>
	);
}