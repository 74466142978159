import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import LoadingButton from '@material-ui/lab/LoadingButton';

import api from 'src/utilities/axios';

interface IAddFormDialogProps {
	updateRender: any,
	editDialog: any,
}

export default function EditFormDialog(props: IAddFormDialogProps): JSX.Element {
	const { updateRender, editDialog } = props

	const [loading, setLoading] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<JSX.Element>(null);

	const { t } = useTranslation();

	function handleEdit(event: React.SyntheticEvent): void {
		event.preventDefault();
		event.persist();

		setLoading(true);
		setMessage(null);

		const target = event.target as typeof event.target & {
			questionText: { value: string },
		}

		api({
			pathname: '/staff/questions/' + editDialog.data.id,
			method: 'PUT',
			data: {
				text: target.questionText.value,
			},
			handleResponse: (): void => {
				setLoading(false);
				updateRender();
				handleClose();
			},
			handleError: (): void => {
				setLoading(false);
			}
		})
	}

	function handleClose(): void {
		editDialog.setState(false);
	};

	return (
		<React.Fragment>
			<Dialog open={editDialog.state} onClose={handleClose} maxWidth='sm' fullWidth>
				<form onSubmit={handleEdit}>
					<DialogTitle>Edit Question</DialogTitle>
					{message}
					<DialogContent>
						<TextField
							label='Question Text'
							id='questionText'
							name='questionText'
							variant='outlined'
							defaultValue={editDialog.data.text && editDialog.data.text}
							fullWidth
							required
							sx={{ mb: 2 }}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={handleClose}
							color='primary'
						>
							{t('form.cancel')}
						</Button>
						<LoadingButton
							component='button'
							type='submit'
							variant='contained'
							color='primary'
							loading={loading}
						>
							{t('form.edit')}
						</LoadingButton>
					</DialogActions>
				</form>
			</Dialog>
		</React.Fragment>
	)
}