import { useState } from 'react';
import UniversalCookie from 'universal-cookie';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';

import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';

import UserMenu from './components/user-menu';
import LanguageMenu from 'src/components/language-menu';

function DashboardNavbar(props: any) {
	const { onMobileNavOpen, ...rest } = props;
	const cookie = new UniversalCookie();
	const [notifications] = useState([]);

	return (
		<AppBar elevation={0} {...rest}>
			<Toolbar sx={{ px: 1 }}>
				<Box sx={{ display: { xs: 'inline', lg: 'none' }, mr: 1 }}>
					<IconButton
						color='inherit'
						onClick={onMobileNavOpen}
					>
						<MenuIcon />
					</IconButton>
				</Box>

				<Typography variant='h5'>
					{cookie.get('workspace_name')}
				</Typography>

				<Box sx={{ flexGrow: 1 }} />

				<Box sx={{ display: { xs: 'inline', lg: 'inline' } }}>
					<IconButton color='inherit'>
						<Badge
							badgeContent={notifications.length}
							color='primary'
							variant='dot'
						>
							<NotificationsIcon />
						</Badge>
					</IconButton>
					<LanguageMenu iconOnly />
					<UserMenu />
				</Box>
			</Toolbar>
		</AppBar>
	);
};

export default DashboardNavbar;