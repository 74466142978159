import React from 'react';
import { useTranslation } from 'react-i18next';
import RCSlider from 'rc-slider';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import 'rc-slider/assets/index.css';

const { createSliderWithTooltip } = RCSlider;
const Range = createSliderWithTooltip(RCSlider.Range);

interface ISlider {
	data: any,
	setSliderData: any,
}

function Slider(props: ISlider): JSX.Element {
	const { data, setSliderData } = props;
	const { t } = useTranslation();
	const [minValues, setMinValues] = React.useState<Array<number>>([0,0,0,0]);
	const [maxValues, setMaxValues] = React.useState<Array<number>>([0,0,0,0]);
	const [meanValues, setMeanValues] = React.useState<Array<number>>([0,0,0,0]);
	const [hasFetchedFromProps, setHasFetchedFromProps] = React.useState<boolean>(false);

	const trackStyle = [
		{ backgroundColor: '#ffb74d', height: 10 }, 
		{ backgroundColor: '#81c784', height: 10 }, 
		{ backgroundColor: '#ffb74d', height: 10 }
	];

	const handleStyle = [
		{ backgroundColor: '#ffb74d', height: 24, width: 24, marginTop: -7, border: 'none', boxShadow: '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.40)' }, 
		{ backgroundColor: '#81c784', height: 24, width: 24, marginTop: -7, border: 'none', boxShadow: '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.40)' },
		{ backgroundColor: '#81c784', height: 24, width: 24, marginTop: -7, border: 'none', boxShadow: '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.40)' },
		{ backgroundColor: '#ffb74d', height: 24, width: 24, marginTop: -7, border: 'none', boxShadow: '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.40)' }
	];
	
	const railStyle = {
		backgroundColor: '#e57373', height: 10
	}

	function handleMaxSliderChange(value: any): void {
		setMaxValues(value);
	};

	function handleMinSliderChange(value: any): void {
		setMinValues(value);
	};

	function handleMeanSliderChange(value: any): void {
		setMeanValues(value);
	};

	function handleSliderSave(): void {
		setSliderData({
			maxValue: maxValues,
			minValue: minValues,
			meanValue: meanValues,
		});
	}

	function handleSliderValues(): void {
		if (data.report_info && !hasFetchedFromProps) {
			setHasFetchedFromProps(true);
			let reportInfo = data.report_info.replaceAll(/'/g, '"');
			reportInfo = JSON.parse(reportInfo);

			if (reportInfo.maxValue)
				setMaxValues(reportInfo.maxValue);
			else
				setMaxValues([data.min_value, Math.floor(data.max_value*0.7), Math.floor(data.max_value*0.8), data.max_value])
			if (reportInfo.minValue)
				setMinValues(reportInfo.minValue);
			else
				setMinValues([data.min_value, Math.floor(data.max_value*0.5), Math.floor(data.max_value*0.7), data.max_value])
			if (reportInfo.meanValue)
				setMeanValues(reportInfo.meanValue);
			else
				setMeanValues([data.min_value, Math.floor(data.max_value*0.4), Math.floor(data.max_value*0.5), data.max_value])
		}

	}

	React.useEffect(handleSliderValues, [props]);

	return (
		<Grid container spacing={3} sx={{ px: 2, py: 3 }}>
			<Grid item xs={12} onMouseUp={handleSliderSave}>
				<Typography id='max_score_slider' variant='h6'>{t('report.slider_max')}</Typography>
				<Range
					count={3}
					trackStyle={trackStyle}
					handleStyle={handleStyle}
					railStyle={railStyle}
					tipProps={{ placement: 'top', visible: true }}
					step={0.1}
					value={maxValues}
					max={data.max_value}
					min={data.min_value}
					onChange={handleMaxSliderChange}
				/>
			</Grid>
			<Grid item xs={12} onMouseUp={handleSliderSave}>
				<Typography id='min_score_slider' variant='h6'>{t('report.slider_min')}</Typography>
				<Range
					count={3}
					trackStyle={trackStyle}
					handleStyle={handleStyle}
					railStyle={railStyle}
					tipProps={{ placement: 'top', visible: true }}
					step={0.1}
					value={minValues}
					max={data.max_value}
					min={data.min_value}
					onChange={handleMinSliderChange}
				/>
			</Grid>
			<Grid item xs={12} onMouseUp={handleSliderSave}>
				<Typography id='mean_score_slider' variant='h6'>{t('report.slider_mean')}</Typography>
				<Range
					count={3}
					trackStyle={trackStyle}
					handleStyle={handleStyle}
					railStyle={railStyle}
					tipProps={{ placement: 'top', visible: true }}
					step={0.1}
					value={meanValues}
					max={data.max_value}
					min={data.min_value}
					onChange={handleMeanSliderChange}
				/>
			</Grid>
		</Grid>
	)
}

export default Slider;