import { Outlet } from 'react-router-dom';
import Box from '@material-ui/core/Box';

const MobileLayout = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
			}}
		>
			<Outlet/>
		</Box>
	)
}

export default MobileLayout;