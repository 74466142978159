import React, { useState, useEffect, FormEvent } from 'react'
import { Link as RouterLink } from "react-router-dom"
import UniversalCookie from 'universal-cookie';
import { useTranslation } from 'react-i18next';

import Loader from 'src/components/loader';
import api, { apiReturnRes } from 'src/utilities/axios';
import { toSnakeCase } from 'src/utilities/text';

import { TextField, FormStack, SubmitButton } from '../components/styled-form-components';
import { Grid, Typography, Breadcrumbs, Link, Button, Divider, MenuItem } from "@material-ui/core"


export default function Synchronization() {
    const cookie = new UniversalCookie();
    const { t } = useTranslation();

    // UI states
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // notification settings states
    const [notificationTypes, setNotificationTypes] = useState([]);
    const [selectedOptionId, setSelectedOptionId] = useState<string>("");

    // sync length states
    const [syncLengthOptions, setSyncLengthOptions] = useState([]);
    const [selectedSyncLengthOptionId, setSelectedSyncLengthOptionId] = useState<string>("");

    // lock state
    const [isLocked, setIsLocked] = useState<boolean>(true);

    const fetchData = async () => {
        const { data: syncData } = await apiReturnRes({
            pathname: '/staff/' + cookie.get('workspace') + '/notification_sync_settings',
            method: 'GET',
        });

        setSyncLengthOptions(syncData.notification_sync_length_option_list);
        setSelectedSyncLengthOptionId(syncData.notification_sync_length_option.id);

        const { data: emailData } = await apiReturnRes({
            pathname: '/staff/' + cookie.get('workspace') + '/notification_settings/2',
            method: 'GET',
        })

        setNotificationTypes(emailData.notification_setting_type_list.sort((a, b) => a.order - b.order));
        setSelectedOptionId(emailData.notification_setting_type.id);

        switch (emailData.entity?.notification_setting_status || syncData.entity?.notification_setting_status) {
            case "unlocked":
                setIsLocked(false)
                break;
            case "locked":
                setIsLocked(true)
                break;
            default:
                break;
        }

        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleOptionSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOptionId(e.target.value);
    }

    const handleSyncOptionSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedSyncLengthOptionId(e.target.value);
    }

    const handleTypeSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (isLocked) return;

        setIsLoading(true);

        api({
            pathname: '/staff/' + cookie.get('workspace') + '/notification_settings/2',
            method: 'POST',
            data: {
                "notification_setting_type_id": +selectedOptionId,
            },
            handleResponse: (response: any): void => {
                setNotificationTypes(response.data.notification_setting_type_list.sort((a, b) => a.order - b.order));
                setSelectedOptionId(response.data.notification_setting_type.id);
                setIsLoading(false);
            },
            handleError: (error: any): void => setIsLoading(false),
        })
    }

    const handleSyncLengthSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (isLocked) return;

        setIsLoading(true);

        api({
            pathname: '/staff/' + cookie.get('workspace') + '/notification_sync_settings',
            method: 'POST',
            data: {
                "notification_sync_length_option_id": +selectedSyncLengthOptionId,
            },
            handleResponse: (response: any): void => {
                setSyncLengthOptions(response.data.notification_sync_length_option_list);
                setSelectedSyncLengthOptionId(response.data.notification_sync_length_option.id);
                setIsLoading(false);
            },
            handleError: (error: any): void => setIsLoading(false)
        })
    }

    return (
        <Loader state={isLoading}>
            <Grid container justifyContent='space-between' spacing={3}>
                <Grid item>
                    <Typography variant='h3' sx={{ mb: 1 }}>
                        {t('notification_settings.synchronization')}
                    </Typography>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link component={RouterLink} color='inherit' to='/staff'>
                            {t('navigation.dashboard')}
                        </Link>
                        <Link component={RouterLink} color="inherit" to="/staff/notification-settings">
                            {t('navigation.notification_settings')}
                        </Link>
                        <Typography color='text.primary'>
                            {t("notification_settings.synchronization")}
                        </Typography>
                    </Breadcrumbs>
                </Grid>

            </Grid>

            <Divider sx={{ my: 3 }} />

            {/* Notification Email Settings */}
            <FormStack
                onSubmit={handleTypeSubmit}
                title={t("notification_settings.notification_email_settings")}
                // tooltipText={t("insert_here.tooltip")}
                referenceData={[
                    <>
                        <Typography>{t("notification_settings.types.description.1_time")}</Typography>
                        <Typography>{t("notification_settings.types.description.2_times")}</Typography>
                        <Typography>{t("notification_settings.types.description.3_times")}</Typography>
                        <Typography>{t("notification_settings.types.description.email_notice")}</Typography>
                    </>
                ]}>

                <Typography sx={{ pt: .8, width: "100%", maxWidth: "max-content", fontWeight: "bold", fontSize: "16px" }}>
                    {t("notification_settings.everyday")}
                </Typography>

                <TextField
                    id='notification-settings'
                    disabled={isLocked}
                    select
                    value={selectedOptionId}
                    onChange={handleOptionSelect}
                    label={t('notification_settings.number_of_notifications')}
                    helperText={t("notification_settings.receive_notification_email")}
                >
                    {notificationTypes.map(
                        (type) =>
                            <MenuItem key={type.id} value={type.id}>{t(`notification_settings.types.name.${toSnakeCase(type.name)}`)}</MenuItem>
                    )}
                </TextField>

                <SubmitButton
                    isLocked={isLocked}
                >
                    {t("form.set")}
                </SubmitButton>
            </FormStack>

            {/* SYNC LENGTH */}
            <FormStack
                onSubmit={handleSyncLengthSubmit}
                title={t("notification_settings.notification_conditions")}
                // tooltipText={t("insert_here.tooltip")}
            >
                <TextField
                    id='notification-settings'
                    disabled={isLocked}
                    select
                    value={selectedSyncLengthOptionId}
                    onChange={handleSyncOptionSelect}
                    label={t('notification_settings.duration')}
                    // helperText={t("insert_here.helper_text")}
                >
                    {syncLengthOptions.map(
                        (option) =>
                            <MenuItem key={option.id} value={option.id}>{option.duration_by_hour} {t("notification_settings.out_of_sync_duration")}</MenuItem>
                    )}
                </TextField>

                <SubmitButton
                    isLocked={isLocked}
                >
                    {t("form.set")}
                </SubmitButton>
            </FormStack>
        </Loader >
    )
}
