import { useTranslation } from "react-i18next";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";

export interface IPublicProps {
  values: any;
  values_dbp: any;
  id: string;
  additional?: boolean;
}

export type Props = IPublicProps;

export function StatsCard(props: Props) {
  const { values, values_dbp=[], id, additional } = props;
  const { t } = useTranslation();

  function renderValueLabel() {
    let maximum: any = Number.NEGATIVE_INFINITY;
    let minimum: any = Number.POSITIVE_INFINITY;

    values &&
      values.map((item: any) => {
        if (item[1] === null) return item;
        if (parseInt(item[1]) > maximum) maximum = parseInt(item[1]);

        if (id === "00005097") {
          if (parseInt(item[1]) < minimum) minimum = parseInt(item[1]);
        } else {
          if (item[2] === null) return item;
          if (parseInt(item[2]) < minimum) minimum = parseInt(item[2]);
        }
      });

    if (maximum === Number.NEGATIVE_INFINITY) maximum = "0";
    if (minimum === Number.POSITIVE_INFINITY) minimum = "0";

    return minimum + " - " + maximum;
  }

  function renderDbpValueLabel() {
    let maximum_dbp: any = Number.NEGATIVE_INFINITY;
    let minimum_dbp: any = Number.POSITIVE_INFINITY;
    values_dbp &&
    values_dbp.map((item: any) => {
        if (item[1] === null) return item;
        if (parseInt(item[1]) > maximum_dbp) maximum_dbp = parseInt(item[1]);

        if (id === "00005097") {
          if (parseInt(item[1]) < minimum_dbp) minimum_dbp = parseInt(item[1]);
        } else {
          if (item[2] === null) return item;
          if (parseInt(item[2]) < minimum_dbp) minimum_dbp = parseInt(item[2]);
        }
      });
    if (maximum_dbp === Number.NEGATIVE_INFINITY) maximum_dbp = "0";
    if (minimum_dbp === Number.POSITIVE_INFINITY) minimum_dbp = "0";

    return minimum_dbp + " - " + maximum_dbp;
  }

  function renderDateLabel() {
    if (!values) return "0 - 0";

    let firstValue = values[0];
    let lastValue = values[values.length - 1];

    if (!firstValue) return "0 - 0";
    if (!lastValue) return "0 - 0";

    return lastValue[0].slice(0, 10) + " - " + firstValue[0].slice(0, 10);
  }

  return (
    <Grid container alignItems="center" sx={{ height: "360px" }}>
      <Grid item sx={{ textAlign: "center", pt: 6 }} xs={12}>
        <Typography variant="h4">{t("report." + id)}</Typography>
      </Grid>
      <Grid item sx={{ textAlign: "center" }} xs={12}>
        <img
          src={"/static/img/dashboard/" + id + ".svg"}
          alt={t("report." + id)}
          width="92px"
        />
      </Grid>
      <Grid item sx={{ textAlign: "center", pb: 6 }} xs={12}>
        {id==="00005095"?(<div>
        <Typography variant="h3">{renderValueLabel()}</Typography>
        <Typography variant="h3">{renderDbpValueLabel()}</Typography></div>):(
          <Typography variant="h3">{renderValueLabel()}</Typography>
        )}
        <Typography variant="subtitle2" color="text.secondary">
          {renderDateLabel()}
        </Typography>
      </Grid>
    </Grid>
  );
}
