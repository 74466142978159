import React from 'react';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import LoadingButton from '@material-ui/lab/LoadingButton';

import Loader from 'src/components/loader';
import api from 'src/utilities/axios';

function ImageFormDialog(props: any): JSX.Element {
	const { data, updateRender } = props;

	const [open, setOpen] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<JSX.Element | null>(null);

	const { t } = useTranslation();

	function handleFile(acceptedFiles: any) {
		setLoading(true);
		setMessage(null);

		let formData = new FormData();
		formData.append('image', acceptedFiles[0]);

		api({
			pathname: '/user/data/' + data.id + '/images',
			method: 'POST',
			data: formData,
			handleResponse: (): void => {
				setLoading(false);
				handleClose();
				updateRender();
			},
			handleError: (): void => {
				setLoading(false);
			}
		})
	}

	function handleClose(): void {
		setOpen(false);
	}

	function handleOpen(): void {
		setOpen(true);
	}

	return (
		<React.Fragment>
			<Button variant='contained' color='primary' onClick={handleOpen}>
				{t('form.add_image')}
			</Button>
			<Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='sm'>
				<Loader state={loading}>
					<form>
						<DialogTitle>{t('form.add_image')}</DialogTitle>

						{message}

						<DialogContent>
							<Dropzone onDrop={handleFile}>
								{({getRootProps, getInputProps}) => (
									<Paper elevation={6} sx={{ boxShadow: 6, cursor: 'pointer', px: 3, py: 4 }}>
										<div {...getRootProps()}>
											<input {...getInputProps()} />
											<Typography variant='body1' sx={{ textAlign: 'center' }}>
												Drag 'n' drop a file here, or click to select a file
											</Typography>
										</div>
									</Paper>
								)}
							</Dropzone>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={handleClose}
								color='primary'
							>
								{t('form.cancel')}
							</Button>
							<LoadingButton
								component='button'
								type='submit'
								variant='contained'
								color='primary'
								loading={loading}
							>
								{t('form.add_image')}
							</LoadingButton>
						</DialogActions>
					</form>
				</Loader>
			</Dialog>
		</React.Fragment>
	)
}

export default ImageFormDialog;