import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

const MainLayoutRoot = experimentalStyled('div')(
	({ theme }) => ({
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		height: '100%',
		overflow: 'hidden',
		width: '100%'
	})
);

const MainLayoutWrapper = experimentalStyled('div')({
	display: 'flex',
	flex: '1 1 auto',
	overflow: 'hidden',
	paddingTop: 64
});

const MainLayoutContainer = experimentalStyled('div')({
	display: 'flex',
	flex: '1 1 auto',
	overflow: 'hidden'
});

const MainLayoutContent = experimentalStyled('div')({
	flex: '1 1 auto',
	height: '100%',
	overflow: 'auto'
});

const MainLayout = () => (
	<MainLayoutRoot>
		<MainLayoutWrapper>
			<MainLayoutContainer>
				<MainLayoutContent>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
							justifyContent: 'center',
						}}
					>
						<Container maxWidth='sm' sx={{ boxShadow: 24, py: 4, marginTop: '-100px', borderRadius: '4px', }}>
							<Outlet />
						</Container>
					</Box>
				</MainLayoutContent>
			</MainLayoutContainer>
		</MainLayoutWrapper>
	</MainLayoutRoot>
);

export default MainLayout;