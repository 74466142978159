import React from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Loader from 'src/components/loader';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import DownloadIcon from '@material-ui/icons/Download';
import api from 'src/utilities/axios';

import { stableSort, getComparator } from 'src/utilities/data-table';

export default function DataTable() {
	const { t } = useTranslation();
	const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
	const [orderBy, setOrderBy] = React.useState('calories');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [startDate, setStartDate] = React.useState<any>();
	const [searched, setSearched] = React.useState<string>('');
	const [entities, setEntities] = React.useState<any[]>([]);
	const [endDate, setEndDate] = React.useState<any>();
	const [headers, setHeaders] = React.useState<any>([]);
	const [render, setRender] = React.useState<boolean>(false);
	const [tableData, setTableData] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);

	const updateRender = (): void => setRender(!render);

	const [csvData, setCsvData] = React.useState<any>([]);
	const [filename, setFilename] = React.useState<string>();
	const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

	const csvLink: any = React.useRef();

	const [dataType, setDataType] = React.useState('Vital');

	const handleDataTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDataType((event.target as HTMLInputElement).value);
	};

	const [dbType, setDbType] = React.useState('Preprocessed');

	const handleDbTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDbType((event.target as HTMLInputElement).value);
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearched(event.target.value);
	  };

	const headCells: any = [
		{ id: 'name', align: 'left', disablePadding: false, label: t('table.name') },
		{ id: 'email', align: 'left', disablePadding: false, label: t('table.email') },
		{ id: 'actions', align: 'right', disablePadding: false, label: t('report.csv_download_title')},
	];

	const [entity, setEntity] = React.useState('all_entities');

	const handleEntityChange = (event: SelectChangeEvent) => {
		setEntity(event.target.value);
	};
	
	function fetchData(): void {
		setLoading(true);
		api({
			pathname: '/admin/users/' + entity,
			method: 'GET',
			handleResponse: (response: any): void => {
				setLoading(false);
				setTableData(response.data);
			},
			handleError: (error: any): void => {
				setLoading(false);
			}
		})
	}

	React.useEffect(fetchData, [entity]);
	

	function handleRequestSort(event: any, property: string) {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
		setPage(newPage);
	};

	function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	

	function fetchEntities(): void {		
		api({
			pathname: '/entities',
			method: 'GET',
			handleResponse: (response: any) => {
				setEntities(response.data);
				
			},
			handleError: (error: any) => {
				console.log('error fetching entities');
			}
			
		})
	}

	React.useEffect(fetchEntities, []);

	function handleSubmit(uid: any, username: any) {
		let startUnixDate = Date.parse(startDate);
		let endUnixDate = Date.parse(endDate);

		let pathname = '/admin/users/data/' + uid + '/download'
		pathname += '?start_time=' + startUnixDate / 1000;
		pathname += '&end_time=' + endUnixDate / 1000;
		pathname += '&data_type=' + dataType;
		pathname += '&db_type=' + dbType;
		pathname += '&timezone=' + timeZone;
		
		api({
			pathname: pathname,
			method: 'GET',
			handleResponse: (response: any): void => {
				setCsvData(response.data.values)				
				setHeaders(response.data.headers)
				setFilename(username +'_'+dataType+'_'+dbType+ '_data.csv')
				csvLink.current.link.click()
			},
			handleError: (error: any): void => {
				console.log(error);
			}
		})
	}

	return (
		<Loader state={loading}>
			<CSVLink
													filename={filename}
													data={csvData}
													headers={headers}
													ref={csvLink}
												>
												</CSVLink>
		<Card sx={{ mb: 3, boxShadow: 3 }}>
        <Grid container justifyContent="space-between" alignItems='center'>
          <Grid item xs={12} md={12}>
		<Grid container justifyContent='space-between' alignItems='baseline' spacing={3} sx={{ px: 2, py: 3 }}>
			<Grid item xs={12}>
				<Typography variant='h6' display='inline-block'>
					CSV download settings
				</Typography>
			</Grid>
			<Grid item xs={12}>
					<form onSubmit={undefined}>
					<Grid container justifyContent='space-evenly' alignItems='center' direction={'row'} spacing={3} sx={{ px: 15, py: 2 }}>
						<TextField
							id="datetime-local"
							label={t('report.starting_date')}
							type="datetime-local"
							onChange={(event) => setStartDate(event.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
							sx={{ mr: 1 }}
							required
						/>
						<TextField
							id="datetime-local"
							label={t('report.end_date')}
							type="datetime-local"
							onChange={(event) => setEndDate(event.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
							sx={{ mx: 1 }}
							required
						/>
						<FormControl>
						<FormLabel id="demo-controlled-radio-buttons-group">Data type</FormLabel>
						<RadioGroup
							aria-labelledby="demo-controlled-radio-buttons-group"
							name="controlled-radio-buttons-group"
							value={dataType}
							onChange={handleDataTypeChange}
						>
							<FormControlLabel value="Vital" control={<Radio />} label="Vital" />
							<FormControlLabel value="Sleep" control={<Radio />} label="Sleep" />
						</RadioGroup>
						</FormControl>

						<FormControl>
						<FormLabel id="demo-controlled-radio-buttons-group">Database type</FormLabel>
						<RadioGroup
							aria-labelledby="demo-controlled-radio-buttons-group"
							name="controlled-radio-buttons-group"
							value={dbType}
							onChange={handleDbTypeChange}
						>
							<FormControlLabel value="Preprocessed" control={<Radio />} label="Preprocessed" />
							<FormControlLabel value="Raw" control={<Radio />} label="Raw" />
						</RadioGroup>
						</FormControl>
					</Grid>
						
					</form>
				</Grid>
			</Grid>
			</Grid>
			</Grid>
			</Card>
			
		<Card sx={{ boxShadow: 6 }}>
			<Grid container justifyContent='flex-start'>
				<Grid item sx={{ px: 2, py: 3 }}>
					<Typography variant='h6' display='inline'>
						{t('table.users_table')}
					</Typography>
				</Grid>
				<Grid item sx={{ px: 2, py: 3 }}>
					<Input
					id="input-with-icon-adornment"
					onChange={handleSearchChange}
					startAdornment={
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					}
					/>
				</Grid>
				<Grid item sx={{ px: 2, py: 3 }}>
				<FormControl fullWidth variant="standard" sx={{ minWidth: 120 }}>
					<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={entity}
					label="Entity"
					onChange={handleEntityChange}
					>
						<MenuItem value={'all_entities'}>All entities</MenuItem>
						{entities.map((e) => (
							<MenuItem
							key={e.workspace_name}
							value={e.workspace_name}
							>
							{e.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				</Grid>
				</Grid>
				<Grid container justifyContent='space-between'>
				<Grid item xs={12}>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									{headCells.map((headCell: any) => (
										<TableCell
											key={headCell.id}
											align={headCell.align}
											sortDirection={orderBy === headCell.id ? order : false}
										>
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={orderBy === headCell.id ? order : 'asc'}
												onClick={(event) => handleRequestSort(event, headCell.id)}
											>
												<Box pr={1.5}>{headCell.label}</Box>
												
												
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{stableSort(tableData.filter((item) => item.name.toLowerCase().includes(searched.toLowerCase())||item.email.toLowerCase().includes(searched.toLowerCase())), getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => (
										<TableRow hover role='checkbox' tabIndex={-1} key={index + 1}>
											<TableCell align='left'>{row.name}</TableCell>
											<TableCell align='left'>{row.email}</TableCell>
											<TableCell align='right'>
												<IconButton	onClick={()=>handleSubmit(row.uid, row.name)}>
													<DownloadIcon />
												</IconButton>
												
											</TableCell>
										</TableRow>
									)
								)}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										count={tableData.filter((item) => item.name.toLowerCase().includes(searched.toLowerCase())||item.email.toLowerCase().includes(searched.toLowerCase())).length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</TableRow>
							</TableFooter>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</Card>
		</Loader>
	)
}