import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { StaffReportStateType } from 'src/redux/staff-report/reducers'

export interface IReduxStateProps {
    reduxState: {
        staffReport: StaffReportStateType
    }
}

export interface IReduxDispatchProps {
    setInterval: (_: any) => void
}

export type Props = IReduxDispatchProps & IReduxStateProps

export function HeaderComponent(props: Props): JSX.Element {
	const { t } = useTranslation();
	const { interval } = props.reduxState.staffReport;

	function handleIntervalChange(event: any): void{
		props.setInterval(event.target.value)
	};

	return (
		<Grid container justifyContent='space-between' spacing={1} sx={{ px: 2, py: 3 }}>
			<Grid item xs={6}>
				<Typography variant='h6' display='inline-block'>
					{t('table.users_table')}
				</Typography>
			</Grid>
			<Grid item xs={6} sx={{ textAlign: 'right' }}>
				<FormControl size='small' sx={{ textAlign: 'left', width: '100px', ml: 2 }}>
					<InputLabel id='interval-label'>{t('date.interval')}</InputLabel>
					<Select
						label={t('date.interval')}
						id='interval'
						value={interval}
						labelId='interval-label'
						onChange={handleIntervalChange}
					>
						<MenuItem value='daily'>{t('date.daily')}</MenuItem>
						<MenuItem value='weekly'>{t('date.weekly')}</MenuItem>
						<MenuItem value='monthly'>{t('date.monthly')}</MenuItem>
						<MenuItem value='yearly'>{t('date.yearly')}</MenuItem>
					</Select>
				</FormControl>
			</Grid>
		</Grid>
	)
}