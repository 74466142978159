import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import LanguageSharpIcon from '@material-ui/icons/LanguageSharp';

export default function LanguageMenu(props: any): JSX.Element {
	const { iconOnly } = props;
	const { t, i18n } = useTranslation();
	const [anchorMenu, setAnchorMenu] = React.useState<any>(null);

	function toggleMenu(event: React.SyntheticEvent): void {
		setAnchorMenu(event.currentTarget);
	};

	function closeMenu(): void {
		setAnchorMenu(null);
	};

	return (
		<Box sx={{ display: 'inline' }}>
			{
				iconOnly
					? (
						<IconButton
							aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
							aria-haspopup='true'
							onClick={toggleMenu}
							color='inherit'
						>
							<LanguageSharpIcon />
						</IconButton>
					)
					: (
						<Button
							aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
							aria-haspopup='true'
							onClick={toggleMenu}
							variant='outlined'
							color='primary'
						>
							<LanguageSharpIcon />
							&nbsp;
							{t('common.language')}
						</Button>
					)
			}
			<Menu
				id='menu-appbar'
				anchorEl={anchorMenu}
				open={Boolean(anchorMenu)}
				onClose={closeMenu}
			>
				<MenuItem onClick={() => {closeMenu(); i18n.changeLanguage('jp')}}>
					日本語
				</MenuItem>
				<MenuItem onClick={() => {closeMenu(); i18n.changeLanguage('en')}}>
					English
				</MenuItem>
			</Menu>
		</Box>
	)
}