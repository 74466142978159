import { connect } from "react-redux";
import { IReduxState } from "src/redux/store";
import { BarChartComponent } from "./component";

function mapStateToProps(state: IReduxState) {
  return {
    reduxState: {
      staffReport: state.staffReport,
    },
  };
}

export const BarChart = connect(mapStateToProps)(BarChartComponent);
