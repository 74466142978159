import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import api from 'src/utilities/axios';
import Loader from 'src/components/loader';

import AddFormDialog from './components/add-form-dialog';
import DataTable from './components/data-table';


function Manage(): JSX.Element {
	const { t } = useTranslation();

	const [data, setData] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [render, setRender] = React.useState<boolean>(false);

	const updateRender = (): void => setRender(!render);

	function fetchData(): void {
		setLoading(true);
		
			api({
			pathname: '/entities',
			method: 'GET',
			handleResponse: (response: any) => {
				setData(response.data);
				setLoading(false);
				
			},
			handleError: (error: any) => {
				setLoading(false);
			}
			
		})
		

	}

	React.useEffect(fetchData, [render]);

	return (
		<Loader state={loading}>
			<Grid container justifyContent='space-between' spacing={3}>
				<Grid item>
					<Typography variant='h3' sx={{ mb: 1 }}>
						{t('navigation.entities')}
					</Typography>
					<Breadcrumbs aria-label='breadcrumb'>
						<Link component={RouterLink} color='inherit' to='/admin'>
							{t('navigation.dashboard')}
						</Link>
						<Typography color='text.primary'>
							{t('navigation.entities')}
						</Typography>
					</Breadcrumbs>
				</Grid>
				<Grid item>
					<AddFormDialog updateRender={updateRender} />
				</Grid>
			</Grid>

			<Divider sx={{ my: 3 }} />

			<DataTable data={data} updateRender={updateRender} />
		</Loader>
	)
}

export default Manage;