import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import Tooltip from '@material-ui/core/Tooltip';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import AddSharpIcon from '@material-ui/icons/AddSharp';

import api from 'src/utilities/axios';

function DataTable(props: any): JSX.Element{
	const { data = [], updateRender } = props;
	const { t } = useTranslation();

	const [loading, setLoading] = React.useState<Array<boolean>>(new Array(data ? data.length : 0))

	function applyRequest(_index: number, changeLoading: Function): void {
		changeLoading(true)

		api({
			pathname: '/user/entities/' + props.data[_index].workspace_name,
			method: 'POST',
			handleResponse: (): void => {
				props.data[_index].status = 'added';
				changeLoading(false);
				updateRender();
			},
			handleError: (): void => {
				changeLoading(false);
			}
		})
	}

	function deleteRequest(_index: number, changeLoading: Function): void {
		changeLoading(true);

		api({
			pathname: '/user/entities/' + props.data[_index].workspace_name,
			method: 'DELETE',
			handleResponse: (): void => {
				props.data[_index].status = null;
				changeLoading(false);
				updateRender();
			},
			handleError: (): void => {
				changeLoading(false);
			}
		})
	}

	function deletePendingRequest(_index: number, changeLoading: Function): void {
		changeLoading(true);

		api({
			pathname: '/user/entities/' + props.data[_index].workspace_name,
			method: 'PUT',
			handleResponse: (): void => {
				props.data[_index].status = null;
				changeLoading(false);
				updateRender();
			},
			handleError: (): void => {
				changeLoading(false);
			}
		})
	}


	function renderArrayData(): JSX.Element[] {
		return data
			.sort((a: any, b: any): number => {
				return (a.status === b.status) ? 0 : a.status ? -1 : 1;
			})
			.map((item: any, index: number): JSX.Element => {
				function changeLoading(value: boolean): void {
					let arrayLoading = JSON.parse(JSON.stringify(loading));
					arrayLoading[index] = value;
					setLoading(arrayLoading);
				}

				return (
					<TableRow key={index}>
						<TableCell component='th' scope='row'>{item.name}</TableCell>
						<TableCell align='left'>{item.description}</TableCell>
						<TableCell align='center'>
							{(item.status === 'added')
								// @ts-ignore
								? (<Chip label={t('table.registered')} color='success' size='small' />):(item.is_pending === true)?
								// @ts-ignore
								 (<Chip label={t('table.pending')} color='warning' size='small' />):(<Chip label={t('table.unregistered')} color='error' size='small' />)
							}
						</TableCell>
						<TableCell align='right'>
							<Tooltip title={t('table.detailed')}>
								<IconButton
									component={RouterLink}
									to={'/client/entities/' + item.workspace_name}
									disabled={item.status !== 'added'}
									>
									<VisibilitySharpIcon />
								</IconButton>
							</Tooltip>
							{item.status === 'added' || item.is_pending === true
								? (
									<Tooltip title={t('table.quit')}>
										<IconButton onClick={() => {item.is_pending === true ? deletePendingRequest(index, changeLoading) : deleteRequest(index, changeLoading)}} >
											<ClearSharpIcon />
										</IconButton>
									</Tooltip>
								)
								: (
									<Tooltip title={t('table.apply')}>
										<IconButton onClick={() => applyRequest(index, changeLoading)} >
											<AddSharpIcon />
										</IconButton>
									</Tooltip>
								)
							}
						</TableCell>
					</TableRow>
				)
			})
	}

	return (
		<Card sx={{ boxShadow: 6 }}>
			<Grid container justifyContent='space-between'>
				<Grid item sx={{ px: 2, py: 3 }}>
					<Typography variant='h6' display='inline'>
						{t('navigation.entities')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>{t('table.name')}</TableCell>
								<TableCell align='left'>{t('table.description')}</TableCell>
								<TableCell align='center'>{t('table.status')}</TableCell>
								<TableCell align='right'><Box pr={1.5}>{t('table.actions')}</Box></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{renderArrayData()}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</Card>
	)
}

export default DataTable;