import React from 'react';
import { useTranslation } from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LoadingButton from '@material-ui/lab/LoadingButton';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import UniversalCookie from 'universal-cookie';
import api from 'src/utilities/axios';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/core/Autocomplete';

interface IResendFormDialogProps {
	updateRender: any,
	id: number
}

export default function ResendFormDialog(props: IResendFormDialogProps): JSX.Element {
	const { updateRender, id } = props
	const cookie = new UniversalCookie();
	const [open, setOpen] = React.useState<boolean>(false);
	const [arbitraryKey, setArbitraryKey] = React.useState<number>(1);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [addLoading, setAddLoading] = React.useState<boolean>(false);
	const [usersList, setUsersList] = React.useState<any[]>([]);
	const [sendList, setSendList] = React.useState<any[]>([]);
	const navigate =useNavigate();
	const { t } = useTranslation();

	function fetchUsersList(): void {
        api({
            pathname: '/staff/' + cookie.get('workspace') + '/users',
            method: 'GET',
            handleResponse: (response: any): void => {
                setUsersList(response.data);
				setLoading(false);
				setOpen(true);
            },
            handleError: (): void => {
            }
        })
    }

	
	function handleSubmit(event: React.SyntheticEvent): void {
		event.preventDefault();
		event.persist();

		setAddLoading(true);
		let user_ids = sendList.map(a => a.uid);

		api({
			pathname: '/staff/' + cookie.get('workspace') + '/forms/'+ id,
			method: 'POST',
			
			data: {
				users: user_ids

			},
			handleResponse: (response:any): void => {
				setAddLoading(false);
				handleClose();
				updateRender();
			},
			handleError: (): void => {
				setAddLoading(false);
				handleClose();
				updateRender();
				navigate('/staff/form');
			}
		})
	}

	
	function handleClickOpen(): void {
		setLoading(true);
		fetchUsersList();
	};

	function handleSelectAll(): void {
		setSendList(usersList);
		setArbitraryKey(arbitraryKey+1);

	};

	const handleSendList = (value:any) => {
		setSendList(value);

	};

	function handleClose(): void {
		setOpen(false);
	};


	return (
		<React.Fragment>
			<LoadingButton variant='contained' loading={loading} color='primary' onClick={handleClickOpen}>
				<AddSharpIcon /> {t('form.form_send')}
			</LoadingButton>
			<Dialog open={open} maxWidth='sm'  sx={{
					justifyContent: 'center'
				}} fullWidth>
			<DialogTitle>{t('form.send_to')}</DialogTitle>
			<DialogContent >
			
			
			<Autocomplete
				multiple
				id="checkboxes-tags-demo"
				options={usersList}
				value={sendList}
				disableCloseOnSelect
				limitTags={20}
				key={arbitraryKey}
				forcePopupIcon={false}
				onChange={(event, value) => handleSendList(value)} 
				getOptionLabel={(option) => option.name}
				renderInput={(params) => (
					<TextField {...params} hiddenLabel variant="standard" placeholder="" />
				)}
				/>
					
				</DialogContent>
				
				<DialogActions>					
					<Button
						onClick={handleClose}
						color='primary'
					>
						{t('form.cancel')}
					</Button>
					<Button
						onClick={handleSelectAll}
						color='primary'
					>
						{t('form.select_all')}
					</Button>
					<LoadingButton
							component='button'
							type='submit'
							
							variant='contained'
							color='primary'
							loading={addLoading}
							onClick={handleSubmit}
						>
							{t('form.send')}
					</LoadingButton>

				</DialogActions>

			</Dialog>
		</React.Fragment>
	)
}