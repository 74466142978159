import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

import { apiCustom } from 'src/utilities/axios';
import { calculateStartTime, calculateEndTime } from 'src/utilities/date';
import Loader from 'src/components/loader';

import InfoPanel from './components/info-panel';
import BarChart from './components/bar-chart';
import BpChart from './components/bp-chart';
import StepGraph from "./components/step-graph";
import SleepGraph from "src/views/client/reports/resq-band/params/time-series/components/sleep-graph";

function TimeSeries(props: any): JSX.Element {
	const currentTime = new Date(new Date().toUTCString());
	const location = useLocation();
	const { t, i18n } = useTranslation();
	const search = queryString.parse(location.search)
	
	const [data, setData] = React.useState<any>({});
	const [dbp_data, setDbpData] = React.useState<any>({});
	const [loading, setLoading] = React.useState<boolean>(true);
	const [interval, setInterval] = React.useState<string>(search.report_id === "00005100" ? 'daily' : 'weekly');
	const [startTimeIndex, setStartTimeIndex] = React.useState<number>(1);

	function onIntervalChange(_interval: string) {
		setLoading(true);
		setInterval(_interval);
		setStartTimeIndex(1);
	}

	function onIndexChange(_index: number) {
		if (startTimeIndex + _index <= 0) {
			return;
		}
		setLoading(true);
		setStartTimeIndex(startTimeIndex + _index);
	}

	function fetchData(): void {

		let pathname = '/user/reports/resq_band/' + search.report_id;
		pathname += '?start_time=' + calculateStartTime(interval, currentTime, startTimeIndex);
		pathname += '&end_time=' + calculateEndTime(interval, currentTime, startTimeIndex);
		pathname += '&interval=' + interval;
		if (search.timezone){
			pathname += '&timezone=' + search.timezone;
		}

		apiCustom({
			pathname: pathname,
			method: 'GET',
			headers: {
				'Authorization': search.idToken && search.idToken
			},
			handleResponse: (response: any): void => {
				setData(response.data.report);
				if (search.report_id !== "00005095"){
					setLoading (false);
				}
			},
			handleError: (): void => {
				setLoading(false);
			}
		});

		if (search.report_id ==="00005095"){
			fetchDbpData();
		}
	}

	function fetchDbpData(): void {
		let pathname = '/user/reports/resq_band/00005096';
		pathname += '?start_time=' + calculateStartTime(interval, currentTime, startTimeIndex);
		pathname += '&end_time=' + calculateEndTime(interval, currentTime, startTimeIndex);
		pathname += '&interval=' + interval;
		if (search.timezone){
			pathname += '&timezone=' + search.timezone;
		}

		apiCustom({
			pathname: pathname,
			method: 'GET',
			headers: {
				'Authorization': search.idToken && search.idToken
			},
			handleResponse: (response: any): void => {
				setDbpData(response.data.report);
				setLoading (false);
			},
			handleError: (): void => {
				setLoading(false);
			}
		})
	}

	function initComp(): void{
		i18n.changeLanguage(search.lang === "en" ? 'en' : 'jp');
	}
	React.useEffect(initComp, []);
	React.useEffect(fetchData, [interval, startTimeIndex]);

	return (
		<Card sx={{ boxShadow: 6 }}>
			<Grid container justifyContent='space-between' spacing={1} sx={{ px: 2, py: 3 }}>
				<Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
					<InfoPanel
						data={data}
						dbp_data={dbp_data}
						startTime={calculateStartTime(interval, currentTime, startTimeIndex) * 1000}
						endTime={calculateEndTime(interval, currentTime, startTimeIndex) * 1000}
						interval={interval}
						onIndexChange={onIndexChange}
					/>
					
				</Grid>
				<Grid item xs={12} sm={3} sx={{ textAlign: 'right' }}>
					<FormControl size='small' sx={{ textAlign: 'left', width: '100px' }}>
						<InputLabel id='interval-label'>{t('date.interval')}</InputLabel>
						<Select
							labelId='interval-label'
							id='interval'
							value={interval}
							label={t('date.interval')}
							onChange={(event) => onIntervalChange(event.target.value)}
						>
							<MenuItem value='hourly'>{t('date.hourly')}</MenuItem>
							<MenuItem value='daily'>{t('date.daily')}</MenuItem>
							<MenuItem value='weekly'>{t('date.weekly')}</MenuItem>
							<MenuItem value='monthly'>{t('date.monthly')}</MenuItem>
							<MenuItem value='yearly'>{t('date.yearly')}</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<Loader state={loading}>
						{data.id === "00005097"
							? (<StepGraph
								values={data.values && data.values}
								interval={interval}
								min={data.y_min}
								max={data.y_max}
								reportId={data.id}
							/>)
							: (data.id === "00005095" ? (
							  <BpChart
								values={data.values && data.values}
								values_dbp={dbp_data.values && dbp_data.values}
								interval={interval}
								min={dbp_data.y_min}
								max={data.y_max}
								reportId={data.id}/>
							) : (data.id === "00005100" ? (
							  <SleepGraph
								  values={data.values && data.values}
								  interval={interval}
								  min={data.y_min}
								  max={data.y_max}
								  reportId={data.id}
								/>
							) : (<BarChart
								values={data.values && data.values}
								interval={interval}
								min={data.y_min}
								max={data.y_max}
								reportId={data.id}
							/>)))
						}
					</Loader>
				</Grid>
			</Grid>
		</Card>
	)
}

export default TimeSeries;