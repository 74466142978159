import DashboardLayout from "src/layouts/Dashboard";
import Home from "src/views/common/home";
// import { Reports } from "src/views/client/reports";
// import ReportsResQParams from "src/views/client/reports/resq-band/params";
// import Data from "src/views/client/data";
// import DataParams from "src/views/client/data/params";
import Entities from "src/views/admin/entities";
import Users from "src/views/admin/users";
import EntitiesParams from "src/views/admin/entities/params";
import CSV from "src/views/admin/CSVdownload";

import Profile from "src/views/common/profile";

const adminRoutes = {
	path: 'admin',
	element: <DashboardLayout usertype={'admin'}/>,
	children: [
		{ path: '', element: <Home /> },
		// { path: 'reports', element: <Reports /> },
		// { path: 'reports/resq-band/:type/:id', element: <ReportsResQParams /> },
		// { path: 'data', element: <Data /> },
		{ path: 'users', element: <Users /> },
		{ path: 'CSVdownload', element: <CSV /> },
		{ path: 'entities', element: <Entities /> },
		{ path: 'entities/:workspace_name', element: <EntitiesParams /> },
		{ path: 'profile', element: <Profile />}
	]
}

export default adminRoutes;
