import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import DownloadSharpIcon from '@material-ui/icons/DownloadSharp';

import ImageFormDialog from '../image-form-dialog';

import api from 'src/utilities/axios';

interface IImageTableProps {
	data: any,
	updateRender: any
}

function ImageTable(props: IImageTableProps): JSX.Element {
	const { data, updateRender } = props;
	const { t } = useTranslation();

	function downloadImage(name: string, id: number): void {
		api({
			method: 'GET',
			pathname: '/user/data/' + data.id + '/images/' + id,
			handleResponse: (response: any): void => {
				let file = document.createElement('a');
				file.download = name;
				file.href = 'data:application/octet-stream;base64,' + response.data.base64_image;
				file.click();
			},
			handleError: (error: any): void => {
				console.log(error);
			}
		})
	}

	return (
		<Card sx={{ boxShadow: 6 }}>
			<Grid container justifyContent='space-between'>
				<Grid item sx={{ px: 2, py: 3 }}>
					<Typography variant='h6' display='inline'>
						{t('navigation.images')}
					</Typography>
				</Grid>
				<Grid item sx={{ px: 2, py: 3 }}>
					<ImageFormDialog data={data} updateRender={updateRender} />
				</Grid>
				<Grid item xs={12}>
					<Table>
						<TableBody>
							{data.images && data.images.map((item: any, index: number) => {
								return (
									<TableRow key={index}>
										<TableCell>Image File {index + 1}</TableCell>
										<TableCell align='right'>
											<IconButton onClick={() => downloadImage('imageFile' + (index + 1) + '.png', item.id)}>
												<DownloadSharpIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</Card>
	)
}

export default ImageTable;