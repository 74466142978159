import { Fragment } from "react"
import AssessmentSharpIcon from '@material-ui/icons/AssessmentSharp';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
// import QuestionAnswerSharpIcon from '@material-ui/icons/QuestionAnswerSharp';
import DevicesOtherSharpIcon from '@material-ui/icons/DevicesOtherSharp';
import LayersSharpIcon from '@material-ui/icons/LayersSharp';
import BusinessCenterSharpIcon from '@material-ui/icons/BusinessCenterSharp';
import PeopleSharpIcon from '@material-ui/icons/PeopleSharp';
import AppSettingsAltSharpIcon from '@material-ui/icons/AppSettingsAltSharp';
import MarkunreadSharpIcon from '@material-ui/icons/MarkunreadSharp';

// import NotificationBadge from "../notification-badge"

const staff = [
	{
		href: '/staff/reports',
		icon: AssessmentSharpIcon,
		title: 'navigation.reports',
		tooltip_title: 'tooltip.menu_reports',
		badge: Fragment,
	},
	{
		href: '/staff/form',
		icon: DescriptionSharpIcon,
		title: 'navigation.forms',
		tooltip_title: 'tooltip.menu_forms',
		badge: Fragment,
	},
	// {
	// 	href: '/staff/questions',
	// 	icon: QuestionAnswerSharpIcon,
	// 	title: 'navigation.questions',
	// badge: Fragment,
	// },
	{
		href: '/staff/devices',
		icon: DevicesOtherSharpIcon,
		title: 'navigation.devices',
		tooltip_title: 'tooltip.menu_devices',
		badge: Fragment,
	},
	{
		href: '/staff/modules',
		icon: LayersSharpIcon,
		title: 'navigation.modules',
		tooltip_title: 'tooltip.menu_modules',
		badge: Fragment,
	},
	{
		href: '/staff/staffs',
		icon: BusinessCenterSharpIcon,
		title: 'navigation.staffs',
		tooltip_title: 'tooltip.menu_staffs',
		badge: Fragment,
	},
	{
		href: '/staff/users',
		icon: PeopleSharpIcon,
		title: 'navigation.users',
		tooltip_title: 'tooltip.menu_users',
		badge: Fragment,
	},
	{
		href: '/staff/notification-settings',
		icon: AppSettingsAltSharpIcon,
		title: 'navigation.notification_settings',
		tooltip_title: 'tooltip.menu_notification_settings',
		badge: Fragment,
	},
	{
		href: '/staff/notifications',
		icon: MarkunreadSharpIcon,
		title: 'navigation.notifications',
		tooltip_title: 'tooltip.menu_notification_list',
		badge: Fragment,
	}
]

export default staff;