import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import UniversalCookie from 'universal-cookie';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import Loader from 'src/components/loader';
import api from 'src/utilities/axios';
import withRouter from 'src/hoc/withRouter';

import Covid19PCRTest from './components/covid19_pcr_test';
import Covid19Vaccine from './components/covid19_vaccine';

function UsersParams(props: any): JSX.Element {
	const { id } = props.params;
	const location = useLocation();
	const { t } = useTranslation();
	const cookie = new UniversalCookie();
	const query = queryString.parse(location.search);
	
	const [loading, setLoading] = React.useState<boolean>(true);
	const [data, setData] = React.useState<any>([]);

	function fetchData() {
		setLoading(true);

		api({
			method: 'GET',
			pathname: '/staff/' + cookie.get('workspace') + '/user/' + id + '/data',
			handleResponse: (response: any): void => {
				setData(response.data);
				setLoading(false);
			},
			handleError: (error: any): void => {
				console.log(error);
				setLoading(false);
			}
		})
	}

	function renderData() {
		let render: any = [];

		data.map((item: any, index: number) => {
			if (item.id === 'covid19_pcr_test')
				render.push(
					<Grid item lg={4} key={index}>
						<Covid19PCRTest />
					</Grid>
				)

			if (item.id === 'covid19_vaccine')
				render.push(
					<Grid item lg={4} key={index}>
						<Covid19Vaccine />
					</Grid>
				)
			
			if (item.id === 'form_data_10')
				render.push(
					<Grid item lg={4} key={index}>
						<Card sx={{ boxShadow: 6, px: 2, py: 3 }}>
							<Typography variant='h6'>
								Form Data
							</Typography>
						</Card>
					</Grid>
				);
			
			if (item.id === 'mobile_0509')
				render.push(
					<Grid item lg={4} key={index}>
						<Card sx={{ boxShadow: 6, px: 2, py: 3 }}>
							<Typography variant='h6'>
								{t('data.mobile_0509_title')}
							</Typography>
						</Card>
					</Grid>
				);
			
			if (item.id === 'user_info')
				render.push(
					<Grid item lg={4} key={index}>
						<Card sx={{ boxShadow: 6, px: 2, py: 3 }}>
							<Typography variant='h6'>
								{t('data.user_info_title')}
							</Typography>
						</Card>
					</Grid>
				)
		})

		if (data.length === 0)
			render = (
				<Grid item xs={12}>
					<Typography variant='body1'>
						{t('data.no_data_msg')}
					</Typography>
				</Grid>
			)

		return render;
	}

	React.useEffect(fetchData, [id]);

	return (
		<Loader state={loading}>
			<Grid container justifyContent='space-between' spacing={3}>
				<Grid item>
					<Typography variant='h3' sx={{ mb: 1 }}>
						{query.name} ( {query.email} )
					</Typography>
					<Breadcrumbs aria-label='breadcrumb'>
						<Link component={RouterLink} color='inherit' to='/staff'>
							{t('navigation.dashboard')}
						</Link>
						<Link component={RouterLink} color='inherit' to='/staff/users'>
							{t('navigation.users')}
						</Link>
						<Typography color='text.primary'>
							{query.name} ( {query.email} )
						</Typography>
					</Breadcrumbs>
				</Grid>
			</Grid>
			
			<Divider sx={{ my: 3 }} />

			<Grid container justifyContent='flex-start' spacing={3}>
				{renderData()}
			</Grid>
		</Loader>
	)
}

export default withRouter(UsersParams)