import React, {useRef, useEffect} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import api from 'src/utilities/axios';
import {useNavigate} from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Stack from '@material-ui/core/Stack';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/core/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import AdapterDayjs from '@material-ui/lab/AdapterDayjs';
import 'dayjs/locale/ru';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DesktopDatePicker from '@material-ui/lab/DesktopDatePicker';
import TimePicker from '@material-ui/lab/TimePicker';
import DateTimePicker from '@material-ui/lab/DateTimePicker';
import { Dayjs } from 'dayjs';
import Skeleton from '@material-ui/core/Skeleton';
import Divider from '@material-ui/core/Divider';
import LoadingButton from '@material-ui/lab/LoadingButton';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useLocation } from "react-router-dom";
import withRouter from 'src/hoc/withRouter';

function FormDetailAnswer(props: any) {
	const { t } = useTranslation();
  const location = useLocation();
  const id = props.params.id;
  const sent_order = location.state.sent_order;

  const [all, setAll] = React.useState<any>({});
  const [questions, setQuestions] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [contentLoading, setContentLoading] = React.useState<boolean>(true);
  const [isChecked, setIsChecked] = React.useState<any>(null);
  const [answers, setAnswers] = React.useState<any[]>([]);
  const navigate = useNavigate();
  const formRef = React.useRef();
  const bottomRef = useRef(null);
  const [errorOpen, setErrorOpen] = React.useState(false);


  const handleCheckChange = (event, question_id) => {
    setIsChecked({ ...isChecked, [event.target.name]: event.target.checked });
    const temp = answers.find(o=>o.question_id=== question_id).answer_options
    if (event.target.checked){
      answers.find(o=>o.question_id=== question_id).answer_options = [...temp, Number(event.target.name)]
    } else {
      var temp_index = answers.find(o=>o.question_id=== question_id).answer_options.indexOf(Number(event.target.name));
      if (temp_index >= 0) {
        answers.find(o=>o.question_id=== question_id).answer_options.splice( temp_index, 1 );
      }

    }
    setAnswers(answers);
    setAnswers(currentRows=> [...currentRows]);
  };
	
  const handleTextChange = (e, question_id) =>{
    answers.find(o=>o.question_id=== question_id).value=e.target.value
    setAnswers(answers);
    setAnswers(currentRows=> [...currentRows]);
  };

  const handleNumberChange = (e, question_id) =>{
    answers.find(o=>o.question_id=== question_id).value=e.target.value
    setAnswers(answers);
    setAnswers(currentRows=> [...currentRows]);
  };
 

  const handleRadioChange = (e, question_id) =>{
    answers.find(o=>o.question_id=== question_id).answer_options = [Number(e.target.name)]
    setAnswers(answers);
    setAnswers(currentRows=> [...currentRows]);
  };


  const handleDateChange =(d: Dayjs | null, question_id) =>{
    answers.find(o=>o.question_id=== question_id).value = d['$d']
    setAnswers(answers);
    setAnswers(currentRows=> [...currentRows]);
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [errorOpen]);

  const handleTimeChange =(d: Dayjs | null, question_id) =>{
    answers.find(o=>o.question_id=== question_id).value = d['$d']
    setAnswers(answers);
    setAnswers(currentRows=> [...currentRows]);
  };

  const handleDateTimeChange =(d: Dayjs | null, question_id) =>{
    answers.find(o=>o.question_id=== question_id).value = d['$d']
    setAnswers(answers);
    setAnswers(currentRows=> [...currentRows]);
  };
  

  function handleSubmit(event: React.SyntheticEvent, formRef): void {
		event.preventDefault();
		event.persist();

    if (formRef?.current?.reportValidity() && !answers.find(o=>o.is_required=== true && (o.value===null||o.value==='') && !o.answer_options.length)){

        setLoading(true);
        api({
          pathname: '/user/forms/' +id +'/'+ sent_order +'/answer',
          method: 'POST',
          
          data: {
            answers: answers

          },
          handleResponse: (response: any): void => {
            setLoading(false);
            if (response){navigate('/client/forms');}
  
          },
          handleError: (): void => {
            setLoading(false);
          }
        })
    } else {
    setLoading(false);
    setErrorOpen(true);
  }
	}

  function fetchData(): void {

		api({
			pathname: '/user/forms/' +id +'/'+ sent_order +'/answer',
			method: 'GET',
			handleResponse: (response: any): void => {
				setLoading(true);
        setContentLoading(true);
				setAll(response.data);
        setAnswers(response.data.questions.map((item, index)=>({"question_id":item.id, "value":null, "answer_options":[], "is_required":item.is_required})));
				setQuestions(response.data.questions);
        setLoading(false);
        setContentLoading(false);
			},
			handleError: (): void => {
				setLoading(false);
			}
		})

	}

	React.useEffect(fetchData, []);

  

	return (
        <div>
            <Grid container justifyContent='space-between' spacing={3}>
                  <Grid item>
                      <Typography variant='h3' sx={{ mb: 1 }}>
                          {t('form.form_answer')}
                      </Typography>
                      <Breadcrumbs aria-label='breadcrumb'>
                          <Link component={RouterLink} color='inherit' to='/client'>
                              {t('navigation.dashboard')}
                          </Link>
                          <Link component={RouterLink} color='inherit' to='/client/forms'>
                              {t('navigation.forms')}
                          </Link>
                          <Link component={RouterLink} color='inherit' to={'/client/forms/'+id+'/detail'}>
                              {t('navigation.form_detail')}
                          </Link>
                          <Typography color='text.primary'>
                              {all.name}
                          </Typography>
                      </Breadcrumbs>
                      
                  </Grid>
                 
                  <Grid item>
                  <Stack spacing={2} direction="row">

                    <LoadingButton variant='contained' loading={loading} color='primary' onClick={(e)=> handleSubmit(e, formRef)}>
                      {t("form.answer")}
                    </LoadingButton>
                    </Stack>
                    </Grid>
              </Grid>

              <Divider sx={{ my: 3 }} />
              <div>
            
        {contentLoading?(<div>
          <Stack alignItems="center" justifyContent="center" spacing={3}>
          <Skeleton variant="rectangular" sx={{
                    '& > :not(style)': { m: 5, width: '100%' },
                    width: '100%',
                    maxWidth:"100ch",
                    flexWrap: 'wrap',
                    border: 1,
                    borderColor: 'grey.300', 
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '16px',
                }} animation="wave" height={'27ch'} />
          <Skeleton variant="rectangular" sx={{
                    '& > :not(style)': { m: 5, width: '100%' },
                    width: '100%',
                    maxWidth:"100ch",
                    flexWrap: 'wrap',
                    border: 1,
                    borderColor: 'grey.300', 
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '16px',
                }} animation="wave" height={'30ch'} />
          <Skeleton variant="rectangular" sx={{
                    '& > :not(style)': { m: 5, width: '100%' },
                    width: '100%',
                    maxWidth:"100ch",
                    flexWrap: 'wrap',
                    border: 1,
                    borderColor: 'grey.300', 
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '16px',
                }} animation="wave" height={'30ch'} />
          </Stack>
          </div>):(
        <form ref={formRef}>
            <Stack alignItems="center" justifyContent="center" spacing={3}>
                <Box
                sx={{
                    '& > :not(style)': { m: 5, width:"100%"},
                    width:"100%",
                    maxWidth:"100ch",
                    flexWrap: 'wrap',
                    backgroundColor: '#FFFFFF',
                    border: 1,
                    borderColor: 'grey.300', 
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '16px',
                }}
                >
                    <Stack  spacing={3}>
                    
                    <Typography variant="h3" gutterBottom>
                      {all.name}
                    </Typography>
                    <Typography variant="subtitle2" sx={{whiteSpace: "pre-line"}} gutterBottom>
                      {all.description}
                    </Typography>
                    </Stack>
                </Box>
                <Snackbar anchorOrigin={ {vertical: 'bottom', horizontal: 'right'} } open={errorOpen} autoHideDuration={6000} onClose={()=>{setErrorOpen(false);}}>
						      <Alert severity="error"
                    action={
                      <IconButton
                      aria-label={t('form.close')}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setErrorOpen(false);
                      }}
                      >
                      <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                    >
                    {t('form.required_field_not_filled')}
                  </Alert>
                </Snackbar>
                
                
                {questions.map((item: any, index: number) => (
                  // <div width={'100%'}>
                  <Stack width={'100%'} alignItems="center" spacing={3}>
                  {answers.find(o=>o.question_id===item.id && o.is_required=== true && (o.value===null||o.value==='') && !o.answer_options.length)&&errorOpen?(
                    <Box
                        id={index.toString()}
                        key={'box-'+index.toString()}
                        sx={{
                            '& > :not(style)': { m: 5, width:"100%" },
                            width:"100%",
                            maxWidth:"100ch",
                            flexWrap: 'wrap',
                            backgroundColor: '#fad1d0',
                            border: 1,
                            borderColor: 'grey.300',
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: '16px',
                        }}
                        >
                            <Grid container spacing={2} ref={bottomRef} justifyContent="flex-end">
                                <Grid item xs={12} >
                                    <Stack spacing={2}>
                                    <Stack spacing={0.5} direction="row">
                                    <Typography variant="h5" gutterBottom>
                                    {item.text}
                                    </Typography>
                                    
      
                                    {item.is_required?(
                                      <span><Typography color="red" variant="caption" sx={{fontWeight: 'bold'}}  gutterBottom>
                                        {'*'+t("form.required")}
                                                                    </Typography></span>
                                    ):(<div></div>)}
                                    </Stack>

                                    <Grid item xs={12}></Grid>
                                    
                                    
                                    {item.question_type_id == 1?(<TextField  fullWidth id="standard-basic" label={t("form.input_type.text")} onChange={e => handleTextChange(e, item.id)} defaultValue={answers.find(o=>o.question_id=== item.id).value} onKeyPress={(event) => { if (event.key === 'Enter') event.preventDefault()}} variant="standard" />
                                    ):(item.question_type_id == 4?(
                                    <FormGroup >
                                       {item.question_options.map((it: any, o_index: number) => (
                                      <FormControlLabel key={'co4-'+o_index.toString()} control={<Checkbox 
                                        name={it.order.toString()}
                                        onChange={(e) => handleCheckChange(e, item.id)}
                                      />} label={it.text} />
                                      ))}
                                    </FormGroup>
                                        
                                    ):(item.question_type_id == 3?(
                                      <FormControl>
                                      <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="random-name"
                                        onChange={e => handleRadioChange(e, item.id)}
                                      >
                                         {item.question_options.map((it: any, o_index: number) => (
                                        <FormControlLabel key={'cont-'+o_index.toString()} name={it.order.toString()} value={it.text} control={<Radio />} label={it.text} />
                                        ))}
                                      </RadioGroup>
                                    </FormControl>
                                    
                                    ):(item.question_type_id == 2?(<TextField id="standard-basic"   type="number" label={t("form.input_type.number")} onChange={e => handleNumberChange(e, item.id)} defaultValue={answers.find(o=>o.question_id=== item.id).value} onKeyPress={(event) => { if (event.key === 'Enter') event.preventDefault()}} variant="standard" />
                                    ):(item.question_type_id == 5?(<LocalizationProvider   dateAdapter={AdapterDayjs}>
                                      <DesktopDatePicker
                                      
                                              label={t("form.input_type.date")}
                                              inputFormat="MM/DD/YYYY"
                                              value={answers.find(o=>o.question_id=== item.id).value}
                                              onChange={e=> handleDateChange(e, item.id)}
                                              renderInput={(params) => <TextField {...params} />}
                                              />
                                      </LocalizationProvider>):(item.question_type_id == 6?(<LocalizationProvider   dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                                    label={t("form.input_type.time")}
                                                    value={answers.find(o=>o.question_id=== item.id).value}
                                                    onChange={e=> handleTimeChange(e, item.id)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    ampm={false}
                                                  />
                                        </LocalizationProvider>):(<LocalizationProvider   dateAdapter={AdapterDayjs}>
                                          <DateTimePicker
                                            label={t("form.input_type.datetime")}
                                            value={answers.find(o=>o.question_id=== item.id).value}
                                            onChange={e=> handleDateTimeChange(e, item.id)}
                                            renderInput={(params) => <TextField {...params} />}
                                            ampm={false}
                                          />
                                        </LocalizationProvider>))))))}

                                   
                                    </Stack>
                                </Grid>

                                
                                <Grid item xs={12}></Grid>
                            </Grid>
                        </Box>
                  ):(
                    <Box
                        id={index.toString()}
                        key={'box-'+index.toString()}
                        sx={{
                            '& > :not(style)': { m: 5, width:"100%" },
                            width:"100%",
                            maxWidth:"100ch",
                            flexWrap: 'wrap',
                            backgroundColor: '#FFFFFF',
                            border: 1,
                            borderColor: 'grey.300',
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: '16px',
                        }}
                        >
                            <Grid container spacing={2} justifyContent="flex-end">
                                <Grid item xs={12} >
                                    <Stack spacing={2}>
                                    <Stack spacing={0.5} direction="row">
                                    <Typography variant="h5" gutterBottom>
                                    {item.text}
                                    </Typography>
                                    
      
                                    {item.is_required?(
                                    <span><Typography color="red" variant="caption" sx={{fontWeight: 'bold'}} gutterBottom>
                                                                  {'*'+t("form.required")}
                                                                  </Typography></span>
                                  ):(<div></div>)}
                                    </Stack>

                                    <Grid item xs={12}></Grid>
                                    
                                    
                                    {item.question_type_id == 1?(<TextField   id="standard-basic" label={t("form.input_type.text")} onChange={e => handleTextChange(e, item.id)} defaultValue={answers.find(o=>o.question_id=== item.id).value} onKeyPress={(event) => { if (event.key === 'Enter') event.preventDefault()}} variant="standard" />
                                    ):(item.question_type_id == 4?(
                                    <FormGroup >
                                       {item.question_options.map((it: any, o_index: number) => (
                                      <FormControlLabel key={'co4-'+o_index.toString()} control={<Checkbox 
                                        name={it.order.toString()}
                                        onChange={(e) => handleCheckChange(e, item.id)}
                                      />} label={it.text} />
                                      ))}
                                    </FormGroup>
                                        
                                    ):(item.question_type_id == 3?(
                                      <FormControl>
                                      <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="random-name"
                                        onChange={e => handleRadioChange(e, item.id)}
                                      >
                                         {item.question_options.map((it: any, o_index: number) => (
                                        <FormControlLabel key={'cont-'+o_index.toString()} name={it.order.toString()} value={it.text} control={<Radio />} label={it.text} />
                                        ))}
                                      </RadioGroup>
                                    </FormControl>
                                    
                                    ):(item.question_type_id == 2?(<TextField id="standard-basic"   type="number" label={t("form.input_type.number")} onChange={e => handleNumberChange(e, item.id)} defaultValue={answers.find(o=>o.question_id=== item.id).value} onKeyPress={(event) => { if (event.key === 'Enter') event.preventDefault()}} variant="standard" />
                                    ):(item.question_type_id == 5?(<LocalizationProvider   dateAdapter={AdapterDayjs}>
                                      <DesktopDatePicker
                                      
                                              label={t("form.input_type.date")}
                                              inputFormat="MM/DD/YYYY"
                                              value={answers.find(o=>o.question_id=== item.id).value}
                                              onChange={e=> handleDateChange(e, item.id)}
                                              renderInput={(params) => <TextField {...params} />}
                                              />
                                      </LocalizationProvider>):(item.question_type_id == 6?(<LocalizationProvider   dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                                    label={t("form.input_type.time")}
                                                    value={answers.find(o=>o.question_id=== item.id).value}
                                                    onChange={e=> handleTimeChange(e, item.id)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    ampm={false}
                                                  />
                                        </LocalizationProvider>):(<LocalizationProvider   dateAdapter={AdapterDayjs}>
                                          <DateTimePicker
                                            label={t("form.input_type.datetime")}
                                            value={answers.find(o=>o.question_id=== item.id).value}
                                            onChange={e=> handleDateTimeChange(e, item.id)}
                                            renderInput={(params) => <TextField {...params} />}
                                            ampm={false}
                                          />
                                        </LocalizationProvider>))))))}

                                   
                                    </Stack>
                                </Grid>

                                
                                <Grid item xs={12}></Grid>
                            </Grid>
                        </Box>
                  ) }

                      
                    </Stack>
                        
                    ))}
                    
                    
                      
                                
                </Stack>
                </form>)}  
                </div>  
              
      </div>
	);
}

export default withRouter(FormDetailAnswer);
