import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Card,
	CardContent,
	Typography,
} from '@material-ui/core';

import { WatchSharp, PhoneAndroidSharp } from "@material-ui/icons"

const iconStyle = {
	width: "50%",
	height: "auto",
	color: "primary.main",
}

export default function Settings({ path }: SettingsProps) {
	const { t } = useTranslation();

	const options = {
		"measurement-value": {
			icon: <WatchSharp sx={iconStyle} />,
			name: t("notification_settings.measurement_value"),
		},
		"synchronization": {
			icon: <PhoneAndroidSharp sx={iconStyle} />,
			name: t("notification_settings.synchronization")
		},
	}
	return (
		<Box component={RouterLink} to={'/staff/notification-settings/' + path}>
			<Card sx={{ boxShadow: 6, pt: 6, pb: 2 }}>
				<CardContent sx={{ textAlign: 'center', py: 0 }}>
					{options[path].icon}
					<Typography variant='h6'>
						{options[path].name}
					</Typography>
				</CardContent>
			</Card>
		</Box>
	);
}

interface SettingsProps {
	path: string;
}