import { Bar } from "react-chartjs-2";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { formatTime } from "src/utilities/date";
import { UserReportStateType } from "src/redux/user-report/reducers";

export interface IPublicProps {
  values: any;
  values_dbp: any;
  interval: string;
  min: number;
  max: number;
  reportId: string;
  reduxState: any;
}

export interface IReduxStateProps {
  reduxState: {
    userReport: UserReportStateType;
  };
}

export type Props = IPublicProps & IReduxStateProps;

const DEFAULT_LINE_STYLE: any = {
  borderWidth: 2,
  pointBorderWidth: 3,
  pointRadius: 3,
  pointHoverBorderWidth: 5,
};

export function BpChartComponent(props: Props): JSX.Element {
  const { values = [], values_dbp =[], min = 0, max = 100, reportId = "" } = props;
  const { interval } = props.reduxState.userReport;
  const { t } = useTranslation();

  let options: any = {
    maintainAspectRatio: false,
    scales: {
      y: {
        type: "linear",
        min: reportId == "00005093" ? 80 : min,
        max: max,
        ...(reportId == "00005093" && {
          ticks: {
            stepSize: 1,
            autoSkip: false,
          },
        }),
      },
    },
    plugins: {
      legend: {
        display: false,
        align: "end",
        labels: {
          usePointStyle: true,
          font: {
            size: 12,
          },
        },
      },
    },
  };

  function data(): any {
    let labels: any[] = [];
    let meanDatasets: any[] = [];
    let minMaxDatasets: any[] = [];

    let meanDatasetsDbp: any[] = [];
    let minMaxDatasetsDbp: any[] = [];

    values.map((item: any) => {
      labels.push(formatTime(interval, item[0]));
      meanDatasets.push(parseFloat(item[3]));
      minMaxDatasets.push([parseFloat(item[2]), parseFloat(item[1])]);

      return item;
    });

    values_dbp.map((item: any) => {
      meanDatasetsDbp.push(parseFloat(item[3]));
      minMaxDatasetsDbp.push([parseFloat(item[2]), parseFloat(item[1])]);

      return item;
    });

    return {
      labels: labels.reverse(),
      datasets: [
        {
          type: "line",
          label: t("report.mean_value_sbp"),
          data: meanDatasets.reverse(),
          backgroundColor: "#ff1943",
          borderColor: 'rgba(0,0,0,1)',
          ...DEFAULT_LINE_STYLE,
        },
        {
          type: "line",
          label: t("report.mean_value_dbp"),
          data: meanDatasetsDbp.reverse(),
          backgroundColor: "#260dc4",
          borderColor: 'rgba(0,0,0,1)',
          ...DEFAULT_LINE_STYLE,
        },
        {
          label: t("report.min_max_sbp"),
          borderColor: "#000000",
          backgroundColor: "#c40d23",
          data: minMaxDatasets.reverse(),
        },
          {
            label: t("report.min_max_dbp"),
            borderColor: "#000000",
            backgroundColor: "#260dc4",
            data: minMaxDatasetsDbp.reverse(),
          },
      ],
    };
  }

  return (
    <Box sx={{ height: "60vh" }}>
      <Bar data={data()} options={options} />
    </Box>
  );
}
