export const toSnakeCase = string =>
    string &&
    string
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(
            char =>
                char.toLowerCase())
        .join("_");

export const toKebabCase = string =>
    string &&
    string
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(
            char =>
                char.toLowerCase())
        .join("-");

export const toCamelCase = string =>
    string &&
    string
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (match, char) => char.toUpperCase());