import { connect } from "react-redux";
import { IReduxState } from "src/redux/store";
import { StepGraphComponent } from "./component";

function mapStateToProps(state: IReduxState) {
  return {
    reduxState: {
      staffReport: state.staffReport,
    },
  };
}

export const StepGraph = connect(mapStateToProps)(StepGraphComponent);
