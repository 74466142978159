import React  from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import api from 'src/utilities/axios';
import Box from '@material-ui/core/Box';
import DownloadIcon from '@material-ui/icons/Download';

import { CSVLink } from "react-csv";
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Dialog } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';



function CSV():JSX.Element{
    const { t } = useTranslation();
	const [data, setData] = React.useState<any[]>([]);
	const [open, setOpen] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);
	const csvLink: any = React.useRef();	


	function fetchData(e): void {
		setLoading(true);
			api({
			pathname: '/get_user_list',
			method: 'GET',
			handleResponse: (response: any) => {
				setData(response.data);	
				csvLink.current.link.click();
				setLoading(false);
				setOpen(false);
			},
			handleError: (error: any) => {
			}			
		})

	}

	const handleClose = () => {	
		setOpen(false);
	  };

	const handleOpen =()=>{
		setOpen(true);
	}
 
function ToDialog() {

  return (
      <Box sx={{ display: 'flex', marginLeft: 5, }}>

			<Button color="info" variant="contained" size="large" onClick={handleOpen}>
				<DownloadIcon/>
				{t("report.download")}

		</Button>
      </Box>
  );
}
	const csvData = data;

    return (
		<><><Grid container justifyContent='space-between' spacing={3}>
			<Grid item>
				<Typography variant='h3' sx={{ mb: 1 }}>
					{"CSV"}
				</Typography>

				<Breadcrumbs aria-label='breadcrumb'>
					<Link component={RouterLink} color='inherit' to='/admin'>
						{t('navigation.dashboard')}
					</Link>
					<Typography color='text.primary'>
						{"CSV"}
					</Typography>
				</Breadcrumbs>

				<Typography variant="h5" align="center" color="text.secondary" paragraph>
				<br/>
				{t('report.user_data_download_abled')}

            </Typography>
			
			</Grid>
		</Grid>
		<br/>
		<br/>
			<ToDialog />
		</><Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
				<form  id="submitform">
					<DialogTitle>{t('report.user_data_download')}</DialogTitle>
					<DialogContent>
						<Box
							sx={{ height: 10 }} />

							<DialogContentText sx={{ p: 1 }}>
							{t('report.user_download_description')}
							</DialogContentText>
					
					</DialogContent>
					<DialogActions>
						<Button
							onClick={handleClose}
							color='primary'
						>
							{t('form.cancel')}
							
						</Button>
					<LoadingButton
					 onClick={e=>fetchData(e)}
					 loading={loading}
						>
				
						<DownloadIcon/>
						
						</LoadingButton>
						<CSVLink
						filename={'resQ-user-data.csv'} 
						data={csvData}
						target="_blank"
						ref={csvLink}
								/>
				
					</DialogActions>
				</form>
			</Dialog></>
	)
}

export default CSV;