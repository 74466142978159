import React, { useState, useEffect, FormEvent } from 'react'
import { Link as RouterLink } from "react-router-dom"
import UniversalCookie from 'universal-cookie';
import { useTranslation } from 'react-i18next';

import Loader from 'src/components/loader';
import api from 'src/utilities/axios';
import { toSnakeCase } from 'src/utilities/text';

import { FormStack, TextField, SubmitButton } from '../components/styled-form-components';
import { Grid, Typography, Breadcrumbs, Link, Button, Divider, MenuItem, Tooltip } from "@material-ui/core"


export default function MeasurementValue() {
    const cookie = new UniversalCookie();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [notificationTypes, setNotificationTypes] = useState([]);
    const [selectedOptionId, setSelectedOptionId] = useState<string>("");
    const [isLocked, setIsLocked] = useState<boolean>(true);

    const fetchData = () => {
        api({
            pathname: '/staff/' + cookie.get('workspace') + '/notification_settings/1',
            method: 'GET',
            handleResponse: (response: any): void => {
                switch (response.data.entity.notification_setting_status) {
                    case "unlocked":
                        setIsLocked(false)
                        break;
                    case "locked":
                        setIsLocked(true)
                        break;
                    default:
                        break;
                }

                setNotificationTypes(response.data.notification_setting_type_list.sort((a, b) => a.order - b.order));
                setSelectedOptionId(response.data.notification_setting_type.id);
                setIsLoading(false);
            },
            handleError: (error: any): void => {
                console.log(error);
                setIsLoading(false);
            }
        })
    }

    useEffect(fetchData, []);

    const handleOptionSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOptionId(e.target.value);
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (isLocked) return;

        setIsLoading(true);

        api({
            pathname: '/staff/' + cookie.get('workspace') + '/notification_settings/1',
            method: 'POST',
            data: {
                "notification_setting_type_id": +selectedOptionId,
            },
            handleResponse: (response: any): void => {
                setNotificationTypes(response.data.notification_setting_type_list.sort((a, b) => a.order - b.order));
                setSelectedOptionId(response.data.notification_setting_type.id);
                setIsLoading(false);
            },
            handleError: (error: any): void => setIsLoading(false)
        })
    }

    return (
        <Loader state={isLoading}>
            <Grid container justifyContent='space-between' spacing={3}>
                <Grid item>
                    <Typography variant='h3' sx={{ mb: 1 }}>
                        {t('notification_settings.measurement_value')}
                    </Typography>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link component={RouterLink} color='inherit' to='/staff'>
                            {t('navigation.dashboard')}
                        </Link>
                        <Link component={RouterLink} color="inherit" to="/staff/notification-settings">
                            {t('navigation.notification_settings')}
                        </Link>
                        <Typography color='text.primary'>
                            {t("notification_settings.measurement_value")}
                        </Typography>
                    </Breadcrumbs>
                </Grid>

            </Grid>

            <Divider sx={{ my: 3 }} />

            <FormStack
                onSubmit={handleSubmit}
                title={t("notification_settings.notification_email_settings")}
                // tooltipText={t("insert_here.tooltip")}
                referenceData={[
                    <>
                        <Typography>{t("notification_settings.types.description.1_time")}</Typography>
                        <Typography>{t("notification_settings.types.description.2_times")}</Typography>
                        <Typography>{t("notification_settings.types.description.3_times")}</Typography>
                        <Typography>{t("notification_settings.types.description.real_time")}</Typography>
                        <Typography>{t("notification_settings.types.description.email_notice")}</Typography>
                    </>,
                    <Typography sx={{ fontWeight: "bold" }}>{t("notification_settings.types.description.real_time_notice")}</Typography>
                ]}
            >
                <Typography sx={{ pt: .8, width: "100%", maxWidth: "max-content", fontWeight: "bold", fontSize: "16px" }}>
                    {t("notification_settings.everyday")}
                </Typography>
                <TextField
                    id='times'
                    disabled={isLocked}
                    select
                    value={selectedOptionId}
                    onChange={handleOptionSelect}
                    label={t('notification_settings.number_of_notifications')}
                    helperText={t("notification_settings.receive_notification_email")}
                >
                    {notificationTypes.map(
                        (type) =>
                            <MenuItem key={type.id} value={type.id}>{t(`notification_settings.types.name.${toSnakeCase(type.name)}`)}</MenuItem>
                    )}
                </TextField>

                <SubmitButton
                    isLocked={isLocked}
                >
                    {t("form.set")}
                </SubmitButton>
            </FormStack>
        </Loader >

    )
}
