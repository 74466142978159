import { connect } from "react-redux";

import {
  FormsComponent,

} from "./component";


export const Forms = connect(

)(FormsComponent);
