import Box from "@material-ui/core/Box";
import { Line, Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { formatTime } from "src/utilities/date";

interface ISleepGraphProps {
  values: any;
  interval: string;
  min: number;
  max: number;
  reportId: string,
}

function SleepGraph(props: ISleepGraphProps): JSX.Element {
  const { values = [], interval = "weekly", min = 0, max = 100, reportId = "" } = props;
  const { t } = useTranslation();

  let options: any = {
    maintainAspectRatio: false,
    scales: {
      y: {
        type: "linear",
        min: min,
        max: 12,
      }
    },
    plugins: {
      tooltip: {
          callbacks: {
              label: function(context) {
                  var label = '';

                  if (label) {
                      label += ': ';
                  }
                  if (context.parsed.y !== null) {
                      label += new Intl.NumberFormat('en-US').format(context.parsed.y);
                      label += " 時間";
                  }
                  return label;
              }
          }
      },
    
      legend: {
        display: false,
      },
    },
  };

  let optionsLine: any = {
    maintainAspectRatio: false,
    scales: {
      y: {
        type: "linear",
        min: 0,
        max: 3,
        ticks: {
          callback: function(val, index) {
            if (val === 1 ){
              return t("report.deep_sleep")
            }
            if (val === 2 ){
              return t("report.light_sleep")
            } 
            return '';
          },
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            if (context.parsed.y === 1 ){
              return t("report.deep_sleep")
            }
            if (context.parsed.y === 2 ){
              return t("report.light_sleep")
            }
          }
        }
      },
      legend: {
        display: false,
      },
    },
  };

  function dataBar(): any {
    let labels: any[] = [];
    let min_max_datasets: any[] = [];

    values.map((item: any) => {
      labels.push(formatTime(interval, item[0]));
      if (interval === "yearly"){
        min_max_datasets.push(parseFloat(item[3]));
      }
      else {
        min_max_datasets.push(parseFloat(item[1]));
      }
      
      return item;
    });
    
    return {
      labels: labels.reverse(),
      datasets: [
        {
          label: t("report.min_max"),
          borderColor: "rgba(0,0,0,0)",
          backgroundColor: "#c40d23",
          data: min_max_datasets.reverse(),
        },
      ],
    };
  }

  function dataLine(): any {
    let labels: any[] = [];
    let min_max_datasets: any[] = [];

    values.map((item: any) => {
      labels.push(formatTime(interval, item[0]));
      if (item[1]) {
        min_max_datasets.push(parseFloat(item[2]));
      }
      else {
        min_max_datasets.push(null);
      }
      return item;
    });
    
    return {
      labels: labels.reverse(),
      datasets: [
        {
          label: t("report.min_max"),
          borderColor: "rgba(0,0,0,1)",
          backgroundColor: "#c40d23",
          data: min_max_datasets.reverse(),
          spanGaps: true,
        },
      ],
    };
  }

  return (
    <Box sx={{ height: "60vh" }}>
      
      {['weekly', 'monthly', 'yearly'].includes(interval) ? 
        <Bar data={dataBar()} options={options} />
      : 
        <Line data={dataLine()} options={optionsLine} />
      }
    </Box>
  );
}

export default SleepGraph;
