import moment from 'moment';
import { IUserReportActions, UserReportActions } from "./actions";

export type UserReportStateType = {
  interval: string;
  startDate: Date;
};

const TODAY = new Date();
const WEEK_AGO = moment(TODAY).subtract(6, 'days').toDate();

let initialState: UserReportStateType = {
  interval: "weekly",
  startDate: WEEK_AGO,
};

export function userReportReducer(
  state = initialState,
  actions: IUserReportActions,
) {
  switch (actions.type) {
    case UserReportActions.SetInterval:
      return {
        ...state,
        interval: actions.payload,
      };

    case UserReportActions.SetStartDate:
      return {
        ...state,
        startDate: actions.payload,
      };

    default:
      return state;
  }
}
