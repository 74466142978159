import React from 'react';
import UniversalCookie from 'universal-cookie';
import { useTranslation } from 'react-i18next';

import Alert from '@material-ui/core/Alert';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import firebase from 'src/utilities/firebase';

function PasswordForm(): JSX.Element {
	const cookie = new UniversalCookie();
	const { t } = useTranslation();

    const [loading, setLoading] = React.useState<boolean>(true);
    const [message, setMessage] = React.useState<JSX.Element>(null);

    function handleSubmit(event: React.SyntheticEvent): void {
        event.preventDefault();
		event.persist();
 
		setLoading(false);
		setMessage(null);
 
		const target = event.target as typeof event.target & {
			password: { value: string },
			repassword: { value: string },
			oldPassword: { value: string },
		}
		
		const user = firebase.auth().currentUser;
		const credentials = firebase.auth.EmailAuthProvider.credential(
			cookie.get('userEmail'),
			target.oldPassword.value
		)

		if (target.password.value === target.repassword.value) {
			user
				.reauthenticateWithCredential(credentials)
				.then(() => {
					user
						.updatePassword(target.password.value)
						.then(() => {
							setMessage(<Alert color='success' sx={{ mb: 3 }}>Password successfully changed</Alert>)
						})
						.catch((error: any): void => {
							console.log(error);
						})
				})
				.catch((error: any) => {
					console.log(error.message);
					setMessage(<Alert color='error' sx={{ mb: 3 }}>{error.message}</Alert>)
				})
		} else {
			setMessage(
				<Alert color='error' sx={{ mb: 3 }}>Password does not match</Alert>
			)
		}

    }
    
    return (
		<Card sx={{ boxShadow: 6, px: 2, py: 3 }}>
			{message}
			<form onSubmit={handleSubmit}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant='h6'>
							{t('profile.password_title')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id='oldPassword'
							label={t('profile.old_password')}
							variant='outlined'
							type='password'
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id='password'
							label={t('profile.new_password')}
							variant='outlined'
							type='password'
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id='repassword'
							label={t('profile.new_password_reenter')}
							variant='outlined'
							type='password'
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} style={{'textAlign': 'right'}}>
						<Button type='submit' variant='contained' color='primary'>
							{t('profile.password_change_btn')}
						</Button>
					</Grid>
				</Grid>
			</form>
		</Card>
    )
}

export default PasswordForm;