import { Fragment } from 'react';
import BusinessSharpIcon from '@material-ui/icons/BusinessSharp';
import DownloadIcon from '@material-ui/icons/Download';
import PeopleSharpIcon from '@material-ui/icons/PeopleSharp';

const admin = [
	{
		href: '/admin/entities',
		icon: BusinessSharpIcon,
		title: 'navigation.entities',
		badge: Fragment,
	},
	{
		href: '/admin/CSVdownload',
		icon: DownloadIcon,
		title: 'navigation.CSV',
		badge: Fragment,
	},
	{
		href: '/admin/users',
		icon: PeopleSharpIcon,
		title: 'navigation.users',
		badge: Fragment,
	}
]

export default admin;