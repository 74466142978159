import { connect } from "react-redux";
import { IReduxState } from "src/redux/store";
import { BpChartComponent } from "./component";

function mapStateToProps(state: IReduxState) {
  return {
    reduxState: {
      userReport: state.userReport,
    },
  };
}

export const BpChart = connect(mapStateToProps)(BpChartComponent);
