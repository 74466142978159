import { Fragment } from 'react';
import AssessmentSharpIcon from '@material-ui/icons/AssessmentSharp';
import StorageSharpIcon from '@material-ui/icons/StorageSharp';
import BusinessSharpIcon from '@material-ui/icons/BusinessSharp';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';

const client = [
	{
		href: '/client/reports',
		icon: AssessmentSharpIcon,
		title: 'navigation.reports',
		badge: Fragment,
	},
	{
		href: '/client/data',
		icon: StorageSharpIcon,
		title: 'navigation.data',
		badge: Fragment,
	},
	{
		href: '/client/entities',
		icon: BusinessSharpIcon,
		title: 'navigation.entities',
		badge: Fragment,
	},
	{
		href: '/client/forms',
		icon: DescriptionSharpIcon,
		title: 'navigation.forms',
		badge: Fragment,
	}
]

export default client;