import { createTheme } from '@material-ui/core/styles'

import palette from './palette';
import shadows from './shadows';
import typography from './typography';

function theme() {
	return createTheme(
		{
			shadows,
			typography,
			palette: palette,
		},
	);
};

export default theme;
