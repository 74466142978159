import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import LoadingButton from '@material-ui/lab/LoadingButton';
import api from 'src/utilities/axios';
import { DialogContent } from '@material-ui/core';

interface IAddFormDialogProps {
	updateRender: any,
	deleteDialog: any,
}

export default function DeleteFormInstanceDialog(props: IAddFormDialogProps): JSX.Element {
	const { updateRender, deleteDialog } = props
	const [loading, setLoading] = React.useState<boolean>(false);

	const { t } = useTranslation();

	function handleEdit(event: React.SyntheticEvent): void {
		event.preventDefault();
		event.persist();

		setLoading(true);

        api({
			pathname: '/user/forms/' + deleteDialog.deleteId + '/' + deleteDialog.deleteSentOrder + '/answer',
			method: 'Delete',
			handleResponse: (): void => {
	        	setLoading(false);
                handleClose();
                updateRender();

			},
			handleError: (): void => {
				setLoading(false);
                handleClose();
                updateRender();
			}
		})
	}

	function handleClose(): void {
		deleteDialog.setState(false);
	};

	return (
		<Dialog open={deleteDialog.state} onClose={handleClose} maxWidth='sm' fullWidth>
			<form onSubmit={handleEdit}>
				<DialogTitle>{t("form.delete_dialog_title")}</DialogTitle>
				<DialogContent>{t('form.delete_client_answer')}</DialogContent>
				
				<DialogActions>
					<Button
						onClick={handleClose}
						color='primary'
					>
						{t('form.cancel')}
					</Button>
					<LoadingButton
						component='button'
						type='submit'
						variant='contained'
						color='primary'
						loading={loading}
					>
						{t('form.delete')}
					</LoadingButton>
				</DialogActions>
			</form>
		</Dialog>
	)
}