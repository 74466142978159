import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

interface IStatsProps {
	id: string
	name: string
	type: string
	value: string
	unit: string
	color: string
}

function Stats(props: IStatsProps) {
	const { id, name, type, value, unit, color } = props;
	const { t } = useTranslation();

	return (
		<Box component={RouterLink} to={'/client/reports/resq-band/' + type + '/' + id}>
			<Card sx={{ boxShadow: 6, pt: 6, pb: 2 }}>
				<CardContent sx={{ textAlign: 'center', py: 0}}>
					<img
						src={'/static/img/dashboard/' + id + '.svg'}
						alt={name}
						width='50%'
					/>
					<Typography variant='h1' sx={{ pt: 3, pb: 1 }}>
						<Box fontWeight='fontWeightLight' color={color}>
							<span style={{ fontSize: '28px', color: color }}>●</span>
							&nbsp;
							{value + ' ' + unit}
						</Box>
					</Typography>
					<Typography variant='h6'>
						{t('report.'+id, name)}
					</Typography>
				</CardContent>
			</Card>
		</Box>
	);
}

export default Stats;