import { Stack } from '@material-ui/core'
import { ArrowDropUp, ArrowDropDown } from "@material-ui/icons";

const normalizeStyle = {
    // to 10px width
    fontSize: '.625rem',
    // to 20px width
    transform: "scale(2)",
}

export default function CustomSortIcon(direction, active) {
    if (direction === "asc") {
        return function CustomSortIcon() {
            return (
                <Stack sx={{ p: 1 }}>
                    <ArrowDropUp sx={{ ...normalizeStyle, color: active ? "text.primary" : "text.disabled" }} />
                    <ArrowDropDown sx={{ ...normalizeStyle, color: "text.disabled" }} />
                </Stack>
            )
        }
    }

    if (direction === "desc") {
        return function CustomSortIcon() {
            return (
                <Stack sx={{ p: 1 }}>
                    <ArrowDropUp sx={{ ...normalizeStyle, color: "text.disabled" }} />
                    <ArrowDropDown sx={{ ...normalizeStyle, color: active ? "text.primary" : "text.disabled" }} />
                </Stack>
            )
        }
    }

    return () => { return null }
}