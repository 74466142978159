import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import LoadingButton from '@material-ui/lab/LoadingButton';

import Loader from 'src/components/loader';
import api from 'src/utilities/axios';

function AnswerFormDialog(props: any): JSX.Element {
	const { data, updateRender } = props;
	const { t } = useTranslation();

	const [open, setOpen] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);

	function handleAnswer(event: React.SyntheticEvent): void {
		event.preventDefault();
		event.persist();

		setLoading(false);

		const target = event.target as typeof event.target & {
			text: { value: string };
		}

		api({
			pathname: '/user/questions/' + data.question.id + '/answer',
			method: 'POST',
			data: {
				value: target.text.value
			},
			handleResponse: (): void => {
				setLoading(false);
				handleClose();
				updateRender();
			},
			handleError: (): void => {
				setLoading(false);
			}
		})
	}

	function handleClose(): void {
		setOpen(false);
	}

	function handleOpen(): void {
		setOpen(true);
	}

	return (
		<React.Fragment>
			<Button variant='contained' color='primary' onClick={handleOpen}>
				{t('form.add_answer')}
			</Button>
			<Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='sm'>
				<Loader state={loading}>
					<form onSubmit={handleAnswer}>
						<DialogTitle>Answer</DialogTitle>
						<DialogContent>
							<TextField
								label='Text :'
								id='text'
								name='text'
								variant='outlined'
								fullWidth
							/>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={handleClose}
								color='primary'
							>
								{t('form.cancel')}
							</Button>
							<LoadingButton
								component='button'
								type='submit'
								variant='contained'
								color='primary'
								loading={loading}
							>
								{t('form.answer')}
							</LoadingButton>
						</DialogActions>
					</form>
				</Loader>
			</Dialog>
		</React.Fragment>
	)
}

export default AnswerFormDialog;