import React from 'react'

import { FormControlLabel, Switch, FormControlLabelProps } from "@material-ui/core";

import { LockSharp, LockOpenSharp } from '@material-ui/icons';



export default function LockSwitch(props: Omit<FormControlLabelProps, "control">) {
    return (
        <FormControlLabel
            {...props}
            labelPlacement="start"
            sx={{
                "& .MuiFormControlLabel-label": {
                    width: "max-content",
                    textAlign: 'center'
                }
            }}
            control={
                <Switch
                    sx={{
                        alignItems: "center",
                        "& .MuiSwitch-switchBase": {
                            p: "6px",
                            top: "initial",
                            transform: "translateX(5px)",
                            // bgcolor: "background.paper",
                            color: "primary.main",

                            "&:hover": {
                                // bgcolor: "background.paper",
                            },

                            "&.Mui-checked": {
                                transform: "translateX(18px)",
                                // bgcolor: "background.paper",
                                color: "success.main",

                                "&:hover": {
                                    // bgcolor: "background.paper",
                                },

                                "& + .MuiSwitch-track": {
                                    bgcolor: "success.main",
                                },

                                "&.Mui-disabled": {
                                    color: "#eaf5ea", //success.main @ 0.12 opacity on #fff
                                }
                            },

                            "&.Mui-disabled": {
                                color: "#f8e2e5", //primary.main @ 0.12 opacity on #fff
                            }
                        },

                        "& .MuiSwitch-thumb": {
                            bgcolor: "primary.main",
                        },

                        "& .MuiSwitch-track": {
                            bgcolor: "primary.main",
                        }

                    }}
                    icon={
                        <LockSharp
                            sx={{
                                p: "2px",
                                bgcolor: "background.paper",
                                borderColor: "primary.main",
                                border: "1px solid",
                                borderRadius: "50%"
                            }}
                        />
                    }
                    checkedIcon={
                        <LockOpenSharp
                            sx={{
                                p: "2px",
                                bgcolor: "background.paper",
                                borderColor: "success.main",
                                border: "1px solid",
                                borderRadius: "50%"
                            }}
                        />
                    }
                />
            }
        />

    )
}