import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UniversalCookie from 'universal-cookie';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import PersonIcon from '@material-ui/icons/Person';

import firebase from 'src/utilities/firebase';

export default function UserMenu(): JSX.Element {
	const { t } = useTranslation();
	const cookie = new UniversalCookie();

	const [anchorMenu, setAnchorMenu] = React.useState<any>(null);

	function toggleMenu (event: React.SyntheticEvent): void {
		setAnchorMenu(event.currentTarget);
	};

	function closeMenu(): void {
		setAnchorMenu(null);
	};

	function handleSignOut(): void {
		firebase
			.auth()
			.signOut()

		let userType = cookie.get('userType');
		
		localStorage.setItem("isAuthenticated", "false");

		cookie.remove('userEmail', { path: '/' });
		cookie.remove('userType', { path: '/' });
		cookie.remove('workspace', { path: '/' });

		if (userType === 'staff'){
			window.location.assign('/auth/staff/sign-in');
		}else if (userType === 'admin'){
			window.location.assign('/auth/admin/sign-in');
		}else	window.location.assign('/auth/client/sign-in');

	}

	const changeToStaff = () => {
		cookie.set('userType', 'staff', { path: '/' });
		window.location.replace('/auth/staff/entity-select');
	}

	const changeToClient = () => {
		cookie.set('userType', 'client', { path: '/' });
		cookie.remove('workspace', { path: '/' });
		window.location.replace('/client');
	}

	const changeEntity = () => {
		if (cookie.get('userType') === 'staff')
			return ([
				(
					<MenuItem key="change_entity" onClick={changeToStaff}>
						{t('header.change_entity')}
					</MenuItem>
				),
				(
					<MenuItem key="change_to_client" onClick={changeToClient}>
						{t('header.change_to_client')}
					</MenuItem>
				),
				(
					<Divider key="divider" sx={{ my: 2 }} />
				)
			])
	}

	return (
		<React.Fragment>
			<IconButton
				aria-owns={Boolean(anchorMenu) ?  'menu-appbar' : undefined}
				aria-haspopup='true'
				onClick={toggleMenu}
				color='inherit'
				>
				<PersonIcon />
			</IconButton>
			
			<Menu
				id='menu-appbar'
				anchorEl={anchorMenu}
				open={Boolean(anchorMenu)}
				onClose={closeMenu}
			>
				<MenuItem key="email">
					E-Mail&nbsp;:&nbsp;<b>{cookie.get('userEmail') && cookie.get('userEmail')}</b>
				</MenuItem>

				<Divider key="divider" sx={{ my: 2 }} />
				
				{changeEntity()}
				
				<MenuItem key="profile" component={RouterLink} to={'/' + cookie.get('userType') + '/profile'}>
					{t('header.profile')}
				</MenuItem>
				<MenuItem key="signout" onClick={handleSignOut}>
					{t('header.sign_out')}
				</MenuItem>
			</Menu>
		</React.Fragment>
	);
}