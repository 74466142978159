
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

import { Card, Box, Table, TableBody, TableHead, TableContainer, TableRow, TableCell, TablePagination, TableSortLabel, Checkbox, Toolbar, Typography, alpha } from "@material-ui/core"
import { visuallyHidden } from '@material-ui/utils';

import CustomSortIcon from './custom-sort-icon';

import { stableSort, getComparator } from 'src/utilities/data-table';

type Order = "asc" | "desc";

type keyString<T> = Extract<keyof T, string>;

interface headerCell<T> {
    key: Extract<keyof T, string>;
    i18nKey: string;
    sortable: boolean;
    align: "right" | "left" | "inherit" | "center" | "justify";
}

interface DataTableProps<DataType> {
    data: DataType[];
    header: headerCell<DataType>[];
    defaultSortBy?: keyString<DataType>;
    toolbar?: {
        title: string;
        actions: (selectedIds: readonly string[]) => React.ReactNode;
        defaultActions?: React.ReactNode;
    }
}

export default function DataTable<DataType extends { id: string }>({ data, header, defaultSortBy, toolbar, ...props }: DataTableProps<DataType>) {
    // i18n hook
    const { t } = useTranslation();

    // Sort States
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<keyString<DataType>>(defaultSortBy);

    // Pagination States
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Select States
    const [selectedIds, setSelectedIds] = React.useState<readonly string[]>([]);

    // SIDE EFFECT HANDLERS
    useEffect(() => {
        setSelectedIds([]);
    }, [data, page, rowsPerPage, order, orderBy]);

    // Data
    const renderData = stableSort(data, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    // Sort Handlers
    const createSortHandler =
        (property: keyString<DataType>) => (event: React.MouseEvent<unknown>) => {
            handleRequestSort(event, property);
        };

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyString<DataType>,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // Pagination Handlers
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Selection Handler
    const isSelected = (id: string) => selectedIds.indexOf(id) !== -1;

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = renderData.map((row) => row.id);
            setSelectedIds(newSelected);
            return;
        }
        setSelectedIds([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selectedIds.indexOf(id);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedIds, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedIds.slice(1));
        } else if (selectedIndex === selectedIds.length - 1) {
            newSelected = newSelected.concat(selectedIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedIds.slice(0, selectedIndex),
                selectedIds.slice(selectedIndex + 1),
            );
        }

        setSelectedIds(newSelected);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    return (
        <Card sx={{ boxShadow: 6 }}>
            {
                toolbar && (
                    <Toolbar
                        sx={{
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                            ...(selectedIds.length > 0 && {
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                            }),
                        }}
                    >
                        {selectedIds.length > 0 ? (
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                color="inherit"
                                variant="subtitle1"
                                component="div"
                            >
                                {selectedIds.length} {t("table.selected")}
                            </Typography>
                        ) : (
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                variant="h6"
                                id="tableTitle"
                                component="div"
                            >
                                {toolbar.title}
                            </Typography>
                        )}

                        {
                            selectedIds.length > 0 ? toolbar.actions(selectedIds) : toolbar.defaultActions && (toolbar.defaultActions)
                        }
                    </Toolbar>
                )
            }
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    indeterminate={selectedIds.length > 0 && selectedIds.length < renderData.length}
                                    checked={renderData.length > 0 && selectedIds.length === renderData.length}
                                    onChange={handleSelectAllClick}
                                    inputProps={{
                                        'aria-label': 'select all cells',
                                    }}
                                />
                            </TableCell>
                            {header.map((cell) =>
                                <TableCell
                                    key={cell.key}
                                    align={cell.align}
                                    sortDirection={orderBy === cell.key ? order : false}
                                >
                                    {cell.sortable
                                        ? (
                                            <TableSortLabel
                                                active={orderBy === cell.key}
                                                direction={orderBy === cell.key ? order : 'asc'}
                                                onClick={createSortHandler(cell.key)}
                                                IconComponent={CustomSortIcon(orderBy === cell.key ? order : 'asc', orderBy === cell.key)}
                                                sx={{
                                                    color: orderBy === cell.key ? "text.primary" : "text.disabled"
                                                }}
                                            >
                                                {t(cell.i18nKey)}
                                                {orderBy === cell.key ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        )
                                        : (
                                            t(cell.i18nKey)
                                        )
                                    }
                                </TableCell>)
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            renderData.map((item) => {
                                const isItemSelected = isSelected(item.id);
                                return (
                                    <TableRow key={item.id}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                onClick={(event) => handleClick(event, item.id)}
                                                inputProps={{
                                                    'aria-label': `select item by id ${item.id}`,
                                                }}
                                            />
                                        </TableCell>
                                        {Object.keys(item).map((key) => {
                                            if (key === "id") return;

                                            return (
                                                <TableCell align={key === "delete" ? "center" : "left"} key={key}>{item[key]}</TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })
                        }
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 73 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={{ px: 1 }}>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25]}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </Card>
    )
}
