import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import api from 'src/utilities/axios';
import Loader from 'src/components/loader';

function ImageFile(props: any): JSX.Element {
	const { t } = useTranslation();
	
	const [data, setData] = React.useState<any>({});
	const [loading, setLoading] = React.useState<boolean>(false);

	function fetchData(): void {
		setLoading(true);

		api({
			pathname: '/user/reports/resq_band/' + props.id,
			method: 'GET',
			handleResponse: (response: any): void => {
				setLoading(false);
				setData(response.data.report);
			},
			handleError: (): void => {
				setLoading(false);
			}
		})
	}

	React.useEffect(fetchData, []);

	return (
		<Loader state={loading}>
			<Typography variant='h3' gutterBottom>
				{data.name && data.name}
			</Typography>
			<Breadcrumbs aria-label='Breadcrumb'>
				<Link component={RouterLink} to='#'>
					{t('sidebar.reports')}
				</Link>
				<Link component={RouterLink} to='/client/reports/resq-band'>
					{t('sidebar.resq_band')}
				</Link>
				<Typography>{data.name && data.name}</Typography>
			</Breadcrumbs>

			<Divider sx={{ mb: 6 }} />

			<Card>
				<img src={data.img_url} />
			</Card>
			
		</Loader>
	)
}

export default ImageFile;