import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Alert from '@material-ui/core/Alert';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import Loader from 'src/components/loader';
import api from 'src/utilities/axios';
import withRouter from 'src/hoc/withRouter';

import Info from './components/manage';
import DataTable from './components/data-table';

function EntitiesParams(props: any): JSX.Element {
	const { workspace_name } = props.params;
	const { t } = useTranslation();

	const [data, setData] = React.useState<any>([]);
	const [modules, setModules] = React.useState<any>([]);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<JSX.Element>(null);
	const [render, setRender] = React.useState<boolean>(false);

	const updateRender = (): void => setRender(!render);

	function fetchData(): void {
		setLoading(true);

		api({
			pathname: '/user/entities/' + workspace_name,
			method: 'GET',
			handleResponse: (response: any): void => {
				setData(response.data);
				setLoading(false);
				setMessage(null);
			},
			handleError: (error: any): void => {
				setLoading(false);
				setMessage(<Alert severity='error'>{error.response.data.detail}</Alert>);
			}
		})

		api({
			pathname: '/user/entities/' + workspace_name + '/modules',
			method: 'GET',
			handleResponse: (response: any): void => {
				setModules(response.data);
				setLoading(false);
				setMessage(null);
			},
			handleError: (error: any): void => {
				setLoading(false);
				setMessage(<Alert severity='error'>{error.response.data.detail}</Alert>);
			}
		})
	}

	React.useEffect(fetchData, [render]);

	return (
		<Loader state={loading}>
			<Typography variant='h3' sx={{ mb: 1 }}>
				{data.entity && data.entity.name}
			</Typography>

			<Breadcrumbs aria-label='breadcrumb'>
				<Link component={RouterLink} color='inherit' to='/client'>
					{t('navigation.dashboard')}
				</Link>
				<Link component={RouterLink} color='inherit' to='/client/entities'>
					{t('navigation.entities')}
				</Link>
				<Typography color='text.primary'>
					{data.entity && data.entity.name}
				</Typography>
			</Breadcrumbs>

			<Divider sx={{ my: 3 }} />

			{message}

			<Grid container justifyContent='space-between' spacing={3}>
				<Grid item xs={12} lg={3}>
					<Info data={data} />
				</Grid>
				<Grid item xs={12} lg={9}> 
					<DataTable data={modules} updateRender={updateRender} />
				</Grid>
			</Grid>
		</Loader>
	)
}

export default withRouter(EntitiesParams);