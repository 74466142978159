import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "@material-ui/core";
import { ArrowBackIosSharp, ArrowForwardIosSharp } from "@material-ui/icons";
import { StaffReportStateType } from "src/redux/staff-report/reducers";
import {
  calculateHourAgo,
  calculateDayAgo,
  calculateWeekAgo,
  calculateMonthAgo,
  calculateYearFirst,
} from "src/utilities/date";

export interface IReduxStateProps {
  reduxState: {
    staffReport: StaffReportStateType;
  };
}

export interface IReduxDispatchProps {
  setStartDate: (_: any) => void;
  setComparingDataSet: (_: any) => void;
}

export type Props = IReduxDispatchProps & IReduxStateProps;

export function GraphPaginationComponent(props: Props) {
  const { interval, startDate } = props.reduxState.staffReport;
  const { t } = useTranslation();

  function onChangeSkipIndex(_index: number): void {
    let skippedDate = new Date(startDate.getTime());

    switch (interval) {
      case "hourly":
        skippedDate = calculateHourAgo(skippedDate, _index);
        break;
      case "daily":
        skippedDate = calculateDayAgo(skippedDate, _index);
        break;
      case "weekly":
        skippedDate = calculateWeekAgo(skippedDate, _index);
        break;
      case "monthly":
        skippedDate = calculateMonthAgo(skippedDate, _index);
        break;
      case "yearly":
        skippedDate = calculateYearFirst(skippedDate, _index);
        break;
      default:
        skippedDate = new Date(startDate.getTime());
    }

    props.setComparingDataSet([]);
    props.setStartDate(skippedDate);
  }

  return (
    <ButtonGroup variant="contained" color="primary">
      <Button
        startIcon={<ArrowBackIosSharp />}
        onClick={() => onChangeSkipIndex(1)}
      >
        {t("report.previous")}
      </Button>
      <Button
        endIcon={<ArrowForwardIosSharp />}
        onClick={() => onChangeSkipIndex(-1)}
      >
        {t("report.next")}
      </Button>
    </ButtonGroup>
  );
}
