import { useLocation, useParams } from 'react-router-dom';

function WithRouter (Child: any) {
	return function WithRouter (props: any) {
		const location = useLocation();
		const params = useParams();

		return <Child {...props} location={location} params={params} />;
   }
}

export default WithRouter;