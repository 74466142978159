import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Card, CardContent, Stack, Typography, CardActions, Button, Divider } from '@material-ui/core';

import LockSwitch from './lock-switch';

export default function LockCard({ openModal, isLocked, isAllowed, setIsAllowed, ...props }) {
    const { t } = useTranslation();

    const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isLocked) return;
        setIsAllowed(e.target.checked);
    }

    return (
        <Card sx={{ boxShadow: 6, p: 1 }}>
            <CardContent
                sx={{
                    p: 1,
                    display: "flex",
                    flexDirection: {
                        xs: "column",
                        sm: "row"
                    },
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                <Typography variant="h6" sx={{
                    textAlign: {
                        xs: "center",
                        sm: "left"
                    }
                }}>
                    {t("notification_settings.lock_question")}
                    {
                        isLocked && (
                            <Stack component="span" direction="column">
                                <Typography component="span" sx={{ fontSize: "12px", color: "text.secondary" }}>{t("notification_settings.not_allowed")}</Typography>
                                <Typography component="span" sx={{ fontSize: "12px", color: "text.secondary" }}>{t("notification_settings.lock_validation_modal.content.line_2")}</Typography>
                            </Stack>
                        )
                    }
                </Typography>

                <LockSwitch
                    disabled={isLocked}
                    checked={isAllowed}
                    onChange={handleSwitchChange}
                    label={
                        isAllowed
                            ? t("notification_settings.is_allowed")
                            : t("notification_settings.not_allowed")
                    }
                />
            </CardContent>

            <Divider />

            <CardActions disableSpacing sx={{
                p: 1,
                display: "flex",
                justifyContent: "flex-end"
            }}>
                <Button type="button" variant="contained" disabled={isAllowed || isLocked} onClick={openModal} >{t("form.set")}</Button>
            </CardActions>
        </Card>
    )
}
