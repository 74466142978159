import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/core/Alert';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LoadingButton from '@material-ui/lab/LoadingButton';
import LanguageMenu from 'src/components/language-menu';
import api from 'src/utilities/axios';
import Terms from './terms';
 
export default function SignUp(): JSX.Element {
	const { t, i18n } = useTranslation();
	
	const [loading, setLoading] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<JSX.Element>(null);
	const [terms, setTerms] = React.useState<boolean>(false);
	const [targetData, setTargetData] = React.useState<any>(null);
	
	function handleOnSubmit(event: React.SyntheticEvent): void {
				event.preventDefault();
		
		const target = event.target as typeof event.target & {
			name: { value: string };
			email: { value: string };
			password: { value: string };
			repassword: { value: string };
		}
	
		
		if(target.password.value.length >= 150){
			setMessage(<Alert severity='warning'>{"form.name_max_length"}</Alert>);
		}
		else if(target.password.value.length && target.repassword.value.length < 6){			
			setMessage(<Alert severity='warning'>{t('form.not_enough_password_char')}</Alert>);
		}
		else if(target.password.value!==target.repassword.value){
			setMessage(<Alert severity='warning'>{t('form.password_not_matched')}</Alert>);
		}else {
			sessionStorage.setItem('name',target.name.value);
			sessionStorage.setItem('email',target.email.value);
			localStorage.setItem("isAuthenticated", "true");
			setTargetData(target);
			setTerms(true);
		
	}}
	
	function handleRegister(event: React.SyntheticEvent) {
		event.preventDefault();
		
		if (targetData === null) {
			setTerms(false);
			return;
		}
		setLoading(true);
		setMessage(null);
		
		api({
			pathname: '/user/register',
			method: 'POST',
			data: {
				name: targetData.name.value,
				email: targetData.email.value,
				password: targetData.password.value,
				lng: i18n.language,
			},
			handleResponse: (): void => {
				setLoading(false);
				setTerms(false);
				setMessage(<Alert severity='success'>{t('form.register_success')}</Alert>);
			},
			handleError: (error: any) => {
				setLoading(false);
				setTerms(false);
				setMessage(<Alert severity='error'>{error.response.data.detail}</Alert>);
			}
		})
		
	}
 
	if (!terms)
		return (
			<React.Fragment>
				<div style={{ textAlign: 'center' }}>
					<img src='/static/img/brands/ResQ.jpg' width='40%' alt='ResQ' />
				</div>
				<br />
				{message}
				<br />
				<form onSubmit={handleOnSubmit}>
					<TextField
						label={t('form.name')}
						name='name'
						id='name'
						defaultValue={sessionStorage.getItem('name')}
						autoComplete='name'
						autoFocus
						required					
						fullWidth
						sx={{ mb: 2 }}
						
					/>
					<TextField
						label={t('form.email')}
						name='email'
						type='email'
						autoComplete='email'
						defaultValue={sessionStorage.getItem('email')}
						autoFocus
						required
						fullWidth
						sx={{ mb: 2 }}
					/>
					<TextField
						label={t('form.password')}
						name='password'
						type='password'
						autoComplete='current-password'
						autoFocus
						required
						fullWidth
						sx={{ mb: 2 }}
					/>
					<TextField
						label={t('form.re_password')}
						name='repassword'
						type='password'
						required
						fullWidth
						sx={{ mb: 2 }}
					/>
					<LoadingButton
						component='button'
						type='submit'
						fullWidth
						variant='contained'
						color='primary'
						loading={loading}
						sx={{ mb: 1 }}
					>
						{t('form.sign_up')}
					</LoadingButton>
					<Button
						component={RouterLink}
						to='/auth/client/sign-in'
						fullWidth
						sx={{ mb: 1 }}
					>
						{t('form.sign_in')}
					</Button>
				</form>
				<LanguageMenu />
			</React.Fragment>
		);
	else
		return (
			<React.Fragment>
				<form onSubmit={handleRegister}>
					<Terms />
					<FormControlLabel
						control={<Checkbox value='remember' required color='primary' />}
						label={t('common.agree_terms')}
					/>
					<LoadingButton
						component='button'
						type='submit'
						fullWidth
						variant='contained'
						color='primary'
						loading={loading}
						sx={{ mb: 1 }}
					>
						{t('form.sign_up')}
					</LoadingButton>
				</form>
				<LanguageMenu />
			</React.Fragment>
		)
}