import { useTranslation } from 'react-i18next';
import withRouter from 'src/hoc/withRouter';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

function Manage(props: any) {
	const { data } = props;
	const { t } = useTranslation();

	return (
		<Card sx={{ boxShadow: 6, mb: 3 }}>
			<Grid container justifyContent='space-between'>
				<Grid item xs={12}>
					<Box sx={{ mx: 2, my: 3 }}>
						<Typography variant='h6' display='inlin'>
							{t('navigation.information')}
						</Typography>
					</Box>
				</Grid> 
				<Grid item xs={12}>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>
									<b>{t('table.name')}</b>
								</TableCell>
								<TableCell>
									{t('data.'+data.id+'_title', data.name)}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<b>{t('table.description')}</b>
								</TableCell>
								<TableCell>
									{t('data.'+data.id+'_title', data.description)}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</Card>
	)
}

export default withRouter(Manage);