import React from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core';
import UniversalCookie from 'universal-cookie';
import { useLocation } from 'react-router';
import DashboardHeader from 'src/components/header';
import DashboardSidebar from 'src/components/sidebar';
import {Navigate} from 'react-router-dom'
const cookie = new UniversalCookie();

const DashboardLayoutRoot = experimentalStyled('div')(
	({ theme }) => ({
		backgroundColor: theme.palette.background.default,
		display: 'flex',
		height: '100%',
		overflow: 'hidden',
		width: '100%'
	})
);

const DashboardLayoutWrapper = experimentalStyled('div')(
	({ theme }) => ({
		display: 'flex',
		flex: '1 1 auto',
		overflow: 'hidden',
		paddingTop: 64,
		[theme.breakpoints.up('lg')]: {
			paddingLeft: 280
		}
	})
);

const DashboardLayoutContainer = experimentalStyled('div')({
	display: 'flex',
	flex: '1 1 auto',
	overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
	flex: '1 1 auto',
	height: '100%',
	overflow: 'auto',
	padding: '2em',
});

const DashboardLayout = (props: any) => {
	const usertype = props;
	const location= useLocation();
	const [isMobileNavOpen, setMobileNavOpen] = React.useState(false);
	const isAuthenticated = localStorage.getItem("isAuthenticated");
	const navigate = useNavigate();
	let repath = '/';
	if (usertype['usertype'] === 'staff'){
		repath = '/auth/staff/sign-in';
	}else if (usertype['usertype'] === 'admin'){
		repath = '/auth/admin/sign-in';
	}else	{repath = '/auth/client/sign-in';}


	
	if (!cookie.get('userEmail'))
		navigate('/auth/client/sign-in');

	if (!cookie.get('userType'))
		navigate('/auth/client/sign-in');
	
	if (cookie.get('userType') === 'admin' && (window.location.pathname.includes('staff') || window.location.pathname.includes('client')))
	 	navigate('/admin');

	if (window.location.pathname.includes('staff') && cookie.get('userType') === 'client')
		navigate('/client');

	if (window.location.pathname.includes('client') && cookie.get('userType') === 'staff')
		navigate('/staff');

	return (
		isAuthenticated!='true'?<Navigate to={repath} replace state={{from: location}}/>:
		<DashboardLayoutRoot>
			<DashboardHeader onMobileNavOpen={() => setMobileNavOpen(true)} />
			<DashboardSidebar
				onMobileClose={() => setMobileNavOpen(false)}
				openMobile={isMobileNavOpen}
			/>
			<DashboardLayoutWrapper>
				<DashboardLayoutContainer>
					<DashboardLayoutContent>
						<Outlet />
					</DashboardLayoutContent>
				</DashboardLayoutContainer>
			</DashboardLayoutWrapper>
		</DashboardLayoutRoot>)
	// );
};

export default DashboardLayout;
