import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Divider from '@material-ui/core/Divider';
import DataTable from './components/data-table';



function Users():JSX.Element{
    const { t } = useTranslation();
 
    return (
		<>
			<Grid container justifyContent='space-between' spacing={3}>
			<Grid item>
				<Typography variant='h3' sx={{ mb: 1 }}>
                    {t('navigation.users')}
				</Typography>

				<Breadcrumbs aria-label='breadcrumb'>
					<Link component={RouterLink} color='inherit' to='/admin'>
						{t('navigation.dashboard')}
					</Link>
					<Typography color='text.primary'>
                        {t('navigation.users')}
					</Typography>
				</Breadcrumbs>
			</Grid>
		</Grid>
		<Divider sx={{ my: 3 }} />
		<DataTable/>
		</>
	)
}

export default Users;