import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UniversalCookie from 'universal-cookie';

import Alert from '@material-ui/core/Alert';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import LoadingButton from '@material-ui/lab/LoadingButton';

import LanguageMenu from 'src/components/language-menu';
import firebase from 'src/utilities/firebase';
import { apiReturnRes } from 'src/utilities/axios';
import { useLocation } from 'react-router';

export default function SignIn(): JSX.Element {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const cookie = new UniversalCookie();
	const location = useLocation();
	const [loading, setLoading] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<JSX.Element>(null);

	function handleSignIn(event: React.SyntheticEvent) {

		event.preventDefault();
		event.persist();

		setLoading(true);
		setMessage(null);

		const target = event.target as typeof event.target & {
			email: { value: string };
			password: { value: string };
		}

		

		firebase
			.auth()
			.signInWithEmailAndPassword(target.email.value, target.password.value)
			.then((response: any): void => {
				if (response.user.emailVerified) {
					cookie.set('userEmail', response.user.email, { path: '/' });
					cookie.set('userType', 'admin', { path: '/' });
					localStorage.setItem("isAuthenticated", "true");
					apiReturnRes({
						pathname: '/admin/login',
						method: 'POST',
						data: target.email.value,
		
					}).then((response:any)=>{
					if(response.data===true){
						if (location.state?.from){
							return navigate(location.state.from);
						}else{
							return navigate('/admin');}
						
					}else{
						return setMessage(<Alert variant='filled' severity='error'>スーパーユーザーでサインインしてください。</Alert>);		
					}
				}					)
				
					.catch((error)=>setMessage(error.response.data.detail))
				
				} else {
					if (i18n.language == "en") {
						firebase.auth().languageCode = 'en';
					}
					else{
						firebase.auth().languageCode = 'jp';
					}
					const user = firebase.auth().currentUser;
					user
						.sendEmailVerification()
						.then(() => {
							setMessage(<Alert variant='filled' severity='error'>{t('form.email_not_verified')}</Alert>)
						})
						.catch((err: any) => {
							setMessage(<Alert variant='filled' severity='error'>{t('form.unknown_error')}</Alert>)
						});

					firebase.auth().signOut()
				}
			

				setLoading(false);
			
			})
			.catch((error: any): void => {
				setLoading(false);
				if (error.code === "auth/user-not-found"){
					setMessage(<Alert variant='filled' severity='error'>{t("form.no_account_error")}</Alert>)
				}
				else if (error.code === "auth/wrong-password"){
					setMessage(<Alert variant='filled' severity='error'>{t("form.wrong_password_error")}</Alert>)
				}
				else if (error.code === "auth/too-many-requests"){
					setMessage(<Alert variant='filled' severity='error'>{t("form.too_many_request_error")}</Alert>)
				}
				else {
					setMessage(<Alert variant='filled' severity='error'>{t("form.unknown_error")}</Alert>)
				}
			})
	}

	return (
		<React.Fragment>
			<div style={{ textAlign: 'center'}}>
				<img src='/static/img/brands/ResQ.jpg' width='40%' alt='ResQ' />
			</div>
			<br />
			{message}
			<br />
			<form onSubmit={handleSignIn}>
				<TextField
					label={t('form.email')}
					variant='outlined'
					name='email'
					type='email'
					autoComplete='email'
					autoFocus
					required
					fullWidth
					sx={{ mb: 2 }}
				/>
				<TextField
					label={t('form.password')}
					variant='outlined'
					name='password'
					type='password'
					autoComplete='current-password'
					required
					fullWidth
					sx={{ mb: 2 }}
				/>
				<LoadingButton
					component='button'
					type='submit'
					fullWidth
					variant='contained'
					loading={loading}
					sx={{ mb: 1 }}
				>
					{t('form.sign_in')}
				</LoadingButton>
				<Button
					component={RouterLink}
					to='/auth/sign-up'
					fullWidth
					sx={{ mb: 1 }}
				>
					{t('form.create_account')}
				</Button>
				<Button
					component={RouterLink}
					to='/auth/forgot-password'
					fullWidth
					sx={{ mb: 1 }}
				>
					{t('form.forgot_password')}
				</Button>
				<Button
					component='a'
					href='https://ad-dice.com/healthcare-ai/'
					fullWidth
					sx={{ mb: 1 }}
				>
					{t('common.about')}
				</Button>
			</form>
			<LanguageMenu />
		</React.Fragment>
	);
}