import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';

export default function Terms() {
	const { t, i18n } = useTranslation();

	return (
		<Box sx={{
			height: '400px',
			overflow: 'scroll',
			overflowX: 'hidden',
		}}>
			<Typography component='h1' variant='h1' align='center' gutterBottom>
				{t('common.terms')}
			</Typography>
			{i18n.language == "en"
			?
				(<p>
					&emsp; Ad-dice Co., Ltd. (Hereafter referred to as "our company") has established the following Terms of Use (Hereafter referred to as the "terms") for the service ResQ AI (Hereafter, together with applications that need to be installed when using the service and wearable IoT devices that collect health data, the service is referred to as the "service".) operated and provided by our company.<br/><br/>
					<br/>
					<b>1. Introduction</b><br/><br/>
					1. To use this service, it is necessary to be a user according to the procedures specified in these terms. You can only register as a user if you agree to these terms and conditions, and if you delete your account (it will be treated as a  withdrawal), all personal information about you will be erased and  will never be disclosed or used by our company or any third party.<br/>
					Our company does not compensate you for the accuracy of health data collected from wearable IoT devices used for this service.<br/><br/>
					2. Applicants registered as users will enter the necessary information from the registration page and then apply the "Registration" button on the user registration screen. The contract shall be concluded, the user registration will be completed, and the proxy application for registration will not be allowed. However, if we guide you to the method specified separately, you can apply for a user according to the method.<br/><br/>
					3. Our company may cancel the registration at the discretion of our company if the applicant who wishes users falls under any of the following items.<br/><br/>
					&emsp; (1) Disposal of the eruption of user registration due to violation of the terms of the user registration (including these agreements, not limited to this) in the past. If it turns out to have received.<br/><br/>
					&emsp; (2) When it is found that the contents of the applicant for the user registration include false matters.<br/><br/>
					&emsp; (3) Applicants registered as a user registration will delay payment of payment debt, etc., for some services provided by our company in the past, cannot receive this product for a long time, and refuse to return or exchange. When it turns out that there were other defaults.<br/><br/>
					&emsp; (4) When it is found that it was a registration application specified in Paragraph 1 of this Article.<br/><br/>
					&emsp; (5) When the location of the applicant who is registered is outside USA.<br/><br/>
					&emsp; (6) In addition, if we judge that it is inappropriate for the operation and management of this service, it is reasonably determined.<br/><br/>
					4. In these terms, the user is the one who applied for user registration by the procedure prescribed by our company, assuming that all of these terms are agreed upon. Users can use this service. Users shall accurately understand the contents specified in each of the following items, such as age and usage environment, and use this service within the scope of our company according to the conditions set by our company.<br/><br/>
					&emsp; (1) This service shares the user's health data and does not provide medical treatment by a doctor or an equivalent act. The user should fully recognize that the information provided by the service is not a medical practice and then use this service at his own risk.<br/><br/>
					&emsp; (2) The user must consult a medical institution as needed, based on his discretion, without making a definitive decision based on the information found on this service.<br/><br/>
					&emsp; (3) Users fully understand that the information based on this service is based on limited information and is for reference to the user's judgment and as needed. Please consult a doctor. Even if the values ​​that can be viewed by the user in this service are normal, health is not guaranteed, so give priority to measurements with medical equipment and consultation at medical institutions even in such cases.<br/><br/>
					&emsp; (4) Users should ask questions and consultations regarding health concerns and illnesses to medical institutions. Please get in touch with the ad-dice only about the failure of the RESQ BAND that makes up this service and how to use the ResQ Live app.<br/><br/>
					5. Our company shall be able to change the content at any time and suspend or terminate any service provided using the service and the service.<br/><br/>
					6. Users who do not agree to these terms shall not be able to use this service and will immediately stop the download, installation, or use of this service. If the user has already installed this service, it will be uninstalled.<br/><br/>
					7. The user shall use this service by operating the smartphone terminal that he owns or the personal computer terminal (hereinafter called the terminal).<br/><br/>
					<b>2. Changes to the terms</b><br/><br/>
					1. If our company determines that it is necessary, the user's content shall be able to change or add the contents of this usage agreement with the user's consent. However, if one of the following items is applicable, it shall be considered to have had the user's consent.<br/><br/>
					&emsp; (1) When the change or addition conforms to the general interests of the user<br/><br/>
					&emsp; (2) When the change or addition is reasonable, regardless of the purpose of concluding this usage agreement, it is reasonable in light of the necessity, the equivalent of the content, and the circumstances related to other changes or additions.<br/><br/>
					2. When changing or adding the preceding paragraph, our company shall inform  the change or additional content and the time of the effects in advance by  the use of the Internet and other appropriate methods.<br/><br/>
					<b>3. Ownership of rights such as copyrights</b><br/><br/>
					1. The copyrights and other intellectual property rights, portrait rights, public rights, and other property rights that have been generated by the text, images, images, programs, and all other information that make up the services belong to our company or a third party with the right, except for the rights created by users.<br/><br/>
					2. Our company preserves and accumulates all the information posted, uploaded, or saved by the user (including character information, image information, etc., but is not limited. Hereinafter called "user content"） In addition to preserving and accumulating these, it can be used  in all aspects for free, indefinitely, and unconditionally for smooth operation and improvement of this service, and users agree to this usage.<br/><br/>
					<b>4. User Information and User Content</b><br/><br/>
					1. Our company may obtain the following information (hereinafter referred to as "user information") regarding this service.<br/><br/>
					&emsp; (1) Information about user individuals: Users registered in this service and sent to us (including the case of the user's real name), telephone number, e-mail address, date of birth and gender, etc.<br/><br/>
					&emsp; (2) Information about  terminals: devices, OS, terminal language settings, access countries, etc.<br/><br/>
					&emsp; (3) Information about the communication environment: Communication carrier, communication environment, access areas, etc.<br/><br/>
					&emsp; (4) Information about usage status: Version of this service, usage history of this service, etc.<br/><br/>
					2.If the user information is stored and accumulated, our company will handle it on our privacy policy according to the duty of good administrators and use it for the following purposes.<br/><br/>
					&emsp; (1) Management of this service (including providing all information to users).<br/><br/>
					&emsp; (2) Providing information on our services (not limited to this service) or advertiser companies and affiliated companies that we judge as useful for users.<br/><br/>
					&emsp; (3) Involved to users about things that significantly impact the operation of this service (including substantial changes in the service content and pause, but not limited to these).<br/><br/>
					&emsp; (4) Contact users to seek consent regarding handling user information.<br/><br/>
					&emsp; (5) Creating statistical information related to the usage status of this service, publications on this service or our website, sales to third parties, etc.<br/><br/>
					&emsp; (6) Providing advertisements and other information according to the age, occupation, gender, hobbies, and preferences of users.<br/><br/>
					&emsp; (7) Use in research and development in general, including marketing analysis for this service.<br/><br/>
					3. Our company shall not disclose user information to third parties other than the person except in the following cases.<br/><br/>
					&emsp; (1) When aggregating and analyzing user information for purposes such as improvement of this service, development related business development, research and development, and marketing of partner companies<br/><br/>
					&emsp; (2) When disclosed or provided to third parties, in a concept that cannot be personally identified by the preceding item's aggregation and analysis.<br/><br/>
					&emsp; (3) When the disclosure and use of personal information have the consent of the user<br/><br/>
					&emsp; (4) When the user is deemed to be disclosed or used personal information to provide the desired services<br/><br/>
					&emsp; (5) If a third party (hereinafter referred to as a "partner") requires personal information for the purpose of providing the services that the user wants, needs personal information . (In addition, partners cannot use the personal information provided by our company beyond the necessary range to provide the service).<br/><br/>
					&emsp; (6) When a user requested an answer to a questionnaire provided by our company to provide the characters, numbers, still images, and other information provided to third parties as the answer information ( In this case, the information provider of our company shall be limited to the ordering source and partner of the questionnaire, and will be provided in a format that users cannot be identified).<br/><br/>
					&emsp; (7) When sending information about the advertisement, service, etc., of our company or partner to the user<br/><br/>
					&emsp; (8) When based on laws and regulations<br/><br/>
					&emsp; (9) If it is necessary to protect the life of our company, users and other third parties, the body or property, or the protection of all services provided by our company<br/><br/>
					&emsp; (10) When it is especially necessary to improve public health or promote the healthy development of children<br/><br/>
					&emsp; (11) If the national institution, local governments, or those who have been commissioned need to cooperate in carrying out the clerical work specified by law if these people are requested to disclose it adequately.<br/><br/>
					4. Our company does not guarantee the integrity and availability of the user information and user content and the integrity and availability. If the user wants to preserve the user information and user content, he shall back up his own responsibility.<br/><br/>
					5. Our company shall be able to erase all user information and user content at the discretion of our company when the user withdraws from the service or our company has completed the operation of this service. We do not respond to requests of return ,etc.<br/><br/>
					<b>5. Price and Cost</b><br/><br/>
					1. Our company will pay for all or part of the functions that can be added or expanded by the selection of users and include other paid services (including points and items that can be used in this service, etc. However, it is not limited to these).<br/><br/>
					2. Our company will provide the functions that are paid or free in this service at the agreement based on the price listed when the user applies. The price may be changed according to our judgment. If the user does not accept the change, he shall agree in advance that all or part of the service may not be available.<br/><br/>
					3.The purchase, introduction, or maintenance of the terminals required for the use of this service, all communication charges such as packet fees, data usage fees, etc., shall be borne by the user.<br/><br/>
					<b>6. Prohibited</b><br/><br/>
					In using this service, the user shall not perform any of the following acts or any acts that may cause such acts.<br/><br/>
					&emsp; (1) Acts that violate laws, public order and morals, or these terms<br/><br/>
					&emsp; (2) Acts that infringe copyrights or other intellectual property rights, portrait rights, publicity rights or other moral rights, ownership rights or other property rights of our company or a third party;<br/><br/>
					&emsp; (3) Acts that unfairly discriminate or slander our company or a third party, or acts that damage the honor or credibility of our company or the service;<br/><br/>
					&emsp; (4) Acts of posting, writing, or uploading any user content that includes expressions or content that harass, obscene, slander, or disgust a third party;<br/><br/>
					&emsp; (5) Acts of posting links to adult sites, dating sites, etc., acts aimed at wishing or inducing encounters with the opposite sex;<br/><br/>
					&emsp; (6) Obtaining, disclosing, tampering or leaking information that can identify a specific individual, such as name, title, telephone number, e-mail address, address, etc., or non-public information, trade secrets, or confidential information of companies, organizations, etc., without the permission of the person or the right holder;<br/><br/>
					&emsp; (7) Acts of impersonating a third party, and registering or posting other false information;<br/><br/>
					&emsp; (8) Any act against the will of other users, such as stalking or obstinately chasing other users;<br/><br/>
					&emsp; (9) Unauthorized access or misleading acts such as behaving as someone else;<br/><br/>
					&emsp; (10) Providing benefits or other acts of cooperation with anti-social forces<br/><br/>
					&emsp; (11) Advertisement, announcement, solicitation and other trading behavior of ponzi schemes, multi-level marketing, network businesses and other transactions similar to chain schemes.<br/><br/>
					&emsp; (12) Acts for profit without the approval of our company<br/><br/>
					&emsp; (13) Religious and political activities and their advertisement, announcement  and solicitation;<br/><br/>
					&emsp; (14) Acts of holding multiple user accounts on the same terminal without the approval of our company;<br/><br/>
					&emsp; (15) Acts of sharing a user account with multiple people or sharing with multiple terminals;<br/><br/>
					&emsp; (16) Acts that significantly load the server or network for operating this service;<br/><br/>
					&emsp; (17) Acts of altering, damaging, disassembling, decompiling, or reverse engineering the service;<br/><br/>
					&emsp; (18) Acts of illegally acquiring various contents (including, but not limited to, points, items, etc. that can be used within the service; hereinafter referred to as "In-service content") provided by our company through the service<br/><br/>
					&emsp; (19) Acts that interfere with the server or network system of this service, acts that illegally operate the service using BOT, cheat tools, or other technical means;<br/><br/>
					&emsp; (20) Acts that use defects in the service (including, but not limited to, program defects, erroneous displays or malfunctions, etc. due to human error, etc.);<br/><br/>
					&emsp; (21) Acts of transferring or allowing a third party to use, in exchange for cash or other property benefits, all or part of the user account, in-service content, or other rights or benefits available to the user in this service, and their promotion, announcement and solicitation;<br/><br/>
					&emsp; (22) Acts that cause nuisance or damage to our company or a third party;<br/><br/>
					&emsp; (23) Acts of making irrational demands to our company, such as repeating similar or similar inquiries more than necessary;<br/><br/>
					&emsp; (24) Acts that interfere with the operation of the service by our company;<br/><br/>
					&emsp; (25) Acts that assist or encourage acts falling under any of the above (1) to (24);<br/><br/>
					&emsp; (26) Other acts that our company judges to be inappropriate;<br/><br/>
					<b>7. Disclaimers</b><br/><br/>
					1. Our company does not guarantee that the service does not infringe the rights of third parties, nor that the execution of the service does not infringe the rights of third parties.<br/><br/>
					2. Users shall use this service at their own responsibility and judgment. Our company does not guarantee the precision, completeness, accuracy, adequacy, applicability, usefulness, or availability of the service, nor does it guarantee the suitability for the user's purpose of use or the terminal used.<br/><br/>
					3. Our company shall not be concerned in any transaction made by the user through the service, and shall not guarantee the accuracy of any information through the service. Users shall attempt to resolve any disputes arising with other users at their own responsibility and judgment.<br/><br/>
					4. Natural disasters such as earthquakes, tsunamis, typhoons, thunder, heavy rains and floods, force such as wars, disputes, revolutions and labor disputes, temporary or large-scale maintenance of systems necessary for the operation of the service, congestion of network lines, provider failures, or other failures related to the operation of the service may make it impossible to use the service temporarily or for a long period of time, or our company may suspend or terminate the use of the service itself. We are not responsible for these events to users.<br/><br/>
					5. Our company shall not be liable to the user for any damage caused to the user due to the actions of a third party, such as unauthorized access to the service, invasion of computer viruses, etc.<br/><br/>
					6. Our company shall not be liable for any damages incurred to users or third parties in connection with the service.<br/><br/>
					7. Our company shall not be liable for any troubles, promises, guarantees, etc. between users and advertisers of advertisements implemented on this service, and any loss or damage caused to users as a result. .<br/><br/>
					8. Our company does not guarantee the legality, integrity, safety, accuracy, or compliance with public order and morals of any third-party websites accessible via links from the service.<br/><br/>
					9. Our company is not obligated to respond to all inquiries, opinions, suggestions for improvement, feedback, etc. from users regarding this service, or to implement any kind of response. We shall implement these at our own discretion and arbitrary judgment. Our company may implement some kind of response to the contents of inquiries, opinions, suggestions, feedback, etc. from users, however such actions by our company shall not be construed as an obligation to individually respond, report, or provide monetary compensation to such users.<br/><br/>
					<b>8. Terminal and account management</b><br/><br/>
					1. When using this service, the user shall manage, at his/her own responsibility, his/her own terminal used for the use and his/her own account obtained through user registration (hereinafter referred to as "user account").<br/><br/>
					2. The user shall be fully responsible for any actions taken using his/her terminal or user account, regardless of who is actually using the terminal or account. If our company or a third party suffers damages due to such actions, the user shall compensate for the damages.<br/><br/>
					3. Our company shall not be liable for any damage of the user caused by failure or loss of the terminal used by the user, attacks by malware, spyware, viruses, hacking, etc. from third parties, or inadequate management of user accounts, errors in use or use by third parties, etc.<br/><br/>
					4. If the user's user account is illegally acquired or there is a risk that the terminal or user account will be used by a third party, the user shall promptly withdraw from the service or report to our company. The user shall take all such measures as may reasonably be deemed necessary to avoid damage to our company or a third party.<br/><br/>
					<b>9. ID and Password management</b><br/><br/>
					1. The user shall be responsible for strictly managing and storing the user ID and password set by the user at the time of user registration.<br/><br/>
					2. The user shall not transfer, sell, succeed, lend, disclose or leak the user ID and password to a third party without the prior consent of our company.<br/><br/>
					3. The user shall immediately contact our company if it is found that the user ID or password has been illegally used by a third party or there is a possibility of such use.<br/><br/>
					4. Users shall be responsible for damages caused by inadequate management of user ID or password, errors or omissions in use, unauthorized use by third parties, etc., and our company shall not bear any responsibility.<br/><br/>
					<b>10. Change of registered contents</b><br/><br/>
					1. If there is any change in all or part of the matters notified to our company, the user shall promptly change the registered content by the method separately specified by our company. If such changes are not made, our company's execution  of business based on the matters already registered shall be deemed to be proper and effective.<br/><br/>
					2. Our company shall not be liable for any damages caused by the user's failure to register the change in a timely manner.<br/><br/>
					<b>11. Account suspension, etc.</b><br/><br/>
					If the user falls under any of the following items, our company may suspend provision of all or part of the service, suspend and delete the use of the user Account, or withdraw from the service, or otherwise we shall be able to take measures to consider. Our company shall not be obliged to disclose the reason for such measures, and the user shall not be able to raise an objection to our company regarding such measures. Even if measures are taken to suspend use or cancel user registration, the user who is subject to such measures will not be exempted from the responsibility under this agreement, such as payment obligations that have already occurred due to the use of this service.<br/><br/>
					&emsp; (1) If it is found that the user has been subject to disposition such as cancellation of user registration due to violation of other terms and conditions provided by our company in the past<br/><br/>
					&emsp; (2) If it turns out that the registered information contains false information (including, but not limited to the case where the user's address notified at the time of user registration is the address of the forwarding service company designated by us)<br/><br/>
					&emsp; (3) With regard to other services provided by our company in the past, it was found without justifiable reasons that there were delays in payment obligations such as fees, long periods of unacceptability, refusal of returns or exchanges, or other defaults.<br/><br/>
					&emsp; (4) The user belongs to anti-social forces (organized crime groups, members of organized crime groups, persons who have not been a member of organized crime groups for less than 5 years, quasi-members of organized crime groups, companies related to organized crime groups, corporate racketeers, etc., social movements, etc. and advocates, or special intelligence violent group, etc., or other similar persons), or commits illegal acts such as violent acts, fraudulent acts, threatening acts, and business obstruction acts<br/><br/>
					&emsp; (5) Regardless of the reason, if our company determines that the operation of the service has been hindered or interfered by the user's actions (including actions and omissions outside the service), or If our company determines that there is a risk.<br/><br/>
					&emsp; (6) If there is a violation such as performing a prohibited act stipulated in these terms and other terms established by our company (including but not limited to these terms), or if our company determines that there is a risk of such violation<br/><br/>
					&emsp; (7) Other cases where our company determines that the user is inappropriate as a user of the service. <br/><br/>
					<b>12. Prohibition of transfer of rights and obligations, etc. </b><br/><br/>
					Users shall not transfer, succeed, pledge or otherwise dispose of any rights or obligations under these terms to a third party.<br/><br/>
					<b>13. Withdrawal </b><br/><br/>
					1. When the user withdraws from this service, the user shall immediately fulfill all obligations to our company at that time.<br/><br/>
					2. When the user replaces the terminal used for using this service due to repair or model change, except as otherwise specified by our company, the registered information before replacing the terminal etc. cannot be transferred to the new terminal.<br/><br/>
					<b>14. Indemnity</b><br/><br/>
					If the user causes damage (including but not limited to reasonable attorney's fees) to our company or a third party due to a violation of these terms, the user shall be liable to our company or the third party for such damage.<br/><br/>
					<b>15. Governing Law and Jurisdiction</b><br/><br/>
					1. These terms shall be governed by and interpreted in accordance with Japanese law.<br/><br/>
					2. The Tokyo District Court shall be the exclusive jurisdiction court of first instance for all lawsuits and other disputes relating to this service and these terms.
				</p>)
			:
				(<p>
					&emsp; 株式会社アドダイス（以下、「当社」といいます）は、当社が運営し提供するサービス「ResQ AI」（以下、当該サービスを利用する際にインストールが必要となるアプリケーションおよび健康データを収集するウェアラブルIoT端末と合わせ、「本サービス」といいます）について、以下のとおり利用規約（以下、「本規約」といいます）を定めます。<br/><br/>
					<br/>
					<b>第1条. (はじめに)</b><br/><br/>
					1. 本サービスを利用するには、本規約に定める手続きに従い、利用者となる必要があります。<br/><br/>
					2．利用者登録の希望者は、登録ページから必要事項を入力の上希望者自ら利用者登録を行うものとし利用者登録画面において「登録」ボタンを押した段階で当社との間で本規約に従った契約が成立し利用者登録が完了するものとし、代理による登録申請は認めないものとします。ただし、当社が別途に指定する方法を案内する場合はその方法に従い利用者申請することができます。<br/><br/>
					3．当社は、利用者登録希望者が以下の各号の何れかに該当する場合、当社の判断により当該登録を取消すことがあります。<br/><br/>
					&emsp; (1) 利用者登録の希望者が、過去に当社との間で成立した規約（本規約を含みますが、これに限りません）に違反したこと等により、利用者登録の抹消等の処分を受けていたことが判明した場合<br/><br/>
					&emsp; (2) 利用者登録の希望者の申請内容に虚偽の事項が含まれていることが判明した場合<br/><br/>
					&emsp; (3) 利用者登録の希望者が、過去に当社の提供する何らかのサービスに関して、正当な理由なく、料金等の支払債務の履行遅延、長期間に亘る本商品の受取り不能、返品・交換の拒絶その他の債務不履行があったことが判明した場合<br/><br/>
					&emsp; (4) 本条第1項に定める代理による登録申請であったことが判明した場合<br/><br/>
					&emsp; (5) 利用者登録の希望者の所在地が日本国外であった場合<br/><br/>
					&emsp; (6) その他本サービスの運営・管理上、不適当であると当社が合理的に判断した場合<br/><br/>
					4．本規約において利用者とは、本規約の内容に全て同意することを前提として、当社所定の手続に従い利用者登録を申請し、当社がこれを承認した者をいいます。利用者は本サービスを利用することができます。利用者は、年齢や利用環境等、以下各号に定める内容を正確に理解したうえで、当社の定める条件に応じて、当社の定める範囲内で、本サービスを利用するものとします。<br/><br/>
					&emsp; ①本サービスは、利用者の健康データを共有するもので、医師による診療行為又はこれに準ずる行為の提供を行うものではありません。本利用者は、本サービスが提供する情報が医療行為ではないことを十分に認識した上で自己の責任において本サービスを利用してください。<br/><br/>
					&emsp; ②利用者は、本サービスに基づく情報を根拠にした確定的判断を行うことなく、必ず自身の判断に基づき、必要に応じて適宜医療機関の受診等を行ってください。<br/><br/>
					&emsp; ③利用者は、本サービスに基づく情報は、限られた情報に基づくものであって、利用者の判断の参考とするためのものであることを十分に理解し、必要に応じて適宜医療機関の受診等を行ってください。本サービスで利用者が閲覧できる値が正常な値域となっていた時でも健康を保証するものではありませんのでこのような時でも医療機器での計測および医療機関での受診を優先して下さい。<br/><br/>
					&emsp; ④利用者は、健康の不安および病気などについての質問および相談は医療機関に対して行って下さい。本サービスを構成するResQ Bandの故障やResQ Liveアプリの使い方についてのみアドダイスにお問い合わせください。<br/><br/>
					5. 当社は、本サービス及び本サービスを利用して提供される一切のサービスについて、いつでも任意にその内容を変更し、中断しまたは終了することができるものとします。 <br/><br/>
					6. 本規約に同意しない利用者は、本サービスを利用することはできないものとし、直ちに本サービスのダウンロード、インストールまたは利用を中止するものとします。利用者が本サービスを既にインストールしている場合、そのアンインストールを行うものとします。 <br/><br/>
					7. 利用者は、本サービスの利用を、自身の所有するスマートフォン端末ないしは自身の所有するパーソナルコンピューター端末（以下、単に「端末」といいます）の操作によって行うものとします。<br/><br/>
					<b>第2条.（本利用契約の変更）</b><br/><br/>
					１　当社は、当社が必要と判断する場合、利用者の承諾を得て、本利用契約の内容を変更又は追加できるものとする。但し、次の各号の一に該当する場合、利用者の承諾があったものとみなすことができるものとする。<br/><br/>
					&emsp; (1) 当該変更又は追加が、利用者の一般の利益に適合するとき<br/><br/>
					&emsp; (2) 当該変更又は追加が、本利用契約を締結した目的に反せず、かつ、必要性、内容の相当性、その他の当該変更又は追加に係る事情に照らして合理的なものであるとき<br/><br/>
					２　当社は、前項の変更又は追加を行うときは、事前にその旨及び当該変更又は追加の内容並びにその効力発生時期をインターネットの利用その他の適切な方法により周知するものとする。<br/><br/>
					<b>第3条. (著作権等の権利の帰属)</b><br/><br/>
					1. 本サービスを構成する文章、画像、プログラムその他一切の情報について発生している著作権その他の知的財産権、肖像権及びパブリシティ権その他の人格権ならびに所有権その他の財産権は、利用者が自ら作成したものに関する権利を除き、当社または当該権利を有する第三者に帰属します。 <br/><br/>
					2. 当社は、利用者が本サービスにおいて投稿、アップロードまたは保存した全ての情報（文字情報、画像情報等を含みますがこれらに限られません。以下、「利用者コンテンツ」といいます）について、これらを保存・蓄積した上、本サービスの円滑な運営及び改善を目的として、無償、無期限かつ無条件で、あらゆる態様で利用でき、利用者はこれに同意するものとします。<br/><br/>
					<b>第4条. (利用者情報及び利用者コンテンツの取扱い)</b><br/><br/>
					1. 当社は、本サービスに関し、次に掲げる情報（以下、「利用者情報」といいます）を取得することがあります。 <br/><br/>
					&emsp; (1) 利用者個人に関する情報：利用者が本サービスに登録し、当社に送信したアカウント名（利用者の実名である場合を含みます）、電話番号、メールアドレス、生年月日及び性別等。 <br/><br/>
					&emsp; (2) 利用端末に関する情報：使用デバイス、OS、端末言語設定、アクセス国等。 <br/><br/>
					&emsp; (3) 通信環境に関する情報：通信キャリア、通信環境、アクセス地域等。 <br/><br/>
					&emsp; (4) 利用状況に関する情報：本サービスのバージョン、本サービスの利用履歴等。 <br/><br/>
					2. 当社は、利用者情報を保存・蓄積した場合、当社のプライバシーポリシーに基づき、善良な管理者の注意義務に従い取り扱うとともに、次の各号の目的で使用します。 <br/><br/>
					&emsp; (1) 本サービスの運営（当社から利用者に対して、あらゆる情報を提供することを含みます）。 <br/><br/>
					&emsp; (2) 当社が利用者にとって有益だと判断する当社のサービス（本サービスに限りません）または広告主企業や提携先企業の商品、サービス等に関する情報の提供。 <br/><br/>
					&emsp; (3) 利用者に対する、本サービスの運営に著しく影響を及ぼす事柄（本サービス内容の大幅な変更、一時停止を含みますがこれらに限られません）に関する連絡。 <br/><br/>
					&emsp; (4) 利用者に対する、利用者情報の取扱いに関する同意を求めるための連絡。 <br/><br/>
					&emsp; (5) 本サービスの利用状況等に係る統計情報の作成、本サービスまたは当社ウェブサイト等における公表及び第三者への販売その他の提供。 <br/><br/>
					&emsp; (6) 利用者の年齢、職業、性別、趣味・嗜好等に合わせた広告その他の情報の提供。 <br/><br/>
					&emsp; (7) 本サービスに関するマーケティング分析をはじめ、研究開発全般での利用 <br/><br/>
					3. 当社は、利用者情報について、次の各号の場合を除き、本人以外の第三者に開示しないものとします。 <br/><br/>
					&emsp; (1)本サービスの向上、関連事業開発、研究開発及び提携企業のマーケティング等の目的で利用者情報を集計及び分析等する場合 <br/><br/>
					&emsp; (2)前号の集計及び分析等により得られたものを、個人を識別又は特定できない態様にて、第三者に開示又は提供する場合 <br/><br/>
					&emsp; (3)個人情報の開示や利用について利用者の同意がある場合 <br/><br/>
					&emsp; (4)利用者が希望するサービスを提供するために、個人情報の開示や利用が必要と認められる場合 <br/><br/>
					&emsp; (5)利用者が希望するサービスを提供する目的で、当社との間で契約を締結した提携先企業等の第三者（以下、「パートナー」といいます）が個人情報を必要とする場合（なお、パートナーは、当社が提供した個人情報をサービス提供のために必要な範囲を超えて利用することはできません） <br/><br/>
					&emsp; (6)当社がサービスとして提供するアンケート等への回答を依頼された利用者が、回答情報として当社に提供した文字、数字、静止画、動画像その他の情報を第三者に提供する場合（この場合、当社の情報提供先は当該アンケートの発注元及びパートナーに限るものとし、利用者個人が特定できない形式での提供となります） <br/><br/>
					&emsp; (7)当社又はパートナーの広告、サービス等に関する情報を、利用者に送信する場合 <br/><br/>
					&emsp; (8)法令に基づく場合 <br/><br/>
					&emsp; (9)当社、利用者その他第三者の生命、身体もしくは財産、又は当社が提供する一切のサービスの保護のために必要がある場合 <br/><br/>
					&emsp; (10)公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合 <br/><br/>
					&emsp; (11)国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合、これらの者から正当に開示を求められた場合 <br/><br/>
					4. 当社は、利用者情報及び利用者コンテンツの保存義務を負わず、これらの完全性、可用性について保証しません。利用者は、利用者情報及び利用者コンテンツを保全したい場合、自らの責任によりバックアップをとるものとします。 <br/><br/>
					5.当社は、利用者が本サービスを退会し、または当社が本サービスの運営を終了したときは、当社の判断により、利用者情報及び利用者コンテンツを全て消去できるものとし、その返却等の要望には応じません。<br/><br/>
					<b>第5条. (料金及び費用)</b><br/><br/>
					1. 当社は、利用者に対して、利用者の選択によって追加または拡張できる機能の全部または一部を有料とし、その他有料のサービス（本サービス内で利用できるポイント、アイテム等の販売等を含みますが、これらに限定されません）を提供します。 <br/><br/>
					2. 当社は、本サービスにおいて有料または無料とされている機能について、利用者が申し込んだ際に提示された価格表に基づいて合意した料金で提供します。当社の判断により、その価格を変更する場合があります。利用者は、当該変更に承諾しない場合、本サービスの全部または一部を利用できなくなる場合があることについて、予め承諾するものとします。 <br/><br/>
					3. 本サービスの利用にあたって必要な端末の購入、導入または維持にかかる費用、パケット料、データ利用料などの通信料その他の一切の費用は、利用者の負担とします。<br/><br/>
					<b>第6条. (禁止事項)</b><br/><br/>
					利用者は、本サービスの利用にあたり、次の各号の行為またはそのおそれがある行為を行ってはならないものとします。 <br/><br/>
					&emsp; (1) 法令、公序良俗または本規約に違反する行為 <br/><br/>
					&emsp; (2) 当社または第三者の著作権その他の知的財産権、肖像権及びパブリシティ権その他の人格権ならびに所有権その他の財産権を侵害する行為 <br/><br/>
					&emsp; (3) 当社または第三者を不当に差別しまたは誹謗中傷する行為、当社または本サービスの名誉・信用を毀損する行為 <br/><br/>
					&emsp; (4) 第三者へのいやがらせ、わいせつ、悪口、嫌悪感を与える表現や内容を含む一切の利用者コンテンツを投稿し、書き込みし、またはアップロードする行為 <br/><br/>
					&emsp; (5) アダルトサイト、出会い系サイト等へのリンクを掲載する行為、異性との出会いを希望または誘導することを目的とする行為 <br/><br/>
					&emsp; (6) 氏名、名称、電話番号、メールアドレス、住所など特定の個人を特定できる情報、または企業、団体などの非公開情報、営業秘密、秘密情報を、本人または権利者の許可なく取得、開示、改ざんもしくは漏洩させる行為 <br/><br/>
					&emsp; (7) 第三者になりすまし、その他虚偽の情報を登録及び掲載する行為 <br/><br/>
					&emsp; (8) 他の利用者につきまとい、執拗に追いかける行為など、他の利用者の意思に反する一切の行為 <br/><br/>
					&emsp; (9) 不正アクセス、または他人であるように振る舞うなどの誤解を与える行為 <br/><br/>
					&emsp; (10) 反社会的勢力に対する利益供与その他の協力行為 <br/><br/>
					&emsp; (11) ねずみ講、マルチ商法、ネットワークビジネスその他連鎖講に類する取引の宣伝・告知・勧誘及びその取引行為 <br/><br/>
					&emsp; (12) 当社の承認を得ずに行われる、営利を目的とする行為 <br/><br/>
					&emsp; (13) 宗教活動及び政治活動並びにその宣伝・告知・勧誘 <br/><br/>
					&emsp; (14) 当社の承認を得ずに行われる、同一端末において複数の利用者アカウントを保有する行為 <br/><br/>
					&emsp; (15) 利用者アカウントを、複数人で共有する行為または複数端末で共有する行為 <br/><br/>
					&emsp; (16) 本サービスを運営するためのサーバーまたはネットワークに著しく負荷をかける行為 <br/><br/>
					&emsp; (17) 本サービスを改変、毀損し、または逆アセンブル、逆コンパイル、リバースエンジニアリングする行為 <br/><br/>
					&emsp; (18) 当社が本サービスを通じて提供する各種コンテンツ（本サービス内で利用できるポイント、アイテム等を含みますが、これらに限定されません。以下、「サービス内コンテンツ」といいます）を不正な方法で取得する行為 <br/><br/>
					&emsp; (19) 本サービスのサーバーやネットワークシステムに支障を与える行為、BOT、チートツール、その他の技術的手段を利用してサービスを不正に操作する行為 <br/><br/>
					&emsp; (20) 本サービスの不具合（プログラムの不具合、人為的ミス等による誤表示、誤作動等を含みますが、これらに限られません）を利用する行為 <br/><br/>
					&emsp; (21) 利用者アカウント、サービス内コンテンツその他本サービスにおいて利用者が利用可能な権利または利益の全部または一部を、現金その他の財産上の利益を対価として第三者に譲渡しまたは利用させる行為、ならびにその宣伝・告知・勧誘 <br/><br/>
					&emsp; (22)当社または第三者に対して迷惑、損害を与える行為 <br/><br/>
					&emsp; (23) 類似または同様のお問合せを必要以上に繰り返す等、当社に対し不合理な要求をする行為 <br/><br/>
					&emsp; (24) 当社による本サービスの運営を妨げる行為 <br/><br/>
					&emsp; (25) 上記（1）から（24）までのいずれかの行為に該当する行為を援助または助長する行為 <br/><br/>
					&emsp; (26) その他当社が不適切と判断する行為 <br/><br/>
					<b>第7条. (免責)</b><br/><br/>
					1. 当社は、本サービスが第三者の権利を侵害していないこと、及び本サービスを実行することが第三者の権利を侵害しないことを保証しません。 <br/><br/>
					2. 利用者は、自己の責任と判断において本サービスを利用するものとします。当社は、本サービスの精度、完成度、正確性、妥当性、適用性、有用性及び可用性について保証せず、ならびに利用者の使用目的及び使用する端末への適合性について保証しません。 <br/><br/>
					3. 当社は、利用者が本サービスを通じて行う取引には一切関知しないものとし、本サービスを通じたいかなる情報の正確性も保証しません。利用者は、他の利用者との間で生じた一切の紛争については、自己の責任と判断において解決を図るものとします。 <br/><br/>
					4. 地震、津波、台風、雷、大雨、洪水などの天災、戦争、争議、革命、労働争議などの不可抗力、本サービス運営において必要なシステム等の一時的または大規模なメンテナンス、ネットワーク回線の混雑、プロバイダー等の障害、その他本サービスの運営にかかる障害等により、一時的または長期にわたって本サービスの利用ができなくなる場合、または当社が本サービスそのものの利用を停止しもしくは終了させる場合があります。当社は、利用者に対し、これらの事象に関して責任を負いません。 <br/><br/>
					5. 当社は、利用者に対し、本サービスに対する不正アクセス、コンピューターウイルスの侵入など、第三者の行為を原因として利用者に生じる一切の損害について責任を負いません。 <br/><br/>
					6. 当社は、本サービスに関連して発生した利用者または第三者の損害について責任を負いません。 <br/><br/>
					7. 当社は、本サービス上で実施される広告の広告主等と利用者との間におけるあらゆるトラブル、約束、保証等及びその結果として利用者に生じる一切の損失・損害について責任を負いません。 <br/><br/>
					8. 当社は、本サービスからリンクにより遷移可能な一切の第三者ウェブサイトについて、その適法性、整合性、安全性、正確性、公序良俗に反しないものであることなどを保証しません。 <br/><br/>
					9. 当社は、本サービスに関する利用者からの問い合わせや改善等の意見その他の提案、フィードバック等について、これらの全てに回答をし、または何らかの対応を実施する義務を負わず、自らの裁量及び任意の判断でこれらを実施するものとします。当社は、利用者からの問い合わせ、意見、提案、フィードバック等の内容に対して何らかの対応を実施することがありますが、当社による当該行為は、当該利用者に対し、個別に回答や報告、金銭的な補償などを行う義務を負うものとは解釈されません。<br/><br/>
					<b>第8条. (端末及びアカウントの管理)</b><br/><br/>
					1. 利用者は、本サービスの利用にあたり、その利用に用いる自身の端末及び利用者登録によって取得した自らのアカウント（以下、「利用者アカウント」といいます）を、自らの責任において管理するものとします。 <br/><br/>
					2. 利用者は、自身の端末または利用者アカウント上を利用してなされた行為について、当該端末またはアカウントを現実に利用する者が誰であるかを問わず一切の責任を負うものとし、当該行為により当社または第三者が損害を被った場合、その損害を賠償するものとします。 <br/><br/>
					3. 当社は、利用者の使用する端末の故障、紛失、第三者からのマルウェア、スパイウェア、ウイルス、ハッキング等による攻撃、利用者アカウントの管理不備、使用上の過誤または第三者の使用等により、利用者が損害を被った場合でも、責任を負いません。 <br/><br/>
					4. 利用者は、自身の利用者アカウントが不正に取得され、または端末もしくは利用者アカウントが第三者により利用されるおそれがある場合は、速やかに退会処理をし、または当社に報告をするなど、当社または第三者に損害が発生することを避けるために合理的に必要と考えられるあらゆる措置をとるものとします。<br/><br/>
					<b>第9条　ID及びパスワードの管理</b><br/><br/>
					1. 利用者は、利用者登録等の際に利用者自身で設定したユーザーID及びパスワードを厳重に管理・保管する責任を負うものとします。<br/><br/>
					2. 利用者は、当社の事前の同意がある場合を除き、ユーザーID及びパスワードを第三者に譲渡、売買、承継、貸与、開示又は漏洩等をしてはならないものとします。<br/><br/>
					3. 利用者は、ユーザーID又はパスワードが第三者によって不正に使用されていること又はそのおそれのあることが判明した場合には、直ちに当社に連絡するものとします。<br/><br/>
					4. 利用者は、ユーザーID又はパスワードの管理不十分、使用上の過誤・不手際、第三者の無断使用等に起因する損害につき自ら責任を負うものとし、当社は一切責任を負担しないものとします。<br/><br/>
					<b>第10条　登録内容の変更</b><br/><br/>
					1. 利用者は、当社に届出ている事項の全部又は一部に変更が生じた場合には、当社が別途指定する方法により、すみやかに登録内容を変更するものとします。かかる変更を行わなかった場合には、既に登録済みの事項に基づく当社の業務遂行は適正かつ有効なものであるとみなされるものとします。<br/><br/>
					2. 当社は、利用者が適宜、変更登録を行わなかったことにより何らかの損害が生じたとしても、一切責任を負わないものとします。<br/><br/>
					<b>第11条. (アカウントの停止等)</b><br/><br/>
					当社は、利用者が次の各号のいずれかに該当する場合、本サービスの一部または全部の提供を停止し、利用者アカウントの利用を停止及び削除し、または退会させ、その他当社が適当と考える措置をとることができるものとします。当社は、当該措置についてその理由を開示する義務を負わないものとし、利用者は、当社に対し、当該措置について異議を申し立てることはできないものとします。 なお、利用停止や利用者登録抹消措置がとられた場合でも、当該措置の対象となった利用者は、本サービスの利用により既に発生した支払義務等の本規約上の責任を免れるものではありません。<br/><br/>
					&emsp; (1) 過去に当社の提供する他の規約に違反をしたこと等により、利用者登録の抹消等の処分を受けていたことが判明した場合<br/><br/>
					&emsp; (2) 登録された内容に虚偽の事項が含まれていることが判明した場合（利用者登録時に届出た利用者の所在地が、当社が指定する転送サービス会社の住所であった場合も含みこれに限られないものとします。）<br/><br/>
					&emsp; (3) 過去に当社の提供する他のサービスに関して、正当な理由なく、料金等の支払債務の履行遅延、長期間に亘る受取り不能、返品・交換の拒絶その他の債務不履行があったことが判明した場合<br/><br/>
					&emsp; (4)利用者が、反社会的勢力（暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者をいいます）であった場合、又は暴力的行為、詐術・脅迫行為、業務妨害行為等違法行為を行った場合<br/><br/>
					&emsp; (5)理由のいかんを問わず、利用者の行為（本サービス外の行為及び不作為を含みます）によって、本サービスの運営に支障が生じ、または妨害がされたと当社が判断した場合、またはそのおそれのあると当社が判断した場合。 <br/><br/>
					&emsp; (6) 本規約その他当社が定める規約（本規約を含みますが、これに限りません）に規定する禁止行為を行う等の違反があった場合またはそのおそれがあると当社が判断した場合<br/><br/>
					&emsp; (7) その他本サービスの利用者として不適当と当社が判断した場合。<br/><br/>
					<b>第12条. (権利義務等の譲渡禁止)</b><br/><br/>
					利用者は、本規約に基づく一切の権利または義務について、第三者への譲渡、承継、質入その他一切の処分をしてはならないものとします。<br/><br/>
					<b>第13条. (退会)</b><br/><br/>
					1. 利用者は、本サービスを退会するときは、当該時点において当社に対して有している一切の債務を、直ちに履行するものとします。 <br/><br/>
					2. 利用者が、本サービスの利用に際して用いる端末について、修理または機種変更等によりこれを交換したときは、当社が特に定める場合を除き、当該新たな端末において、端末を交換する以前の登録情報等の引継ぎ等はできないものとします。<br/><br/>
					<b>第14条. (損害賠償)</b><br/><br/>
					利用者は、本規約に違反したことにより、当社または第三者に損害（合理的な弁護士費用を含みますがこれに限られません）を与えた場合、当社または第三者に対し、その損害を賠償するものとします。<br/><br/>
					<b>第15条. (準拠法及び裁判管轄)</b><br/><br/>
					1. 本規約は、日本法に準拠し、日本法に従って解釈されるものとします。 <br/><br/>
					2. 本サービス、本規約に関する一切の訴訟その他の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。<br/><br/>
	
					2021年6月4日改訂<br/><br/>
					2021年5月25日改訂<br/><br/>
					2021年5月14日改訂<br/><br/>
					2021年1月15日制定
				</p>)
			}
		</Box>
	)
}