import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumbs,
  Divider,
  Link,
  Typography
} from '@material-ui/core'
import Loader from 'src/components/loader';
import api from 'src/utilities/axios';
import ClientForm from './client-form';


export function FormsComponent() {
	const { t } = useTranslation();
	
	const [data, setData] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<JSX.Element>(null);

	function fetchData(): void {
		setLoading(true);
		setMessage(null);

		api({
			pathname: '/user/forms',
			method: 'GET',
			handleResponse: (response: any) => {
				setData(response.data);
				setLoading(false);
			},
			handleError: (error: any) => {
				setLoading(false);
			}
		})
	}



	React.useEffect(fetchData, []);
	return (
		<Loader state={loading}>
			<Typography variant='h3' sx={{ mb: 1 }}>
				{t('navigation.forms')}
			</Typography>
			<Breadcrumbs aria-label='breadcrumb'>
				<Link component={RouterLink} color='inherit' to='/client'>
					{t('navigation.dashboard')}
				</Link>
				<Typography color='text.primary'>
					{t('navigation.forms')}
				</Typography>
			</Breadcrumbs>

			<Divider sx={{ my: 3 }} />

			{message}

			<ClientForm data={data} />
		</Loader>
	)
}