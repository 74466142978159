import React from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';

import EditSharpIcon from '@material-ui/icons/EditSharp';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';

import { stableSort, getComparator } from 'src/utilities/data-table';

function DataTable(props: any) {
	const { t } = useTranslation();
	const { types, editDialog, deleteDialog, data } = props;
	
	const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
	const [orderBy, setOrderBy] = React.useState<string>('name');
	const [page, setPage] = React.useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	
	const headCells: any = [
		{ id: 'text', align: 'left', disablePadding: false, label: 'Text' },
		{ id: 'id', align: 'left', disablePadding: false, label: 'Question Type' },
		{ id: 'email', align: 'right', disablePadding: false, label: t('table.actions') },
	];

	function handleRequestSort(event: any, property: string): void {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void {
		setPage(newPage);
	};

	function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	function handleEditRequest(data: any): void {
		editDialog.setState(true);
		editDialog.setData(data);
	}

	function handleDeleteRequest(data: any): void {
		deleteDialog.setState(true);
		deleteDialog.setData(data);
	}

	return (
		<Card sx={{ boxShadow: 6 }}>
			<Grid container justifyContent='space-between'>
				<Grid item sx={{ px: 2, py: 3 }}>
					<Typography variant='h6' display='inline'>
						{t('navigation.questions')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									{headCells.map((headCell: any) => (
										<TableCell
											key={headCell.id}
											align={headCell.align}
											sortDirection={orderBy === headCell.id ? order : false}
										>
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={orderBy === headCell.id ? order : 'asc'}
												onClick={(event) => handleRequestSort(event, headCell.id)}
											>
												<Box pr={1.5}>{headCell.label}</Box>
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{stableSort(data, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((item, index) => {
										const type = types.find((type: any) => type.id === item.question_type_id);

										return (
											<TableRow hover role='checkbox'	tabIndex={-1} key={index + 1}>
												<TableCell align='left'>{item.text}</TableCell>
												<TableCell align='left'>{type && type.name}</TableCell>
												<TableCell align='right'>
													<Tooltip title={t('form.edit')}>
														<IconButton onClick={() => handleEditRequest(item)}>
															<EditSharpIcon />
														</IconButton>
													</Tooltip>
													<Tooltip title={t('form.delete')}>
														<IconButton	onClick={() => handleDeleteRequest(item)}>
															<DeleteSharpIcon />
														</IconButton>
													</Tooltip>
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										count={data.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</TableRow>
							</TableFooter>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</Card>
	);
}

export default DataTable;
