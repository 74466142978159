import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

function Dashboard(): JSX.Element {
	const { t } = useTranslation();

	return (
		<Grid container alignContent='center' alignItems='center' justifyContent='center'>
			<Grid item xs={12} md={6} lg={6} xl={6}>
				<Box component={Paper} textAlign='center' p={3}>
					<img src='/static/img/brands/ResQ.jpg' width='40%' />

					<br/>
					<br/>

					<Typography variant='h4' gutterBottom>
						{t('common.welcome')}
					</Typography>
					<Typography variant='h5' gutterBottom>
						{t('common.version')}
					</Typography>

					<br/>

					<Button
						component='a'
						href='https://ad-dice.com/healthcare-ai/'
						fullWidth
						variant='contained'
						color='primary'
						sx={{ mb: 2 }}
					>
						{t('common.about')}
					</Button>
					<Button
						component='a'
						href='https://ad-dice.com/cfthanks-name/'
						fullWidth
						variant='contained'
						color='primary'
					>
						{t('common.supporter')}
					</Button>
				</Box>
			</Grid>
		</Grid>
	)
}

export default Dashboard
