import React, { useState, useEffect, useRef } from 'react'
import { Link as RouterLink } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import {
    DragDropContext,
    Droppable,
    Draggable
  } from "react-beautiful-dnd";
import {useNavigate} from 'react-router-dom'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import Tab from '@material-ui/core/Tab';
import TabList from '@material-ui/lab/TabList';
import Box from '@material-ui/core/Box';
import Stack from '@material-ui/core/Stack';
import Radio from '@material-ui/core/Radio';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import NativeSelect from '@material-ui/core/NativeSelect';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AddFormDialog from '../components/add-form-dialog';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import api from 'src/utilities/axios';
import LoadingButton from '@material-ui/lab/LoadingButton';
import UniversalCookie from 'universal-cookie';
import { Grid, Typography, Divider, Breadcrumbs, Link, Button } from '@material-ui/core';


export default function CreateForm(): JSX.Element {
    const { t } = useTranslation();
    const ariaLabel = { 'aria-label': 'description' };
    const bottomRef = useRef(null);
    const [isDefault, setIsDefault] = useState<boolean>(false);
    const [types, setTypes] = React.useState<any[]>([]);
    const [render, setRender] = React.useState<boolean>(false);
    const [value, setValue] = React.useState('1');
    const [questions, setQuestions] = React.useState<any[]>([]);
    const [dummy, setDummy] = React.useState<any[]>([]);
    const [name, setName] = React.useState<any>('');
    const [saveLoading, setSaveLoading] = React.useState<boolean>(false);
    const [description, setDescription] = React.useState<any>('');
    const formRefCreate = React.useRef();
    const cookie = new UniversalCookie();
    const navigate = useNavigate();


    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
      };
    
    function handleSave(event: React.SyntheticEvent, formRef:any): void {
		event.preventDefault();
		event.persist();

		setSaveLoading(true);
        if (formRef?.current){
            if (formRef.current.reportValidity()){
		
            api({
                pathname: '/staff/' + cookie.get('workspace') + '/forms',
                method: 'POST',
                
                data: {
                    questions: questions,
                    is_editable: true,
                    is_default:isDefault,
                    name:name,
                    description:description,
                    users:[]

                },
                handleResponse: (response:any): void => {
                    setSaveLoading(false);
                    updateRender();
                    if (response){navigate('/staff/form');}	

                },
                handleError: (): void => {
                    setSaveLoading(false);
                }
            })} else{setSaveLoading(false);}}
            else{
                api({
                    pathname: '/staff/' + cookie.get('workspace') + '/forms',
                    method: 'POST',
                    
                    data: {
                        questions: questions,
                        is_editable: true,
                        is_default:isDefault,
                        name:name,
                        description:description,
                        users:[]
    
                    },
                    handleResponse: (response:any): void => {
                        setSaveLoading(false);
                        updateRender();
                        if (response){navigate('/staff/form');}	
    
                    },
                    handleError: (): void => {
                        setSaveLoading(false);
                    }
                })

            }
	}

    const handleSwitch = (index) => {
        const questionsSwitch = questions
        if(questionsSwitch[index.toString()].is_required){
            
            questionsSwitch[index.toString()].is_required = false
            setQuestions(questionsSwitch);
            setQuestions(currentRows=> [...currentRows])

        }
        else{
            questionsSwitch[index.toString()].is_required = true
            setQuestions(questionsSwitch);
            setQuestions(currentRows=> [...currentRows])
        }
    };

    const handleDefaultSwitch = (event: React.SyntheticEvent)=> {
        if(isDefault){
            setIsDefault(false);
        }
        else{
            setIsDefault(true);
        }
    };

    const onDragEnd = result => {
		const { destination, source, draggableId } = result;
		if (!destination) {
		  return;
		}
	
		if (
		  destination.droppableId === source.droppableId &&
		  destination.index === source.index
		) {
		  return;
		}
	
		const columna = questions.find(o=>o.id=== Number(draggableId));

        const copyToDos = questions;
        copyToDos.splice(source.index, 1);
        copyToDos.splice(destination.index, 0, columna);

        setQuestions(copyToDos);

	  };
    
    useEffect(() => {
	  }, [questions])

    const fetchTypesData = () => {
        api({
            pathname: '/staff/question_types',
            method: 'GET',
            handleResponse: (response: any): void => {
                setTypes(response.data);
            },
            handleError: (): void => {
            }
        })
    }

    useEffect(fetchTypesData, []);
    useEffect(() => {
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
      }, [dummy.length]);

    
    const addQuestion = () => {
        if (questions.length){
            setQuestions(currentRows=> [...currentRows,{id: Math.max(...questions.map(o => o.id))+1,question_type:1,text:'', is_required:false, question_options:[{'order':0,value:''}]}]);
            setDummy(currentRows=>[...currentRows,''])
        }
        else{
            setQuestions([{id:0,question_type:1,text:'', is_required:false, question_options:[{'order':0,value:''}]}]);
            setDummy(currentRows=>[...currentRows,''])
        }
        
        
    }


    const addQuestionOption = (index:any) => (event) => {
        event.stopPropagation();
        const newQuestionOptions = questions
        if (newQuestionOptions[index].question_options.length){
            newQuestionOptions[index].question_options.push({order:Math.max(...newQuestionOptions[index].question_options.map(o => o.order))+1, value:''});
        }
        else{
            newQuestionOptions[index].question_options.push({order:0, value:''});
        }
        
        setQuestions(newQuestionOptions);
        setQuestions(currentRows=> [...currentRows]);
        
    }

    const updateRender = (): void => setRender(!render);


    const handleInputChange = e => {

        const newQuestions = questions
        newQuestions[e.target.id].text = e.target.value 
        setQuestions(newQuestions);
      };

    const handleNameChange = e =>{
        setName(e.target.value);
      };

    const handleDescriptionChange = e =>{
        setDescription(e.target.value);
      };
    
    const handleOptionsInputChange = (e, index, o_index) => {
        const newQuestionsWithOptions = questions
        newQuestionsWithOptions[index.toString()].question_options[o_index.toString()].value = e.target.value
        setQuestions(newQuestionsWithOptions);
      };

    const handleSelectChange = e => {        
        const newQuestionsSelect = questions
       
        newQuestionsSelect[e.target.id].question_type = Number(e.target.value)
        setQuestions(newQuestionsSelect);
        setQuestions(currentRows=> [...currentRows]);
      };

   const deleteQuestion = (i:number) => (event) => {
    event.stopPropagation();
    setQuestions(questions.filter((_, index) => index !== i));
    
}
    const deleteQuestionOption = (o_index, index) => (event) => {
        
        const deleteQuestionsWithOptions = [...questions]

        deleteQuestionsWithOptions[index].question_options = deleteQuestionsWithOptions[index].question_options.filter((_, index) => index !== o_index)

        setQuestions(deleteQuestionsWithOptions);
        
    }

    return (
        <div>
            <Grid container justifyContent='space-between' spacing={3}>
                <Grid item>
                    <Typography variant='h3' sx={{ mb: 1 }}>
                        {t('form.create_form')}
                    </Typography>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link component={RouterLink} color='inherit' to='/staff'>
                            {t('navigation.dashboard')}
                        </Link>
                        <Link component={RouterLink} color='inherit' to='/staff/form'>
                            {t('navigation.forms')}
                        </Link>
                        <Typography color='text.primary'>
                            {t('form.create_form')}
                        </Typography>
                    </Breadcrumbs>
                    
                </Grid>
                <Grid item>
                <Stack spacing={2} direction="row">
                    <LoadingButton variant='contained' loading={saveLoading} color='primary' onClick={(e)=> handleSave(e, formRefCreate)}>
                        {t('form.save')}
                    </LoadingButton>
					<AddFormDialog updateRender={updateRender} questions={questions} is_default={isDefault} name={name} description={description} formRef={formRefCreate}/>
                </Stack>
                </Grid>
            </Grid>
            <TabContext value={value}>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} centered aria-label="lab API tabs example">
                <Tab disableRipple label={t('navigation.questions')} value="1" />
                <Tab disableRipple label={t('navigation.answers')} value="2" />
                <Tab disableRipple label={t('navigation.settings')} value="3" />
            </TabList>
            </Box>
            <TabPanel value="1">
            <form ref={formRefCreate}>
                <Stack alignItems="center" justifyContent="center" spacing={3} >
                <Grid container justifyContent="center">

                <Box
                
                sx={{
                    '& > :not(style)': { m: 5, width: '93ch' },
                    maxWidth:"100ch",
                    minWidth:"30ch",
                    flexWrap: 'wrap',
                    backgroundColor: '#FFFFFF',
                    border: 1,
                    borderColor: 'grey.300', 
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '16px',
                }}

                >
                    <Stack  spacing={3}>
                    
                        <TextField fullWidth required={true} id="standard-basic" placeholder={t("form.form_name")} InputProps={{ style: { fontSize: 27 } }} onChange={e => handleNameChange(e)} defaultValue={name} variant="standard" onKeyPress={(event) => { if (event.key === 'Enter') event.preventDefault()}}/>
                        <TextField multiline required={true} rows={4} id="standard-basic" placeholder={t("form.form_description")} onChange={e => handleDescriptionChange(e)} defaultValue={description} variant="standard" />
                    </Stack>
                </Box>
                </Grid>
                
                <DragDropContext onDragEnd={onDragEnd} key={'context-key'}>
                <Droppable droppableId={"mainPage"} key={'droppable-key'}>
                {(provided, snapshot) => (
                            <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            key={'div-key'}
                            >
                <Stack  spacing={3} key={'stack-key'}>
                {questions.map((item: any, index: number) => (
                    <Draggable
                    key={'key-'+item.id.toString()}
                    draggableId={item.id.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                        <Box
                        key={'box-'+index.toString()}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                            '& > :not(style)': { mt: 5, mx: 5, mb: 2, width: '93ch' },
                            maxWidth:"100ch",
                            minWidth:"30ch",
                            flexWrap: 'wrap',
                            backgroundColor: '#FFFFFF',
                            border: 1,
                            borderColor: 'grey.300',
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: '16px',
                        }}
                        >
                            <Grid container spacing={2} justifyContent="flex-end" ref={bottomRef}>
                                <Grid item xs={8} >
                                    <Stack spacing={2}>
                                    
                                    <TextField fullWidth required={true} id={index.toString()} placeholder={t("form.question_text")} defaultValue={item.text} onChange={e => handleInputChange(e)} variant="standard" />
                                    <Grid item xs={12}></Grid>
                                    {item.question_type == 1?(<Input fullWidth disabled value="Text Answer Field" inputProps={ariaLabel} />
                                    ):(item.question_type == 4?(
                                    <Stack spacing={1}>
                                        {item.question_options.map((it: any, o_index: number) => (
                                        
                                            <Grid item key={'grid4-'+o_index.toString()} xs={12}><Checkbox disabled /><TextField required={true} id={o_index.toString()} placeholder={"Answer Option"} key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={it.value} onChange={e => handleOptionsInputChange(e, index, o_index)}/><IconButton color="primary" onClick={deleteQuestionOption(o_index, index)}><CloseIcon fontSize="small" /></IconButton></Grid>
                                        
                                        ))}
                                        <Grid item xs={12}>
                                            <IconButton color="primary" onClick={addQuestionOption(index)}>
                                                <AddIcon fontSize="small" />
                                            </IconButton>
                                        </Grid>
                                    </Stack>
                                        
                                    ):(item.question_type == 3?(<Stack spacing={1}>
                                        {item.question_options.map((it: any, o_index: number) => (
                                        
                                            <Grid item key={'grid3-'+o_index.toString()} xs={12}> <Radio disabled/><TextField required={true} id={o_index.toString()} placeholder={"Answer Option"} key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={it.value} onChange={e => handleOptionsInputChange(e, index, o_index)}/><IconButton color="primary" onClick={deleteQuestionOption(o_index, index)}><CloseIcon fontSize="small" /></IconButton></Grid>
                                        
                                        ))}
                                        <Grid item xs={12}>
                                            <IconButton color="primary" onClick={addQuestionOption(index)}>
                                                <AddIcon fontSize="small" />
                                            </IconButton>
                                        </Grid>
                                    </Stack>):(item.question_type == 2?(<Input fullWidth disabled value="Number Answer Field"  />
                                    ):(item.question_type == 5?(<Input disabled value="Day/Month/Year" endAdornment={
                                        <InputAdornment position="start">
                                          <EventIcon />
                                        </InputAdornment>
                                      } inputProps={ariaLabel} />):(item.question_type == 6?(<Input disabled value="Time" endAdornment={
                                        <InputAdornment position="start">
                                          <AccessTimeIcon  />
                                        </InputAdornment>
                                      } inputProps={ariaLabel} />):(<Input disabled value="Day/Month/Year Hour:Minutes" endAdornment={
                                        <InputAdornment position="start">
                                          <EventIcon />
                                        </InputAdornment>
                                      } inputProps={ariaLabel} />))))))}
                                    </Stack>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl fullWidth>
 
                                    <NativeSelect
                                        defaultValue={item.question_type}
                                        id= {index.toString()}
                                        inputProps={{
                                            name: 'age',
                                            id: index.toString(),
                                        }}
                                        onChange={e => handleSelectChange(e)}
                                        >
                                        {types.map((item: any, i: number) => {
                                            return (
                                                <option key={'option-'+i.toString()} value={item.id}>{t("form.input_type."+item.name)}</option>
                                            )
                                        })}
                                        </NativeSelect>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}>
                                    <Divider variant="middle" />
                                </Grid>
                                <Grid item xs={3} >
                                    <IconButton aria-label="delete" onClick={deleteQuestion(index)} size="large">
                                        <DeleteIcon />
                                    </IconButton>
                                    <FormControlLabel control={<Switch checked={item.is_required} onClick={()=>handleSwitch(index)}/>} label={t("form.required")} />
                                </Grid>
                            </Grid>
                        </Box>

                        )}
                        </Draggable>
                        
                    ))}
                    {provided.placeholder}
                    </Stack>
                    </div>)}
                    
                </Droppable>
                

                </DragDropContext>
                
                <Button variant="outlined" color='primary' type='submit' startIcon={<AddIcon />} onClick={addQuestion}>
                    {t("form.add_question")}
                </Button>                
                </Stack>
                
                </form>
            </TabPanel>
            <TabPanel value="2">
                <Stack alignItems="center" justifyContent="center" spacing={6}>
                <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 5, width: '93ch' },
                    maxWidth:"100ch",
                    minWidth:"30ch",
                    flexWrap: 'wrap',
                    backgroundColor: '#FFFFFF',
                    border: 1,
                    borderColor: 'grey.300', 
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '16px',
                }}
                noValidate
                autoComplete="off"
                >
                    <Typography variant="h3"> {t("form.no_response")} </Typography>
                </Box>
                </Stack>
                

            </TabPanel>
            <TabPanel value="3">
                <Stack alignItems="center" justifyContent="center" spacing={2}>
                <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 5, width: '93ch' },
                    maxWidth:"100ch",
                    minWidth:"30ch",
                    flexWrap: 'wrap',
                    backgroundColor: '#FFFFFF',
                    border: 1,
                    borderColor: 'grey.300', 
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '16px',
                }}
                noValidate
                autoComplete="off"
                >
                    <Grid container spacing={{xs: 1}} columns={{xs: 2}}>
                    <Grid item xs={12}>
                    <Typography variant="h3"> {t("form.form_settings")} </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <FormControlLabel control={<Switch checked={isDefault} onClick={handleDefaultSwitch}/>} label={t("form.make_to_default_form")} />
                    <FormHelperText>{t("form.make_to_default_form_desc")}</FormHelperText>
                    </Grid>
                    </Grid>
                </Box>
                
            
                
                </Stack>
                

            </TabPanel>
            </TabContext>
        </div>
    )
}