import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { TextField as RootTextField, TextFieldProps, Stack, StackProps, Card, Typography, Tooltip, IconButton, Button, ButtonProps, Box } from "@material-ui/core";

import { HelpOutline } from "@material-ui/icons"

import ReferenceBox from "../reference-box";

interface SubmitButtonProps extends ButtonProps {
    isLocked: boolean;
}

export const SubmitButton = ({ isLocked, children, ...props }: SubmitButtonProps) => {
    const { t } = useTranslation();
    return (
        <Box
            component="span"
            sx={{
                cursor: isLocked ? "not-allowed" : "pointer"
            }}
        >
            <Button
                type="submit"
                variant="contained"
                disabled={isLocked}
                {...props}
            >
                {children}
            </Button>
        </Box>
    )
}

export const TextField = ({ children, ...props }: TextFieldProps) => {
    return (
        <RootTextField
            sx={{
                "&.Mui-disabled": {
                    color: "text.primary",
                }
            }}
            inputProps={{
                sx: {
                    "&.Mui-disabled": {
                        color: "text.primary",
                        WebkitTextFillColor: "inherit"
                    }
                }
            }}
            size='small'
            fullWidth
            {...props}
        >
            {children}
        </RootTextField>
    )
}

interface FormStackProps extends StackProps<"form"> {
    title?: string;
    referenceData?: ReactNode[];
    tooltipText?: string;
}

export const FormStack = ({ title, children, referenceData, tooltipText, ...props }: FormStackProps) => {
    return (
        <Stack component={Card} direction="column" spacing={2} sx={{ boxShadow: 6, p: 2, mb: 3 }}>
            <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
                {
                    title && (
                        <Typography variant="h3">
                            {title}
                        </Typography>
                    )
                }
                {
                    tooltipText && (
                        <Tooltip title={tooltipText}>
                            <IconButton type="button">
                                <HelpOutline />
                            </IconButton>
                        </Tooltip>
                    )
                }
            </Stack>



            <Stack
                component="form"
                spacing={1}
                direction={{
                    xs: "column",
                    sm: "row"
                }}
                alignItems={{
                    xs: "stretch",
                    sm: "flex-start"
                }}
                sx={{ mb: 2, maxWidth: "480px" }}
                {...props}
            >
                {children}
            </Stack>

            {
                referenceData && (
                    <ReferenceBox
                        data={referenceData}
                    />
                )
            }
        </Stack>
    )
}