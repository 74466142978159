import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import LoadingButton from '@material-ui/lab/LoadingButton';

import api from 'src/utilities/axios';

interface IAddFormDialogProps {
	updateRender: any,
	deleteDialog: any,
}

export default function EditFormDialog(props: IAddFormDialogProps): JSX.Element {
	const { updateRender, deleteDialog } = props

	const [loading, setLoading] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<JSX.Element>(null);

	const { t } = useTranslation();

	function handleEdit(event: React.SyntheticEvent): void {
		event.preventDefault();
		event.persist();

		setLoading(true);
		setMessage(null);

		api({
			pathname: '/staff/questions/' + deleteDialog.data.id,
			method: 'DELETE',
			handleResponse: (): void => {
				setLoading(false);
				updateRender();
				handleClose();
			},
			handleError: (): void => {
				setLoading(false);
			}
		})
	}

	function handleClose(): void {
		deleteDialog.setState(false);
	};

	return (
		<Dialog open={deleteDialog.state} onClose={handleClose} maxWidth='sm' fullWidth>
			<form onSubmit={handleEdit}>
				<DialogTitle>Delete Question?</DialogTitle>
				{message}
				<DialogActions>
					<Button
						onClick={handleClose}
						color='primary'
					>
						{t('form.cancel')}
					</Button>
					<LoadingButton
						component='button'
						type='submit'
						variant='contained'
						color='primary'
						loading={loading}
					>
						{t('form.delete')}
					</LoadingButton>
				</DialogActions>
			</form>
		</Dialog>
	)
}