import Box from "@material-ui/core/Box";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { formatTime } from "src/utilities/date";
import { StaffReportStateType } from "src/redux/staff-report/reducers";

export interface IPublicProps {
  values: any;
  interval: string;
  min: number;
  max: number;
  reportId: string;
}

export interface IReduxStateProps {
  reduxState: {
    staffReport: StaffReportStateType;
  };
}

export type Props = IPublicProps & IReduxStateProps;

export function StepGraphComponent(props: Props): JSX.Element {
  const {
    values = [],
    interval = "weekly",
    min = 0,
    max = 100,
    reportId = "",
  } = props;
  const { t } = useTranslation();

  let options: any = {
    maintainAspectRatio: false,
    scales: {
      y: {
        type: "linear",
        min: reportId === "00005093" ? 80 : min,
        max: max,
        ...(reportId === "00005093" && {
          ticks: {
            stepSize: 1,
            autoSkip: false,
          },
        }),
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            // var label = context.dataset.label || '';
            var label = "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("en-US").format(context.parsed.y);
              label += " steps";
            }
            return label;
          },
        },
      },

      legend: {
        display: false,
      },
    },
  };

  function data(): any {
    let labels: any[] = [];
    let min_max_datasets: any[] = [];

    values.map((item: any) => {
      labels.push(formatTime(interval, item[0]));
      min_max_datasets.push(parseFloat(item[1]));

      return item;
    });

    return {
      labels: labels.reverse(),
      datasets: [
        {
          label: t("report.min_max"),
          borderColor: "#000000",
          backgroundColor: "#c40d23",
          data: min_max_datasets.reverse(),
        },
      ],
    };
  }

  return (
    <Box sx={{ height: "100%" }}>
      <Bar data={data()} options={options} />
    </Box>
  );
}
