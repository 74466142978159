import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Typography } from '@material-ui/core';
import Slider from 'rc-slider';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const redColor = '#e57373';
const greenColor = '#81c784';
const yellowColor = '#ffb74d';
const trackStyle = [
	{ backgroundColor: yellowColor, height: 10 }, 
	{ backgroundColor: greenColor, height: 10 }, 
	{ backgroundColor: yellowColor, height: 10 }
];
const handleStyle = [
	{ backgroundColor: yellowColor, height: 24, width: 24, marginTop: -7, border: 'none', boxShadow: '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.40)' }, 
	{ backgroundColor: greenColor, height: 24, width: 24, marginTop: -7, border: 'none', boxShadow: '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.40)' },
	{ backgroundColor: greenColor, height: 24, width: 24, marginTop: -7, border: 'none', boxShadow: '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.40)' },
	{ backgroundColor: yellowColor, height: 24, width: 24, marginTop: -7, border: 'none', boxShadow: '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.40)' }
];
const railStyle = {
	backgroundColor: redColor, height: 10
}
export default function TuningDialog(props: any) {
    const { t } = useTranslation();
    const { handleClose, data, sliderMin, sliderMax, handleSubmit, generalSlider } = props

    console.log(generalSlider);
    const [maxValues, setMaxValues] = React.useState(data.customRanges.maxValue);
    const [minValues, setMinValues] = React.useState(data.customRanges.minValue);
    const [meanValues, setMeanValues] = React.useState(data.customRanges.meanValue);
    // const [generalMaxValues, setGeneralMaxValues] = React.useState(data.customRanges.maxValue);
    // const [generalMinValues, setGeneralMinValues] = React.useState(data.customRanges.minValue);
    // const [generalMeanValues, setGeneralMeanValues] = React.useState(data.customRanges.meanValue);

    function handleMaxSliderChange(value: any): void{
		  setMaxValues(value);
    };

    function handleMinSliderChange(value: any): void{
      setMinValues(value);
    };

    function handleMeanSliderChange(value: any): void{
      setMeanValues(value);
    };

    function handleClickReset(): void{
		  setMaxValues(generalSlider.maxValue);
      setMinValues(generalSlider.minValue);
      setMeanValues(generalSlider.meanValue);

	  };

    function handleClickSubmit(): void{
		  handleSubmit({
        maxValue: maxValues,
        minValue: minValues,
        meanValue: meanValues
        });
	  };


  return (
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('table.tuning') + " : " + data.name}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid item xs={12}>
                <Grid container spacing={3} sx={{ px: 2, py: 3 }}>
                    <Grid item xs={12}>
                        <Typography id='max_score_slider' variant='h6'>{t('report.slider_max')}</Typography>
                        <Range
                            count={3}
                            trackStyle={trackStyle}
                            handleStyle={handleStyle}
                            railStyle={railStyle}
                            tipProps={{ placement: 'top', visible: true }}
                            step={0.1}
                            value={maxValues}
                            max={sliderMax}
                            min={sliderMin}
                            onChange={handleMaxSliderChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography id='min_score_slider' variant='h6'>{t('report.slider_min')}</Typography>
                        <Range
                            count={3}
                            trackStyle={trackStyle}
                            handleStyle={handleStyle}
                            railStyle={railStyle}
                            tipProps={{ placement: 'top', visible: true }}
                            step={0.1}
                            value={minValues}
                            max={sliderMax}
                            min={sliderMin}
                            onChange={handleMinSliderChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography id='mean_score_slider' variant='h6'>{t('report.slider_mean')}</Typography>
                        <Range
                            count={3}
                            trackStyle={trackStyle}
                            handleStyle={handleStyle}
                            railStyle={railStyle}
                            tipProps={{ placement: 'top', visible: true }}
                            step={0.1}
                            value={meanValues}
                            max={sliderMax}
                            min={sliderMin}
                            onChange={handleMeanSliderChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickReset} color="info">
            {t('table.reset')}
          </Button>
          <Button onClick={handleClose} color="info">
            {t('table.cancel')}
          </Button>
          <Button onClick={handleClickSubmit} variant="contained" color="info" autoFocus>
            {t('table.save')}
          </Button>
        </DialogActions>
      </Dialog>
  );
}